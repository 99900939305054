import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { es } from "date-fns/locale/es";
import Loading from "./Loading";
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import reporteService from "../services/ReporteService"; // Importar servicios de reporte. (Solicitudes al backend)
import NavbarReporte from "./NavbarReporte"; // Importar NavbarReporte.
import NavbarEvidencia from "./NavbarEvidencia"; // Importar NavbarEvidencia.
import Tabla from "./Tabla";
import VerTabla from "./VerTabla";
import usuarioService from "../services/UsuarioService"; // Importar servicios de usuario. (Solicitudes al backend)
import * as reporteFunctions from "../utils/reporteFunctions"; // Importar funciones de reporte.
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EmpresaService from "../services/EmpresaService";
import Breadcrumbs from "./BreadcrumbsMUI";
import InfoIcon from "@mui/icons-material/Info"; // Ya está disponible en Material-UI
import Swal from "sweetalert2";
import CircleIcon from "@mui/icons-material/Circle"; // Agregamos esta importación
import HistoryIcon from "@mui/icons-material/History";
import CompararCampos from "./CompararCampos";

function Reporte() {
  // ------------------------ BLOQUE DE CARGA DE DATOS ------------------------

  // -------------------------------- ESTADOS USUARIO --------------------------------

  const [usuarioLogeado, setUsuarioLogeado] = useState(null);
  const rolesEditarReporte = [0, 1, 6];
  const rolesEditarCampos = [0, 1, 2, 3, 4, 6];
  const rolesAutorizarRegistros = [0, 3, 4, 6];
  const rolesVerEvidencias = [0, 1, 2, 3, 4, 5, 6];
  const rolesNavbarEvidencia = [0, 1, 2, 3, 4, 5, 6];
  const rolesGestionarEvidencias = [0, 1, 2, 3, 4, 6];
  const rolesDescargarReporte = [0, 1, 3, 4, 5, 6];
  const rolesGuardarPlantilla = [0, 4, 6];
  const rolesAutorizarReporte = [0, 4, 6];

  // -------------------------------- PARAMETROS POR URL --------------------------------

  const { idReporte, idEmpresa } = useParams();
  const [params] = useSearchParams();
  const irCategoria = params.get("idCategoria");
  const irSeccion = params.get("idSeccion");

  // -------------------------------- ESTADOS REPORTE --------------------------------

  const [reporte, setReporte] = useState(null);
  const [tituloReporte, setTituloReporte] = useState("");
  const [anioReporte, setAnioReporte] = useState("");
  const [categoriaActualIndex, setCategoriaActualIndex] = useState(
    parseInt(irCategoria) || 0,
  );
  const [seccionActualIndex, setSeccionActualIndex] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [evidencias, setEvidencias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reporteAutorizado, setReporteAutorizado] = useState(false);
  const [alerta, setAlerta] = useState(false); // Estado para abrir la alerta.
  const [alertaTexto, setAlertaTexto] = useState(""); // Estado para el texto de la alerta.
  const [highlightedId, setHighlightedId] = useState(null);
  const [rolUsuario, setRolUsuario] = useState(null);
  const [nombreEmpresa, setNombreEmpresa] = useState("Empresa");
  const [errorCampo, setErrorCampo] = useState(false);
  const [errorSubCampo, setErrorSubCampo] = useState([]);
  const regexNumeroValido = /^[+\-]?\d+([.,]\d+)?$/; // Expresión regular para validar números.
  const [errorTituloCampo, setErrorTituloCampo] = useState(false);
  const [errorTituloSubcampo, setErrorTituloSubcampo] = useState([]);
  const [reportesComparacion, setReportesComparacion] = useState([]);

  const [mostrarSemaforos, setMostrarSemaforos] = React.useState(true);

  // Agregar estos estados al inicio del componente
  const [semaforosVisible, setSemaforosVisible] = React.useState(true);
  const [isAnimating, setIsAnimating] = React.useState(false);

  // Agregar este efecto para manejar la animación
  React.useEffect(() => {
    if (mostrarSemaforos) {
      setSemaforosVisible(true);
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setSemaforosVisible(false);
        setIsAnimating(false);
      }, 500); // Este tiempo debe coincidir con la duración de la animación
      return () => clearTimeout(timer);
    }
  }, [mostrarSemaforos]);

  // ------------------------ FUNCIONES DE REFRESH ------------------------

  // Función para obtener los datos del usuario logeado.
  const fetchUsuarioLogeado = useCallback(async () => {
    try {
      const usuario = await usuarioService.getUsuarioLogueado();
      setUsuarioLogeado(usuario.data);
      localStorage.setItem("userName", usuario.data.nombre);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  // Función para obtener los datos del reporte.
  const refreshReporte = useCallback(async () => {
    try {
      const response = await reporteService.obtenerReporte(idReporte);
      const data = response.data;

      // Actualizar el estado del reporte y sus propiedades
      setReporte(data);
      setTituloReporte(data.titulo);
      setAnioReporte(data.anio);
      setReporteAutorizado(data.estado !== "Pendiente");
      setReportesComparacion(data.reportesComparacion);

      const categoriasObtenidas = data.categorias || [];
      setCategorias(categoriasObtenidas.map((categoria) => categoria.titulo));

      // Actualizar las secciones según la categoría actual
      if (
        categoriasObtenidas.length > 0 &&
        categoriasObtenidas[categoriaActualIndex]
      ) {
        setSecciones(categoriasObtenidas[categoriaActualIndex].secciones || []);
      }

      // Obtener el usuario logueado
      const usuarioLogeadoTemp = await usuarioService
        .getUsuarioLogueado()
        .then((response) => response.data);
      // Buscar el rol del usuario logueado
      if (data.roles && usuarioLogeadoTemp) {
        const rolUsuario = data.roles.find((rol) => {
          // Asegúrate de que idUsuarios sea un array y verifica coincidencias
          return (
            Array.isArray(rol.idUsuarios) &&
            rol.idUsuarios.some((userId) => {
              return userId.trim() === usuarioLogeadoTemp.id.trim(); // Asegúrate de eliminar espacios
            })
          );
        });

        if (rolUsuario) {
          setRolUsuario(rolUsuario);
        } else {
          console.log("El usuario no tiene un rol asignado en este reporte.");
        }
      }
    } catch (error) {
      console.error("Error al obtener el reporte:", error);
    }
  }, [idReporte, categoriaActualIndex]);

  // Función para obtener las evidencias del reporte.
  const refreshEvidencia = useCallback(async () => {
    try {
      const response = await reporteService.obtenerEvidencias(idReporte);
      setEvidencias(response.data);
    } catch (error) {
      console.error("Error al obtener las evidencias:", error);
    }
  }, [idReporte]);

  // Función para obtener el nombre de la empresa.
  const obtenerNombreEmpresa = async () => {
    const empresa = await EmpresaService.getEmpresa(idEmpresa).then(
      (response) =>
        response.data.nombre ? response.data.nombre : response.data.razonSocial,
    );
    setNombreEmpresa(empresa);
    return empresa;
  };

  // Función para obtener los datos del reporte y las evidencias de la base de datos
  const fetchData = useCallback(async () => {
    setLoading(true);
    await fetchUsuarioLogeado()
      .then(() => {
        return refreshReporte();
      })
      .then(() => {
        return refreshEvidencia();
      })
      .then(() => {
        return obtenerNombreEmpresa();
      })
      .catch((error) => {
        console.error("Error al cargar los datos:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchUsuarioLogeado, refreshReporte, refreshEvidencia]);

  // ------------------------ EFECTOS DE CARGA DE DATOS ------------------------

  // Efecto que se ejecuta al cargar la página
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Efecto que se ejecuta cuando cambia la categoría inicial desde la URL (irCategoria)
  useEffect(() => {
    if (irCategoria) {
      setCategoriaActualIndex(parseInt(irCategoria));
    }
  }, [irCategoria]);

  // Efecto para manejar el scroll a secciones o campos según los parámetros en la URL
  useEffect(() => {
    const highlightElement = (id) => {
      setHighlightedId(id); // Activar el highlight
      setTimeout(() => {
        setHighlightedId(null); // Remover el highlight después de 2 segundos
      }, 2000); // 2 segundos para la transición
    };

    const scrollToElement = () => {
      let elementId = null;

      if (irSeccion) {
        elementId = `seccion-${irSeccion}`;
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          highlightElement(elementId);
        } else {
          console.warn(`No se encontró el elemento: seccion-${irSeccion}`);
        }
      }
    };

    if (secciones.length > 0) {
      const timeoutId = setTimeout(scrollToElement, 500); // Esperar 500ms antes de hacer scroll
      return () => clearTimeout(timeoutId);
    }
  }, [irSeccion, secciones]);

  // Función para manejar el cambio de categoría.
  const handleCategoriaChange = (index) => {
    setSecciones(reporte.categorias[index].secciones || []);
    setCategoriaActualIndex(index);
  };

  // ------------------------ BLOQUE DE VISUALIZACIÓN ------------------------

  // ------------------------ EDICIÓN DE CAMPO ------------------------

  const [openDialog, setOpenDialog] = useState(false); // Estado para abrir el pop up de editar campo.
  const [editedField, setEditedField] = useState({
    titulo: "",
    contenido: "",
    tipo: "Texto",
    subCampos: [],
    porcentaje: false,
  }); // Estado para el campo editado.
  const [currentField, setCurrentField] = useState({
    titulo: "",
    contenido: "",
    tipo: "Texto",
    subCampos: [],
  }); // Estado para el campo actual.
  const [campoActualIndex, setCampoActualIndex] = useState(0); // Estado para el índice del campo actual.
  const [subCampoActualIndex, setSubCampoActualIndex] = useState(0); // Estado para el índice del subcampo actual.
  const [isAdding, setIsAdding] = useState(true); // Estado para agregar un campo nuevo.
  const [openEliminarCampoDialog, setOpenEliminarCampoDialog] = useState(false); // Estado para abrir el pop up de eliminar campo.

  // Función para abrir el pop up de editar campo.
  const handleOpenEditDialog = (campo, indexSeccion, indexCampo) => {
    setCurrentField(campo); // Guardar el campo actual.
    setEditedField({
      ...campo,
      titulo: campo ? campo.titulo : "", // Mantener el título original del campo
      tipo: campo ? campo.tipo : "texto", // Si el campo es nulo, el tipo es "Texto".
      contenido: campo ? campo.contenido : "", // Si el campo es nulo, se asigna un string vacio para el contenido.
      subCampos: campo ? JSON.parse(JSON.stringify(campo.subCampos || [])) : [], // Clonar correctamente los subcampos.
    });
    setIsAdding(!campo); // Si el campo es nulo, se está agregando un campo nuevo.
    refreshEvidenciaCampo(campo); // Refrescar las evidencias del campo.
    setSeccionActualIndex(indexSeccion); // Guardar el índice de la sección actual.
    setCampoActualIndex(indexCampo); // Guardar el índice del campo actual.
    if (campo && campo.subCampos && campo.subCampos.length > 0) {
      setErrorTituloSubcampo(new Array(campo.subCampos.length).fill(false));
    } else {
      // Si no hay subcampos, lo dejas como un arreglo vacío
      setErrorTituloSubcampo([]);
    }
    setOpenDialog(true); // Abrir el pop up de editar campo.
  };

  // Cerrar el pop up de editar campo.
  const handleCloseEditDialog = () => {
    setOpenDialog(false); // Cerrar el pop up de editar campo.
    setEditedField(null); // Limpiar el campo editado.
    setIsAdding(false); // No se está agregando un campo nuevo.
    refreshReporte(); // Refrescar el reporte.
    refreshEvidencia(); // Refrescar las evidencias.
    refreshFilteredEvidencia(); // Refrescar las evidencias filtradas.
  };

  // Abrir popup de eliminar campo.
  const handleEliminarCampoDialog = (campoIndex, indexSeccion) => {
    setCampoActualIndex(campoIndex); // Guardar el índice del campo actual.
    setSeccionActualIndex(indexSeccion); // Guardar el índice de la sección actual.
    setOpenEliminarCampoDialog(true); // Abrir el pop up de eliminar campo.
  };

  // Cerrar popup de eliminar campo.
  const handleCerrarEliminarCampoDialog = () => {
    setOpenEliminarCampoDialog(false); // Cerrar el pop up de eliminar campo.
    handleCloseEditDialog(); // Cerrar el pop up de editar campo.
  };

  // Modificar un subcampo o campo.
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    if (name.startsWith("subCampos")) {
      const subcampoIndex = parseInt(name.split("-")[2]);
      // Asegúrate de que subCampos esté definido y sea un array
      if (
        Array.isArray(editedField.subCampos) &&
        subcampoIndex >= 0 &&
        subcampoIndex < editedField.subCampos.length
      ) {
        if (name.split("-")[1] === "titulo" && value.length > 0) {
          let erroresTituloSubcampos = errorSubCampo;
          erroresTituloSubcampos[subcampoIndex] = false;
          setErrorTituloSubcampo(erroresTituloSubcampos);
        }

        // En caso de tener tipo de campo numerico, se valida que el contenido sea un número.
        if (
          name.split("-")[1] === "contenido" &&
          editedField.subCampos[subcampoIndex].tipo.toLowerCase() ===
            "numerico" &&
          (regexNumeroValido.test(value) ||
            value === "" ||
            value === 0 ||
            value === undefined ||
            value === null)
        ) {
          let erroresSubcampos = errorSubCampo;
          erroresSubcampos[subcampoIndex] = false;
          setErrorSubCampo(erroresSubcampos);
        }
        const newSubCampos = [...editedField.subCampos];
        newSubCampos[subcampoIndex] = {
          ...newSubCampos[subcampoIndex],
          [name.split("-")[1]]: value,
        };
        setEditedField({ ...editedField, subCampos: newSubCampos });
      } else {
        console.error("Índice de subcampo inválido o subCampos no es un array");
      }
    } else {
      if (name === "titulo" && value.length > 0) {
        setErrorTituloCampo(false);
      }
      // En caso de tener tipo de campo numerico, se valida que el contenido sea un número.
      if (
        name === "contenido" &&
        editedField.tipo.toLowerCase() === "numerico"
      ) {
        if (regexNumeroValido.test(value)) {
          // Coincide con el patrón numérico
          setErrorCampo(false);
        }
        if (value === "") {
          setErrorCampo(false);
        }
      }

      setEditedField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Agregar un subcampo.
  const handleAddSubcampo = () => {
    const newSubcampo = { titulo: "", contenido: "", tipo: "texto" }; // Se crea un nuevo subcampo.
    setEditedField({
      ...editedField,
      subCampos: [...(editedField.subCampos || []), newSubcampo], // Se agrega el subcampo a la lista de subcampos.
    });
  };

  // Eliminar un subcampo.
  const handleDeleteSubcampo = (index) => {
    const newSubcampos = [...editedField.subCampos];
    newSubcampos.splice(index, 1);
    setEditedField({ ...editedField, subCampos: newSubcampos });
  };

  // Función para eliminar un campo.
  const handleEliminarCampo = async () => {
    if (campoActualIndex !== -1) {
      let coordenadas = {
        // Se crea un objeto con las coordenadas del campo a eliminar.
        indexCategoria: categoriaActualIndex,
        indexSeccion: seccionActualIndex,
        indexCampo: campoActualIndex,
      };
      await reporteService.eliminarContenido(idReporte, coordenadas);
      setSecciones(
        secciones.map((seccion, index) => {
          if (index === seccionActualIndex) {
            return {
              ...seccion,
              campos: seccion.campos.filter((c, i) => i !== campoActualIndex),
            };
          }
          return seccion;
        }),
      );
    } else {
      console.log("error al eliminar campo");
    }
    await fetchData();
    setOpenEliminarCampoDialog(false);
    handleCerrarEliminarCampoDialog();
    handleCloseEditDialog();
  };

  // Función para guardar el campo editado o agregado.
  const handleSaveField = async () => {
    // Validar que el título no esté vacío
    if (!editedField.titulo || editedField.titulo.trim() === "") {
      setErrorTituloCampo(true);
      return; // No continuar si no hay título
    }

    // Validar subcampos si existen
    if (editedField.subCampos && editedField.subCampos.length > 0) {
      const subcamposInvalidos = editedField.subCampos.some(
        (subcampo) => !subcampo.titulo || subcampo.titulo.trim() === "",
      );

      if (subcamposInvalidos) {
        const newErrorTituloSubcampo = editedField.subCampos.map(
          (subcampo) => !subcampo.titulo || subcampo.titulo.trim() === "",
        );
        setErrorTituloSubcampo(newErrorTituloSubcampo);
        return;
      }
    }

    setErrorTituloCampo(false);
    setErrorTituloSubcampo(
      Array(editedField.subCampos?.length || 0).fill(false),
    );

    try {
      // Validación Campo
      const campo = editedField;
      const subCampos = campo.subCampos || [];

      if (editedField.contenido !== "" && errorCampo) {
        return;
      }

      if (
        campo.tipo === "Booleano" &&
        campo.contenido !== true &&
        campo.contenido !== false
      ) {
        campo.contenido = null;
      }

      if (
        campo.tipo.toLowerCase() === "numerico" &&
        !regexNumeroValido.test(campo.contenido) &&
        campo.contenido !== ""
      ) {
        setErrorCampo(true);
        return;
      }
      // Crear el objeto del campo actualizado
      const campoActualizado = {
        ...campo,
        titulo: campo.titulo,
        tipo: campo.tipo,
        descripcion: campo.descripcion || "", // Asegurarnos de incluir la descripción
        contenido: campo.contenido,
        porcentaje: campo.porcentaje || false,
        autorizado: campo.autorizado || false,
        evidencias: campo.evidencias || [],
        subCampos: subCampos.map((subCampo) => ({
          ...subCampo,
          titulo: subCampo.titulo,
          tipo: subCampo.tipo,
          descripcion: subCampo.descripcion || "", // También para subcampos
          contenido: subCampo.contenido,
          porcentaje: subCampo.porcentaje || false,
          autorizado: subCampo.autorizado || false,
          evidencias: subCampo.evidencias || [],
        })),
      };

      const newReporte = {
        coordenadas: {
          indexCategoria: categoriaActualIndex,
          indexSeccion: seccionActualIndex,
          indexCampo: campoActualIndex,
        },
        nuevoTituloCategoria: reporte.categorias[categoriaActualIndex].titulo,
        nuevoTituloSeccion:
          reporte.categorias[categoriaActualIndex].secciones[seccionActualIndex]
            .titulo,
        nuevoCampo: campoActualizado,
      };

      let campoIndex = 0; // Se define el índice del campo.
      let seccionIndex = seccionActualIndex; // Se define el índice de la sección.
      if (isAdding) {
        // Si se está agregando un campo nuevo.
        campoIndex = secciones[seccionActualIndex].campos.length; // Se obtiene el índice del nuevo campo. (Ocupa la última posición porque es un campo nuevo)
        let newReporte = {
          // Se crea un objeto con los datos del nuevo campo.
          coordenadas: {
            indexCategoria: categoriaActualIndex,
            indexSeccion: seccionIndex,
            indexCampo: campoIndex,
          },
          nuevoTituloCategoria: categorias[categoriaActualIndex],
          nuevoTituloSeccion: secciones[seccionIndex].titulo,
          nuevoCampo: campoActualizado,
        };
        setSecciones(
          secciones.map((seccion, index) => {
            // Se actualiza la lista de secciones.
            if (index === seccionIndex) {
              return {
                ...seccion,
                campos: [...seccion.campos, campoActualizado],
              };
            }
            return seccion;
          }),
        );
        let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
          (response) => response.data.nombre,
        );
        let mensaje = `*${usuarioLogeado.nombre}* ha agregado el campo *"${campoActualizado.titulo}"* en la categoría *"${categorias[categoriaActualIndex]}"* del reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
        setOpenDialog(false);
        Swal.fire({
          title: "Guardando campo",
          text: "Por favor espere...",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        await reporteService.actualizarReporte(
          newReporte,
          idReporte,
          usuarioLogeado.id,
          usuarioLogeado.nombre,
          mensaje,
        ); // Se actualiza el reporte en la base de datos.
        Swal.close();
        // fetchData();                                                    // Se actualizan los datos del reporte.
        // refreshFilteredEvidencia();                                     // Se actualizan las evidencias filtradas.
      } else {
        // Si se está editando un campo existente.
        campoIndex = secciones[seccionIndex].campos.findIndex(
          (c) => c === currentField,
        ); // Se obtiene el índice del campo a editar.
        if (campoIndex !== -1) {
          // Si se encontró el campo.
          let newReporte = {
            // Se crea un objeto con los datos del campo editado.
            coordenadas: {
              // Se envían las coordenadas del campo a editar.
              indexCategoria: categoriaActualIndex,
              indexSeccion: seccionIndex,
              indexCampo: campoIndex,
            },
            nuevoTituloCategoria: categorias[categoriaActualIndex],
            nuevoTituloSeccion: secciones[seccionIndex].titulo,
            nuevoCampo: campoActualizado,
          };
          setSecciones(
            secciones.map((seccion, index) => {
              // Se actualiza la lista de secciones.
              if (index === seccionIndex) {
                return {
                  ...seccion,
                  campos: seccion.campos.map((campo, index) => {
                    if (index === campoIndex) {
                      return campoActualizado;
                    }
                    return campo;
                  }),
                };
              }
              return seccion;
            }),
          );
          let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
            (response) => response.data.nombre,
          );
          let mensaje = `*${usuarioLogeado.nombre}* ha editado el campo *"${campoActualizado.titulo}"* en la categoría *"${categorias[categoriaActualIndex]}"* del reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
          setOpenDialog(false);
          Swal.fire({
            title: "Guardando campo",
            text: "Por favor espere...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            },
          });
          await reporteService.actualizarReporte(
            newReporte,
            idReporte,
            usuarioLogeado.id,
            usuarioLogeado.nombre,
            mensaje,
          ); // Se actualiza el reporte en la base de datos.
          Swal.close();
          // fetchData();                                                    // Se actualizan los datos del reporte.
          // refreshFilteredEvidencia();                                     // Se actualizan las evidencias filtradas.
        } else {
          console.log("error al guardar campo");
        }
      }
      setOpenDialog(false);
    } catch (error) {
      console.error("Error al guardar el campo:", error);
    }
  };

  // ---------------- MOVER CAMPOS ----------------

  const [editMode, setEditMode] = useState(false); // Modo de edición de estructura.

  // Función para activar modo de edición de estructura.
  const handleEditMode = (guardado) => {
    setEditMode((prevEditMode) => {
      const newEditMode = !prevEditMode;
      const categoriaIndex = categoriaActualIndex;

      if (!newEditMode) {
        fetchData(); // Ejecuta fetchData si se desactiva el modo de edición.
      }

      if (guardado) {
        // Obtiene el reporte y lo actualiza.
        reporteService
          .obtenerReporte(idReporte)
          .then((nuevoReporte) => {
            nuevoReporte = nuevoReporte.data;
            nuevoReporte.categorias = nuevoReporte.categorias.map(
              (categoria, index) => {
                if (index === categoriaIndex) {
                  // Solo actualiza la categoría especificada
                  return {
                    ...categoria, // Se mantienen las demás propiedades de la categoría
                    titulo: categorias[categoriaIndex],
                    secciones: secciones.map((seccion) => ({
                      titulo: seccion.titulo,
                      campos: seccion.campos.map((campo) => ({
                        titulo: campo.titulo,
                        contenido: campo.contenido,
                        tipo: campo.tipo,
                        subCampos: campo.subCampos,
                        evidencias: campo.evidencias,
                        autorizado: campo.autorizado,
                      })),
                    })),
                  };
                } else {
                  return categoria; // Mantén el contenido original para otras categorías
                }
              },
            );

            return reporteService.reescribirReporte(idReporte, nuevoReporte);
          })
          .then(() => {
            // Una vez reescrito el reporte, se refresca el reporte con los datos subidos.
            fetchData();
          })
          .catch((error) => {
            console.error("Error al actualizar el reporte:", error);
          });
      }

      return newEditMode;
    });
  };

  const handleDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return; // Si no hay destino, no hacer nada

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return; // Si el destino es igual al origen, no hacer nada
    }

    if (type === "section") {
      // Mover una sección
      const reorderedSections = Array.from(secciones);
      const [removed] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, removed);

      setSecciones(reorderedSections); // Actualiza el estado de secciones
    } else if (type === "field") {
      // Mover un campo dentro de una sección
      const sourceSectionIndex = parseInt(source.droppableId);
      const destinationSectionIndex = parseInt(destination.droppableId);

      const sourceSection = secciones[sourceSectionIndex];
      const destinationSection = secciones[destinationSectionIndex];

      const sourceFields = Array.from(sourceSection.campos);
      const destinationFields =
        sourceSectionIndex === destinationSectionIndex
          ? sourceFields
          : Array.from(destinationSection.campos);

      const [removedField] = sourceFields.splice(source.index, 1);
      destinationFields.splice(destination.index, 0, removedField);

      const updatedSections = Array.from(secciones);
      updatedSections[sourceSectionIndex] = {
        ...sourceSection,
        campos: sourceFields,
      };

      if (sourceSectionIndex !== destinationSectionIndex) {
        updatedSections[destinationSectionIndex] = {
          ...destinationSection,
          campos: destinationFields,
        };
      }

      setSecciones(updatedSections); // Actualiza el estado de secciones con los campos movidos
    }
  };

  // ---------------- EVIDENCIAS DEL CAMPO ----------------

  const [filteredEvidencia, setFilteredEvidencia] = useState([]); // Estado para las evidencias filtradas.
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda.
  const [selectedEvidencias, setSelectedEvidencias] = useState([]); // Estado para las evidencias seleccionadas.
  const [openEvidenciaDialog, setOpenEvidenciaDialog] = useState(false); // Estado para abrir el pop up de visualización de evidencia.

  // Función para manejar la búsqueda de evidencias.
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    refreshFilteredEvidencia(event.target.value, selectedEvidencias);
  };

  // Función para agregar una evidencia al campo.
  const handleAgregarEvidenciaAlCampo = (evidencia) => {
    // Se evita agregar evidencias duplicadas.
    if (
      !selectedEvidencias.some(
        (selectedEvidencia) => selectedEvidencia.id === evidencia.id,
      )
    ) {
      selectedEvidencias.push(evidencia);
    }
    refreshFilteredEvidencia(searchTerm, selectedEvidencias);
    // Ordenar por orden alfabético y numérico.
    selectedEvidencias.sort((a, b) => reporteFunctions.compareEvidencias(a, b));
  };

  // Función para remover una evidencia del campo.
  const handleRemoverEvidenciaDelCampo = (evidencia) => {
    // Se remueve la evidencia de las evidencias seleccionadas.
    if (
      selectedEvidencias.some(
        (selectedEvidencia) => selectedEvidencia.id === evidencia.id,
      )
    ) {
      selectedEvidencias.splice(selectedEvidencias.indexOf(evidencia), 1);
    }
    refreshFilteredEvidencia(searchTerm, selectedEvidencias);
    // Ordenar por orden alfabético y numérico.
    selectedEvidencias.sort((a, b) => reporteFunctions.compareEvidencias(a, b));
  };

  // Función para refrescar las evidencias filtradas.
  const refreshFilteredEvidencia = (
    searchTermParam = searchTerm,
    selectedEvidenciasParam = selectedEvidencias,
  ) => {
    // Se filtran las evidencias que no están seleccionadas.
    const filtered = evidencias.filter((evidencia) => {
      const searchTermMatch =
        evidencia.nombre
          .toLowerCase()
          .includes(searchTermParam.toLowerCase()) ||
        evidencia.tipo.toLowerCase().includes(searchTermParam.toLowerCase()) ||
        searchTermParam === "";

      const notSelected = !selectedEvidenciasParam.some(
        (selectedEvidencia) => selectedEvidencia.id === evidencia.id,
      );

      return searchTermMatch && notSelected;
    });

    // Ordenar por orden alfabético y numérico.
    filtered.sort((a, b) => reporteFunctions.compareEvidencias(a, b));

    setFilteredEvidencia(filtered);
  };

  // Función para refrescar las evidencias que contiene el campo.
  const refreshEvidenciaCampo = (campo) => {
    if (campo) {
      setSelectedEvidencias(campo.evidencias || []);
      refreshFilteredEvidencia(searchTerm, campo.evidencias || []);
    } else {
      setSelectedEvidencias([]);
      refreshFilteredEvidencia(searchTerm, []);
    }
  };

  // Función para acceder a una evidencia.
  const accederEvidencia = async (evidencia) => {
    try {
      if (evidencia.tipo.toLowerCase() === "archivo") {
        let url = await reporteService
          .obtenerUrlS3(evidencia.id)
          .then((response) => response.data); // Obtener la URL del servicio S3.
        if (url) {
          window.open(url, "_blank"); // Abrir la URL en una nueva pestaña.
        } else {
          console.error("La URL obtenida del servicio S3 es nula o no válida."); // Mostrar un mensaje de error.
        }
      } else {
        window.open(reporteFunctions.formatUrl(evidencia.url), "_blank"); // Abrir la URL en una nueva pestaña.
      }
    } catch (error) {
      console.error("Error al acceder a la evidencia:", error); // Mostrar un mensaje de error.
    }
  };

  // Función para abrir el pop up de visualización de la evidencia.
  const handleVisualizarEvidenciaCampo = async (indexSeccion, indexCampo) => {
    await refreshEvidencia();
    if (
      secciones[seccionActualIndex].campos[indexCampo].evidencias.length > 0
    ) {
      setOpenEvidenciaDialog(true);
      refreshEvidenciaCampo(secciones[seccionActualIndex].campos[indexCampo]);
      setSeccionActualIndex(indexSeccion);
      setCampoActualIndex(indexCampo);
    }
  };

  // ---------------- AUTORIZACIÓN DE CAMPO ----------------

  const [openAutorizarCampoDialog, setOpenAutorizarCampoDialog] =
    useState(false); // Estado para abrir el pop up de autorización de campo.

  // Función abrir el pop up de autorización de campo.
  const handleOpenAutorizarCampo = (indexCampo, indexSeccion) => {
    setCampoActualIndex(indexCampo);
    setSeccionActualIndex(indexSeccion);
    setOpenAutorizarCampoDialog(true);
  };

  // Función para autorizar el campo y cerrar el pop up.
  const handleAutorizarCampo = async () => {
    let coordenadas = {
      idUsuario: usuarioLogeado.id,
      coordenadas: {
        indexCategoria: categoriaActualIndex,
        indexSeccion: seccionActualIndex,
        indexCampo: campoActualIndex,
      },
    };
    let autorizacion =
      secciones[seccionActualIndex].campos[campoActualIndex].autorizado;

    setOpenAutorizarCampoDialog(false);
    Swal.fire({
      title: autorizacion ? "Revocando autorización" : "Autorizando el campo",
      text: "Por favor espere...",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await reporteService.autorizarCampo(idReporte, coordenadas);

      setSecciones((prevSecciones) => {
        return prevSecciones.map((seccion, indexSeccion) => {
          if (indexSeccion === seccionActualIndex) {
            return {
              ...seccion,
              campos: seccion.campos.map((campo, indexCampo) => {
                if (indexCampo === campoActualIndex) {
                  return {
                    ...campo,
                    autorizado: !autorizacion, // Alterna entre true y false
                  };
                }
                return campo;
              }),
            };
          }
          return seccion;
        });
      });
    } catch {
      await fetchData();
    }
    Swal.close();
  };

  // ---------------- TABLAS ----------------

  const [openVerTablaDialog, setOpenVerTablaDialog] = useState(false); // Estado para abrir el pop up de visualización de la tabla.
  const [csvData, setCsvData] = useState(""); // Estado para los datos de la tabla.
  const [openTableDialog, setOpenTableDialog] = useState(false); // Estado para abrir el pop up de edición de la tabla.
  const [currentTableTitle, setCurrentTableTitle] = useState("");
  const [editingSubcampoIndex, setEditingSubcampoIndex] = useState(null);

  // Función para abrir el diálogo de tabla
  const handleOpenTableDialog = (contenido, subIndex) => {
    setCsvData(contenido || "");
    setEditingSubcampoIndex(subIndex >= 0 ? subIndex : null);
    setOpenTableDialog(true);
  };

  // Función para cerrar el diálogo de tabla
  const handleCloseTableDialog = () => {
    setOpenTableDialog(false);
    setCsvData("");
    setEditingSubcampoIndex(null);
  };

  // Función para abrir el diálogo de visualización de la tabla
  const handleOpenVerTabla = (data, campo) => {
    // Asegurarse de que los datos estén en formato CSV
    let csvData;
    if (typeof data === "string") {
      csvData = data;
    } else if (data instanceof File) {
      // Si es un archivo, necesitamos leerlo primero
      const reader = new FileReader();
      reader.onload = (e) => {
        csvData = e.target.result;
        setCsvData(csvData);
        setCurrentTableTitle(campo?.titulo || "");
        setOpenVerTablaDialog(true);
      };
      reader.readAsText(data);
      return;
    } else {
      console.error("Formato de datos no soportado:", data);
      return;
    }

    setCsvData(csvData);
    setCurrentTableTitle(campo?.titulo || "");
    setOpenVerTablaDialog(true);
  };

  // Función para guardar la tabla
  const handleSaveTable = (tableData) => {
    setEditedField((prevField) => {
      // Si estamos editando un subcampo
      if (editingSubcampoIndex !== null && editingSubcampoIndex >= 0) {
        const newSubCampos = [...(prevField.subCampos || [])];
        newSubCampos[editingSubcampoIndex] = {
          ...newSubCampos[editingSubcampoIndex],
          tipo: "Tabla",
          contenido: tableData,
        };

        return {
          ...prevField,
          subCampos: newSubCampos,
        };
      }

      // Si estamos editando el campo principal
      return {
        ...prevField,
        tipo: "Tabla",
        titulo: prevField.titulo || currentField?.titulo,
        contenido: tableData,
        porcentaje: prevField.porcentaje || false,
        autorizado: prevField.autorizado || false,
        descripcion: prevField.descripcion || "",
        evidencias: prevField.evidencias || [],
        subCampos: prevField.subCampos || [],
      };
    });

    // Cerrar el diálogo de edición de tabla
    setOpenTableDialog(false);
  };

  const handleDescargarTablaCSV = () => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    // Usar el título actual o un nombre por defecto
    const fileName = (currentTableTitle || "tabla") + ".csv";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para cerrar el pop up de visualización de la tabla.
  const handleCloseVerTabla = () => {
    setOpenVerTablaDialog(false);
  };

  // ------------------------ CATEGORÍAS Y SECCIONES ------------------------

  const [openSectionDialog, setOpenSectionDialog] = useState(false); // Estado para abrir el pop up de agregar sección.
  const [openSectionEditDialog, setOpenSectionEditDialog] = useState(false); // Estado para abrir el pop up de editar sección.
  const [openCategoryEditDialog, setOpenCategoryEditDialog] = useState(false); // Estado para abrir el pop up de editar categoría.
  const [editedSection, setEditedSection] = useState({ titulo: "" }); // Estado para la sección editada.
  const [tituloIngresado, setTituloIngresado] = useState(""); // Estado para el título ingresado.
  const [openEliminarCategoriaDialog, setOpenEliminarCategoriaDialog] =
    useState(false); // Estado para abrir el pop up de eliminar categoría.
  const [openEliminarSeccionDialog, setOpenEliminarSeccionDialog] =
    useState(false); // Estado para abrir el pop up de eliminar sección.

  // Abrir popup de editar categoría.
  const handleOpenCategoryEditDialog = () => {
    setOpenCategoryEditDialog(true); // Abrir el pop up de editar categoría.
    setTituloIngresado(categorias[categoriaActualIndex]); // Guardar el título de la categoría actual.
  };

  // Abrir popup de editar sección.
  const handleOpenSectionEditDialog = (seccionIndex) => {
    setOpenSectionEditDialog(true); // Abrir el pop up de editar sección.
    setSeccionActualIndex(seccionIndex); // Guardar el índice de la sección actual.
    setEditedSection({ titulo: secciones[seccionIndex].titulo }); // Guardar el título de la sección actual.
  };

  // Cerrar popup de editar sección.
  const handleCloseSectionEditDialog = () => {
    setOpenSectionEditDialog(false); // Cerrar el pop up de editar sección.
    setEditedSection({ titulo: "" }); // Limpiar el título de la sección editada.
  };

  // Abrir alerta.
  const handleOpenAlert = (texto) => {
    setAlerta(true); // Abrir la alerta.
    setAlertaTexto(texto); // Asignar el texto de la alerta.
  };

  // Cerrar alerta.
  const handleCloseAlert = () => {
    setAlerta(false);
  };

  // Ejecutar eliminación de categoría.
  const handleEliminarCategoria = async () => {
    // Se crea un objeto con las coordenadas de la categoría a eliminar.
    let coordenadas = {
      indexCategoria: categoriaActualIndex, // Coordenada de la categoría a eliminar.
    };
    await reporteService.eliminarContenido(idReporte, coordenadas); // Se elimina la categoría del reporte.
    setCategorias(
      categorias.filter((categoria, index) => index !== categoriaActualIndex),
    ); // Se elimina la categoría de la lista de categorías.
    setCategoriaActualIndex(0); // Se reinicia el índice de la categoría actual.
    await fetchData(); // Se actualizan los datos del reporte.
    setOpenEliminarCategoriaDialog(false); // Se cierra el pop up de eliminar categoría.
    setOpenCategoryEditDialog(false); // Se cierra el pop up de editar categoría.
  };

  // Abrir popup de eliminar sección.
  const handleOpenEliminarSeccion = () => {
    setOpenEliminarSeccionDialog(true);
  };

  // Cerrar popup de eliminar sección.
  const handleCloseEliminarSeccion = () => {
    setOpenEliminarSeccionDialog(false);
    handleCloseSectionEditDialog();
  };

  // Función para eliminar una sección.
  const handleEliminarSeccion = async () => {
    let coordenadas = {
      indexCategoria: categoriaActualIndex,
      indexSeccion: seccionActualIndex,
    };
    await reporteService.eliminarContenido(idReporte, coordenadas);
    refreshReporte();
    setOpenEliminarSeccionDialog(false);
    handleCloseEliminarSeccion();
  };

  // Función para guardar la sección editada o agregada.
  const handleSaveSection = async (agregar) => {
    // Validación de que los campos estén completos.
    const seccion = editedSection;
    if (
      seccion.titulo === "" ||
      seccion.titulo === null ||
      seccion.titulo === undefined
    ) {
      handleOpenAlert("Por favor, complete el titulo de la sección.");
      return;
    }

    let indexSeccion = seccionActualIndex; // Se define el índice de la sección.
    if (agregar) {
      // Si se está agregando una sección nueva.
      indexSeccion = secciones.length; // Se obtiene el índice de la nueva sección. (Ocupa la última posición porque es una sección nueva)
    }
    const newReporte = {
      // Se crea un objeto con los datos de la sección editada o agregada.
      coordenadas: {
        indexCategoria: categoriaActualIndex,
        indexSeccion: indexSeccion,
        indexCampo: null,
      },
      nuevoTituloCategoria: categorias[categoriaActualIndex],
      nuevoTituloSeccion: seccion.titulo,
      nuevoCampo: {},
    };
    setSecciones(
      secciones.map((seccion, index) => {
        // Se actualiza la lista de secciones.
        if (index === seccionActualIndex) {
          return {
            ...seccion,
            titulo: seccion.titulo,
          };
        }
        return seccion;
      }),
    );
    try {
      let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
        (response) => response.data.nombre,
      );
      let mensaje = `*${usuarioLogeado.nombre}* ha editado la sección *"${seccion.titulo}"* en la categoría *"${categorias[categoriaActualIndex]}"* del reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
      if (agregar) {
        mensaje = `*${usuarioLogeado.nombre}* ha agregado una sección *"${seccion.titulo}"* en la categoría *"${categorias[categoriaActualIndex]}"* del reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
      }
      await reporteService.actualizarReporte(
        newReporte,
        idReporte,
        usuarioLogeado.id,
        usuarioLogeado.nombre,
        mensaje,
      ); // Se actualiza el reporte en la base de datos.
      fetchData(); // Se actualizan los datos del reporte.
    } catch (error) {
      console.error("Error al guardar la sección:", error);
    }
    setOpenSectionEditDialog(false); // Se cierra el pop up de editar sección.
    setOpenSectionDialog(false); // Se cierra el pop up de agregar sección.
  };

  // Función para editar el titulo de la categoría.
  const handleEditCategory = async () => {
    // Validación de que el titulo esté completo.
    if (
      tituloIngresado === "" ||
      tituloIngresado === null ||
      tituloIngresado === undefined
    ) {
      handleOpenAlert("Por favor, complete el titulo de la categoría.");
      return;
    }

    const newReporte = {
      // Se crea un objeto con los datos de la categoría editada.
      coordenadas: {
        indexCategoria: categoriaActualIndex,
      },
      nuevoTituloCategoria: tituloIngresado,
    };
    setCategorias(
      categorias.map((categoria, index) => {
        // Se actualiza la lista de categorías.
        if (index === categoriaActualIndex) {
          return tituloIngresado;
        }
        return categoria;
      }),
    );
    try {
      let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
        (response) => response.data.nombre,
      );
      let mensaje = `*${usuarioLogeado.nombre}* ha editado el título de la categoría *"${categorias[categoriaActualIndex]}"* a *"${tituloIngresado}"* del reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
      await reporteService.actualizarReporte(
        newReporte,
        idReporte,
        usuarioLogeado.id,
        usuarioLogeado.nombre,
        mensaje,
      ); // Se actualiza el reporte en la base de datos.
      fetchData();
    } catch (error) {
      console.error("Error al guardar la categoría:", error); // Mostrar un mensaje de error.
    }
    setTituloIngresado(""); // Limpiar el titulo ingresado.
    setOpenCategoryEditDialog(false); // Cerrar el pop up de editar categoría.
  };

  // ---------------------- COMPARAR CAMPOS ----------------------

  const [openCompararCampos, setOpenCompararCampos] = useState(false);

  const handleOpenCompararCampo = (indexSeccion, indexCampo) => {
    setCampoActualIndex(indexCampo);
    setSeccionActualIndex(indexSeccion);
    setOpenCompararCampos(!openCompararCampos);
  };

  // ------------------------ RENDERIZADO ------------------------

  // Función para verificar si el usuario tiene acceso a una sección específica
  const tieneAccesoASeccion = useCallback(
    (categoriaIndex, seccionIndex) => {
      // Superadministrador, administrador y autorizador tienen acceso a todo
      if (
        usuarioLogeado?.rol === "0" ||
        usuarioLogeado?.rol === "4" ||
        usuarioLogeado?.rol === "6"
      ) {
        return true;
      }

      if (!rolUsuario || !rolUsuario.idCategorias) {
        return false;
      }

      const categoria = reporte?.categorias[categoriaIndex];
      if (!categoria) {
        return false;
      }

      const seccion = categoria.secciones[seccionIndex];
      if (!seccion) {
        return false;
      }

      // Buscar la categoría correspondiente en el rol
      const categoriaRol = rolUsuario.idCategorias.find(
        (cat) => cat.id === categoria.id,
      );
      if (!categoriaRol) {
        return false;
      }

      // Verificar si la sección está en las permitidas para esa categoría
      return categoriaRol.idSecciones?.includes(seccion.id) || false;
    },
    [rolUsuario, reporte, usuarioLogeado?.rol],
  );

  // Función para verificar si puede editar campos
  const puedeEditarCampo = useCallback(
    (categoriaIndex, seccionIndex) => {
      // Superadministrador, administrador y autorizador pueden editar todo
      if (
        usuarioLogeado?.rol === "0" ||
        usuarioLogeado?.rol === "4" ||
        usuarioLogeado?.rol === "6"
      ) {
        return true;
      }

      return (
        usuarioLogeado &&
        rolesEditarCampos.includes(parseInt(usuarioLogeado.rol)) &&
        tieneAccesoASeccion(categoriaIndex, seccionIndex)
      );
    },
    [usuarioLogeado, tieneAccesoASeccion, rolesEditarCampos],
  );

  // Función para verificar si puede autorizar campos
  const puedeAutorizarCampo = useCallback(
    (categoriaIndex, seccionIndex) => {
      // Superadministrador, administrador y autorizador pueden autorizar todo
      if (
        usuarioLogeado?.rol === "0" ||
        usuarioLogeado?.rol === "4" ||
        usuarioLogeado?.rol === "6"
      ) {
        return true;
      }

      return (
        usuarioLogeado &&
        rolesAutorizarRegistros.includes(parseInt(usuarioLogeado.rol)) &&
        tieneAccesoASeccion(categoriaIndex, seccionIndex)
      );
    },
    [usuarioLogeado, tieneAccesoASeccion, rolesAutorizarRegistros],
  );

  // Modificar la función puedeEditarAtributosCampo
  const puedeEditarAtributosCampo = useCallback(() => {
    return (
      usuarioLogeado?.rol === "0" ||
      usuarioLogeado?.rol === "1" ||
      usuarioLogeado?.rol === "6"
    ); // Superadmin (0), Editor (1) y Administrador (6)
  }, [usuarioLogeado]);

  // Agregar esta función helper junto a las otras funciones
  const formatTipoDato = (tipo) => {
    if (!tipo) return "";

    const tiposMap = {
      texto: "Texto",
      numerico: "Número",
      fecha: "Fecha",
      booleano: "Alternativa Única",
      tabla: "Tabla",
    };

    return tiposMap[tipo.toLowerCase()] || tipo;
  };

  // Modificar la función determinarEstadoCampo
  const determinarEstadoCampo = (campo) => {
    // Primero verificamos si está vacío, sin importar si está autorizado
    if (
      !campo.contenido &&
      (!campo.subCampos || campo.subCampos.every((sub) => !sub.contenido))
    ) {
      return {
        color: "#FF4E42", // Rojo
        tooltip: "Campo sin contenido",
      };
    } else if (campo.autorizado) {
      return {
        color: "#00DC4B", // Verde
        tooltip: "Campo autorizado",
      };
    } else {
      return {
        color: "#FF8831", // Naranja
        tooltip: "Campo pendiente de autorización",
      };
    }
  };

  // Modificar la función determinarEstadoSeccion
  const determinarEstadoSeccion = (seccion) => {
    if (!seccion.campos || seccion.campos.length === 0) {
      return {
        color: "#FF4E42", // Rojo
        tooltip: "Sección sin campos",
      };
    }

    const todosAutorizados = seccion.campos.every((campo) => campo.autorizado);
    const todosConContenido = seccion.campos.every((campo) => {
      return (
        campo.contenido ||
        (campo.subCampos && campo.subCampos.some((sub) => sub.contenido))
      );
    });

    if (!todosConContenido) {
      return {
        color: "#FF4E42", // Rojo
        tooltip: "Hay campos sin contenido",
      };
    } else if (todosAutorizados) {
      return {
        color: "#00DC4B", // Verde
        tooltip: "Todos los campos autorizados",
      };
    } else {
      return {
        color: "#FF8831", // Naranja
        tooltip: "Campos pendientes de autorización",
      };
    }
  };

  // Modificar la función determinarEstadoCategoria
  const determinarEstadoCategoria = (categoriaIndex) => {
    const categoria = reporte.categorias[categoriaIndex];

    if (
      !categoria ||
      !categoria.secciones ||
      categoria.secciones.length === 0
    ) {
      return {
        color: "#FF4E42", // Rojo
        tooltip: "Categoría sin secciones",
      };
    }

    const estadosSecciones = categoria.secciones.map((seccion) =>
      determinarEstadoSeccion(seccion),
    );

    if (estadosSecciones.some((estado) => estado.color === "#FF4E42")) {
      return {
        color: "#FF4E42", // Rojo
        tooltip: "Hay campos sin contenido",
      };
    }

    if (estadosSecciones.every((estado) => estado.color === "#00DC4B")) {
      return {
        color: "#00DC4B", // Verde
        tooltip: "Todos los campos están autorizados",
      };
    }

    return {
      color: "#FF8831", // Naranja
      tooltip: "Hay campos pendientes de autorización",
    };
  };

  return (
    <>
      {/* Si se está cargando, se muestra el componente de carga. */}
      {loading && (
        <>
          <Loading />
        </>
      )}
      {/* Si no se está cargando, se muestra el contenido del reporte. */}
      {!loading && (
        <>
          <NavbarReporte
            useSectionMode={true}
            categorias={reporte.categorias.map((cat) => cat.titulo)}
            categoriaActualIndex={categoriaActualIndex}
            onCategoriaChange={handleCategoriaChange}
            tituloReporte={reporte.titulo}
            anioReporte={reporte.anio}
            refreshReporte={fetchData}
            usuarioLogeado={usuarioLogeado}
            rolesEditarReporte={rolesEditarReporte}
            mostrarSemaforos={mostrarSemaforos}
            determinarEstadoCategoria={determinarEstadoCategoria}
            reportesComparacion={reportesComparacion}
          />
          <Breadcrumbs
            breadcrumbs={[
              { nombre: "Empresas", ruta: "/empresas", enabled: true },
              {
                nombre: nombreEmpresa,
                ruta: `/empresas/${idEmpresa}/reportes`,
                enabled: true,
              },
            ]}
            ultimoBreadcrumb={{
              nombre: `${tituloReporte} ${anioReporte}`,
              ruta: `/empresas/${idEmpresa}/reportes/${idReporte}`,
            }}
          />

          {/* Contenedor principal del reporte. */}
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "80vw",
              pb: "100px",
            }}
          >
            <Paper sx={{ mt: 2, p: 2, flexGrow: 1 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <Typography
                    color={"secondary.main"}
                    fontWeight={"bold"}
                    fontSize={"30px"}
                  >
                    {categorias[categoriaActualIndex]}{" "}
                    {/* Título de la categoría actual. */}
                    {(semaforosVisible || isAnimating) && (
                      <Tooltip
                        title={
                          determinarEstadoCategoria(categoriaActualIndex)
                            .tooltip
                        }
                        placement="bottom"
                        arrow
                      >
                        <CircleIcon
                          sx={{
                            color:
                              determinarEstadoCategoria(categoriaActualIndex)
                                .color,
                            fontSize: "1.2rem",
                            ml: 1,
                            verticalAlign: "middle",
                            transition: "all 0.3s ease",
                            animation: mostrarSemaforos
                              ? "fadeIn 0.5s"
                              : "fadeOut 0.5s",
                            "@keyframes fadeIn": {
                              "0%": {
                                opacity: 0,
                                transform: "scale(0.5) translateY(-10px)",
                                filter: "blur(3px)",
                              },
                              "100%": {
                                opacity: 1,
                                transform: "scale(1) translateY(0)",
                                filter: "blur(0)",
                              },
                            },
                            "@keyframes fadeOut": {
                              "0%": {
                                opacity: 1,
                                transform: "scale(1) translateY(0)",
                                filter: "blur(0)",
                              },
                              "100%": {
                                opacity: 0,
                                transform: "scale(0.5) translateY(10px)",
                                filter: "blur(3px)",
                              },
                            },
                            "&:hover": {
                              transform: "scale(1.2)",
                              filter: "brightness(1.2)",
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>

                {/* Botón para editar la categoría. */}
                {usuarioLogeado &&
                  rolesEditarReporte.includes(parseInt(usuarioLogeado.rol)) && (
                    <Grid item xs={4} container justifyContent="flex-end">
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontStyle: "italic",
                          fontSize: "1.1rem",
                          width: "200px",
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "scale(1.02)",
                          },
                        }}
                        onClick={handleOpenCategoryEditDialog}
                        endIcon={<EditIcon style={{ fontSize: "1.1rem" }} />}
                      >
                        Editar Categoría
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </Paper>
            <DragDropContext onDragEnd={handleDragEnd}>
              {/* Contexto de arrastre de secciones */}
              <Droppable droppableId="secciones" type="section">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {Array.isArray(secciones) &&
                      secciones.map((seccion, indexSeccion) => {
                        const tienePermisoRol =
                          usuarioLogeado &&
                          rolUsuario?.idCategorias?.some((categoria) =>
                            categoria.idSecciones?.some(
                              (seccionId) => seccionId === seccion.id,
                            ),
                          );

                        let tienePermiso = usuarioLogeado;

                        return tienePermiso ? (
                          <Draggable
                            key={`seccion-${indexSeccion}`}
                            draggableId={`seccion-${indexSeccion}`}
                            index={indexSeccion}
                            isDragDisabled={!editMode}
                          >
                            {(provided) => (
                              <Paper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{
                                  mt: 2,
                                  p: 2,
                                  flexGrow: 1,
                                }}
                              >
                                <Grid container>
                                  {/* Título de la sección */}
                                  <Grid item xs={6}>
                                    <Typography
                                      color={"primary.main"}
                                      fontWeight={"bold"}
                                      fontSize={"25px"}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        color:
                                          highlightedId ===
                                          `seccion-${indexSeccion}`
                                            ? "#ebe417"
                                            : "secondary.main",
                                        transition: "color 0.5s ease",
                                        display: "flex",
                                        alignItems: "center", // Esto asegura alineación vertical
                                      }}
                                    >
                                      {seccion.titulo}
                                      {(semaforosVisible || isAnimating) && (
                                        <Tooltip
                                          title={
                                            determinarEstadoSeccion(seccion)
                                              .tooltip
                                          }
                                          placement="bottom"
                                          arrow
                                        >
                                          <CircleIcon
                                            sx={{
                                              color:
                                                determinarEstadoSeccion(seccion)
                                                  .color,
                                              fontSize: "1.2rem",
                                              ml: 1,
                                              verticalAlign: "middle",
                                              transition: "all 0.3s ease",
                                              animation: mostrarSemaforos
                                                ? "fadeIn 0.5s"
                                                : "fadeOut 0.5s",
                                              "@keyframes fadeIn": {
                                                "0%": {
                                                  opacity: 0,
                                                  transform:
                                                    "scale(0.5) translateY(-10px)",
                                                  filter: "blur(3px)",
                                                },
                                                "100%": {
                                                  opacity: 1,
                                                  transform:
                                                    "scale(1) translateY(0)",
                                                  filter: "blur(0)",
                                                },
                                              },
                                              "@keyframes fadeOut": {
                                                "0%": {
                                                  opacity: 1,
                                                  transform:
                                                    "scale(1) translateY(0)",
                                                  filter: "blur(0)",
                                                },
                                                "100%": {
                                                  opacity: 0,
                                                  transform:
                                                    "scale(0.5) translateY(10px)",
                                                  filter: "blur(3px)",
                                                },
                                              },
                                              "&:hover": {
                                                transform: "scale(1.2)",
                                                filter: "brightness(1.2)",
                                              },
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    {editMode && (
                                      <Typography
                                        fontSize={"20px"}
                                        color={"secondary.main"}
                                        fontWeight={"bold"}
                                      >
                                        Arrastre los campos o secciones para
                                        reordenarlos.
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>

                                {/* Contexto de arrastre de campos */}
                                <Droppable
                                  droppableId={`${indexSeccion}`}
                                  type="field"
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {seccion.campos.map(
                                        (campo, indexCampo) => (
                                          <Draggable
                                            key={`campo-${indexSeccion}-${indexCampo}`}
                                            draggableId={`campo-${indexSeccion}-${indexCampo}`}
                                            index={indexCampo}
                                            isDragDisabled={!editMode}
                                          >
                                            {(provided) => (
                                              <Box
                                                sx={{ pl: 2, pr: 2, my: 2 }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <Grid
                                                  container
                                                  alignItems="center"
                                                  justifyContent="space-between"
                                                  borderBottom={2}
                                                  borderColor={"secondary.main"}
                                                  sx={{ mx: 0, py: 1 }}
                                                >
                                                  {/* En la sección de renderizado del campo, junto al título */}
                                                  <Grid item xs={3}>
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                    >
                                                      <Typography
                                                        fontSize={"20px"}
                                                        color="#000000"
                                                        sx={{
                                                          fontWeight: "medium",
                                                          ml: 0.5,
                                                          color:
                                                            highlightedId ===
                                                            `campo-${indexSeccion}-${indexCampo}`
                                                              ? "#ebe417"
                                                              : "#373737",
                                                          transition:
                                                            "color 0.5s ease",
                                                        }}
                                                        id={`campo-${indexSeccion}-${indexCampo}`}
                                                      >
                                                        {campo.titulo}
                                                      </Typography>
                                                      {campo.descripcion && (
                                                        <Tooltip
                                                          title={
                                                            campo.descripcion
                                                          }
                                                        >
                                                          <Box
                                                            component="span"
                                                            sx={{
                                                              ml: 1,
                                                              cursor: "default",
                                                            }}
                                                          >
                                                            <InfoIcon
                                                              sx={{
                                                                fontSize: 16,
                                                                color:
                                                                  "action.disabled",
                                                              }}
                                                            />
                                                          </Box>
                                                        </Tooltip>
                                                      )}
                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={7}>
                                                    <Typography
                                                      variant="body1"
                                                      sx={{
                                                        fontStyle: "italic",
                                                        fontWeight: "medium",
                                                        fontSize: "20px",
                                                        pl: 2,
                                                        whiteSpace: "pre-line", // Muestra saltos de línea \n
                                                        color:
                                                          campo.tipo.toLowerCase() ===
                                                          "booleano"
                                                            ? campo.contenido
                                                              ? "green"
                                                              : "red"
                                                            : "#373737",
                                                      }}
                                                    >
                                                      {(() => {
                                                        //
                                                        // Subcomponente interno que maneja su propio estado (mostrarTodo).
                                                        //
                                                        function TextoConTruncado() {
                                                          const [
                                                            mostrarTodo,
                                                            setMostrarTodo,
                                                          ] =
                                                            React.useState(
                                                              false,
                                                            );

                                                          const textoCompleto =
                                                            campo.contenido ||
                                                            "";
                                                          const superaLimite =
                                                            textoCompleto.length >
                                                            200;

                                                          // Si NO se muestra todo y supera el límite, truncamos a 200 chars
                                                          let textoVisible =
                                                            textoCompleto;
                                                          if (
                                                            !mostrarTodo &&
                                                            superaLimite
                                                          ) {
                                                            textoVisible =
                                                              textoCompleto.substring(
                                                                0,
                                                                200,
                                                              ) + "...";
                                                          }

                                                          return (
                                                            <>
                                                              {textoVisible}
                                                              {/* Botón sólo si supera 200 caracteres */}
                                                              {superaLimite && (
                                                                <Button
                                                                  variant="text"
                                                                  onClick={() =>
                                                                    setMostrarTodo(
                                                                      (prev) =>
                                                                        !prev,
                                                                    )
                                                                  }
                                                                  sx={{
                                                                    textTransform:
                                                                      "none",
                                                                    fontWeight:
                                                                      "bold",
                                                                    fontSize:
                                                                      "20px",
                                                                    ml: 1, // margen a la izquierda
                                                                    transition:
                                                                      "transform 0.2s",
                                                                  }}
                                                                >
                                                                  {mostrarTodo
                                                                    ? "Ver menos"
                                                                    : "Ver completo"}
                                                                </Button>
                                                              )}
                                                            </>
                                                          );
                                                        }

                                                        // -------------------------
                                                        // Lógica según el tipo
                                                        // -------------------------
                                                        if (!campo.tipo)
                                                          return null;

                                                        // 1. Tipo "texto"
                                                        if (
                                                          campo.tipo.toLowerCase() ===
                                                          "texto"
                                                        ) {
                                                          return (
                                                            <TextoConTruncado />
                                                          );
                                                        }
                                                        // 2. Tipo "fecha"
                                                        else if (
                                                          campo.tipo === "fecha"
                                                        ) {
                                                          return campo.contenido
                                                            ? reporteFunctions.formatearFecha(
                                                                campo.contenido,
                                                                false,
                                                              )
                                                            : "";
                                                        }
                                                        // 3. Tipo "numerico"
                                                        else if (
                                                          campo.tipo ===
                                                          "numerico"
                                                        ) {
                                                          return reporteFunctions.formatearNumero(
                                                            campo.contenido,
                                                            campo.porcentaje,
                                                          );
                                                        }
                                                        // 4. Tipo "booleano"
                                                        else if (
                                                          campo.tipo.toLowerCase() ===
                                                          "booleano"
                                                        ) {
                                                          if (campo.contenido)
                                                            return "Sí";
                                                          if (
                                                            campo.contenido ===
                                                            false
                                                          )
                                                            return "No";
                                                          return "";
                                                        }
                                                        // 5. Tipo "tabla"
                                                        else if (
                                                          campo.tipo.toLowerCase() ===
                                                          "tabla"
                                                        ) {
                                                          return (
                                                            <div
                                                              style={{
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Button
                                                                variant="text"
                                                                onClick={() =>
                                                                  handleOpenVerTabla(
                                                                    campo.contenido,
                                                                    campo,
                                                                  )
                                                                }
                                                                sx={{
                                                                  textTransform:
                                                                    "none",
                                                                  fontWeight:
                                                                    "bold",
                                                                  fontSize:
                                                                    "15px",
                                                                  transition:
                                                                    "transform 0.2s",
                                                                  "&:hover": {
                                                                    transform:
                                                                      "scale(1.05) translateY(-2px)",
                                                                  },
                                                                }}
                                                                disabled={
                                                                  editMode
                                                                }
                                                                startIcon={
                                                                  <BackupTableIcon />
                                                                }
                                                              >
                                                                Ver Tabla
                                                              </Button>
                                                            </div>
                                                          );
                                                        }

                                                        // Caso por defecto
                                                        return null;
                                                      })()}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={2}
                                                    container
                                                    justifyContent={"flex-end"}
                                                  >
                                                    {/* Icono de mostrar semaforo */}
                                                    {mostrarSemaforos && (
                                                      <Tooltip
                                                        title={
                                                          determinarEstadoCampo(
                                                            campo,
                                                          ).tooltip
                                                        }
                                                        placement="bottom"
                                                        arrow
                                                      >
                                                        <CircleIcon
                                                          sx={{
                                                            color:
                                                              determinarEstadoCampo(
                                                                campo,
                                                              ).color,
                                                            fontSize: "1.2rem",
                                                            mr: 1,
                                                            alignSelf: "center",
                                                            transition:
                                                              "all 0.3s ease",
                                                            animation:
                                                              "fadeIn 0.5s",
                                                            "@keyframes fadeIn":
                                                              {
                                                                "0%": {
                                                                  opacity: 0,
                                                                  transform:
                                                                    "scale(0.5)",
                                                                },
                                                                "100%": {
                                                                  opacity: 1,
                                                                  transform:
                                                                    "scale(1)",
                                                                },
                                                              },
                                                            "&:hover": {
                                                              transform:
                                                                "scale(1.2)",
                                                              filter:
                                                                "brightness(1.2)",
                                                            },
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    )}

                                                    {/* Botón de autorizar campo */}
                                                    {puedeAutorizarCampo(
                                                      categoriaActualIndex,
                                                      indexSeccion,
                                                    ) && (
                                                      <Tooltip
                                                        title={
                                                          campo.autorizado
                                                            ? "Revocar Autorización"
                                                            : "Autorizar Campo"
                                                        }
                                                        placement="bottom"
                                                        arrow
                                                      >
                                                        <span>
                                                          <IconButton
                                                            variant="outlined"
                                                            color={
                                                              campo.autorizado
                                                                ? "cuaternary"
                                                                : "primary"
                                                            }
                                                            onClick={() =>
                                                              handleOpenAutorizarCampo(
                                                                indexCampo,
                                                                indexSeccion,
                                                              )
                                                            }
                                                            disabled={editMode}
                                                            sx={{
                                                              transition:
                                                                "transform 0.2s",
                                                              "&:hover": {
                                                                transform:
                                                                  "scale(1.1) translateY(-2px)",
                                                              },
                                                            }}
                                                          >
                                                            {campo.autorizado ? (
                                                              <DoneAllIcon />
                                                            ) : (
                                                              <CheckIcon />
                                                            )}
                                                          </IconButton>
                                                        </span>
                                                      </Tooltip>
                                                    )}
                                                    {/* Botón de comparar campos */}
                                                    {reporte &&
                                                      reporte.reportesComparacion &&
                                                      reporte
                                                        .reportesComparacion
                                                        .length > 0 && (
                                                        <Tooltip
                                                          title="Comparar Campos"
                                                          placement="bottom"
                                                          arrow
                                                        >
                                                          <span>
                                                            <IconButton
                                                              color="primary"
                                                              onClick={() =>
                                                                handleOpenCompararCampo(
                                                                  indexSeccion,
                                                                  indexCampo,
                                                                )
                                                              }
                                                              sx={{
                                                                transition:
                                                                  "transform 0.2s",
                                                                "&:hover": {
                                                                  transform:
                                                                    "scale(1.1) translateY(-2px)",
                                                                },
                                                              }}
                                                            >
                                                              <HistoryIcon color="primary" />
                                                            </IconButton>
                                                          </span>
                                                        </Tooltip>
                                                      )}

                                                    {/* Botón de editar campo */}
                                                    {puedeEditarCampo(
                                                      categoriaActualIndex,
                                                      indexSeccion,
                                                    ) &&
                                                      !campo.autorizado && (
                                                        <Tooltip
                                                          title="Editar Campo"
                                                          placement="bottom"
                                                          arrow
                                                        >
                                                          <span>
                                                            <IconButton
                                                              variant="outlined"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleOpenEditDialog(
                                                                  campo,
                                                                  indexSeccion,
                                                                  indexCampo,
                                                                )
                                                              }
                                                              disabled={
                                                                editMode
                                                              }
                                                              sx={{
                                                                transition:
                                                                  "transform 0.2s",
                                                                "&:hover": {
                                                                  transform:
                                                                    "scale(1.1) translateY(-2px)",
                                                                },
                                                              }}
                                                            >
                                                              <EditIcon />
                                                            </IconButton>
                                                          </span>
                                                        </Tooltip>
                                                      )}

                                                    {/* Botón de ver evidencias asociadas al campo */}
                                                    {usuarioLogeado &&
                                                      rolesVerEvidencias.includes(
                                                        parseInt(
                                                          usuarioLogeado.rol,
                                                        ),
                                                      ) && (
                                                        <Tooltip
                                                          title={
                                                            campo.evidencias
                                                              ? campo.evidencias
                                                                  .length === 0
                                                                ? "No contiene evidencias"
                                                                : "Visualizar Evidencias"
                                                              : "No contiene evidencias"
                                                          }
                                                          placement="bottom"
                                                          arrow
                                                          disableInteractive
                                                        >
                                                          <span>
                                                            <IconButton
                                                              variant="outlined"
                                                              color="primary"
                                                              onClick={() =>
                                                                handleVisualizarEvidenciaCampo(
                                                                  indexSeccion,
                                                                  indexCampo,
                                                                )
                                                              }
                                                              disabled={
                                                                (campo.evidencias
                                                                  ? campo
                                                                      .evidencias
                                                                      .length ===
                                                                    0
                                                                  : false) ||
                                                                editMode
                                                              }
                                                              sx={{
                                                                transition:
                                                                  "transform 0.2s",
                                                                "&:hover": {
                                                                  transform:
                                                                    "scale(1.1) translateY(-2px)",
                                                                },
                                                              }}
                                                            >
                                                              <FindInPageIcon />
                                                            </IconButton>
                                                          </span>
                                                        </Tooltip>
                                                      )}
                                                  </Grid>
                                                </Grid>
                                                {campo.subCampos &&
                                                  (campo.subCampos.length > 0 ||
                                                    campo.subCampos !=
                                                      null) && (
                                                    <Grid item xs={12}>
                                                      <Box
                                                        sx={{ pl: 4, pr: 4 }}
                                                      >
                                                        {campo.subCampos.map(
                                                          (
                                                            subCampos,
                                                            subIndex,
                                                          ) => (
                                                            <Grid
                                                              container
                                                              alignItems="center"
                                                              justifyContent="space-between"
                                                              borderBottom={2}
                                                              borderColor={
                                                                "secondary.main"
                                                              }
                                                              sx={{
                                                                mb: 1,
                                                                py: 1,
                                                              }}
                                                              key={subIndex}
                                                            >
                                                              {/* Título del subcampo */}
                                                              <Grid item xs={3}>
                                                                <Box
                                                                  display="flex"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    fontSize={
                                                                      "16px"
                                                                    }
                                                                    color={
                                                                      "#373737"
                                                                    }
                                                                    sx={{
                                                                      fontWeight:
                                                                        "medium",
                                                                    }}
                                                                  >
                                                                    {
                                                                      subCampos.titulo
                                                                    }
                                                                  </Typography>
                                                                  {subCampos.descripcion && (
                                                                    <Tooltip
                                                                      title={
                                                                        subCampos.descripcion
                                                                      }
                                                                    >
                                                                      <Box
                                                                        component="span"
                                                                        sx={{
                                                                          ml: 1,
                                                                          cursor:
                                                                            "default",
                                                                        }}
                                                                      >
                                                                        <InfoIcon
                                                                          sx={{
                                                                            fontSize: 14,
                                                                            color:
                                                                              "action.disabled",
                                                                          }}
                                                                        />
                                                                      </Box>
                                                                    </Tooltip>
                                                                  )}
                                                                </Box>
                                                              </Grid>
                                                              <Grid item xs={9}>
                                                                <Typography
                                                                  variant="body1"
                                                                  color={
                                                                    "#373737"
                                                                  }
                                                                  sx={{
                                                                    fontStyle:
                                                                      "italic",
                                                                    fontSize:
                                                                      "1.05rem",
                                                                    fontWeight:
                                                                      "medium",
                                                                    pl: 2,
                                                                    ml: 2,
                                                                    // Preservar saltos de línea si es texto largo con \n
                                                                    whiteSpace:
                                                                      "pre-line",
                                                                    color:
                                                                      subCampos.tipo ===
                                                                      "Booleano"
                                                                        ? subCampos.contenido
                                                                          ? "green"
                                                                          : "red"
                                                                        : undefined,
                                                                  }}
                                                                >
                                                                  {(() => {
                                                                    //
                                                                    // Subcomponente interno para manejar "Ver más" / "Ver menos"
                                                                    // con un estado que NO sale de este Typography.
                                                                    //
                                                                    function TextoConTruncado({
                                                                      texto,
                                                                    }) {
                                                                      const [
                                                                        mostrarTodo,
                                                                        setMostrarTodo,
                                                                      ] =
                                                                        React.useState(
                                                                          false,
                                                                        );

                                                                      const superaLimite =
                                                                        texto?.length >
                                                                        200;
                                                                      let textoVisible =
                                                                        texto;

                                                                      if (
                                                                        !mostrarTodo &&
                                                                        superaLimite
                                                                      ) {
                                                                        textoVisible =
                                                                          texto.substring(
                                                                            0,
                                                                            200,
                                                                          ) +
                                                                          "...";
                                                                      }

                                                                      return (
                                                                        <>
                                                                          {
                                                                            textoVisible
                                                                          }
                                                                          {superaLimite && (
                                                                            <Button
                                                                              variant="text"
                                                                              onClick={() =>
                                                                                setMostrarTodo(
                                                                                  (
                                                                                    prev,
                                                                                  ) =>
                                                                                    !prev,
                                                                                )
                                                                              }
                                                                              sx={{
                                                                                textTransform:
                                                                                  "none",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontStyle:
                                                                                  "italic",
                                                                                fontSize:
                                                                                  "16px",
                                                                                ml: 1,
                                                                                transition:
                                                                                  "transform 0.2s",
                                                                              }}
                                                                            >
                                                                              {mostrarTodo
                                                                                ? "Ver menos"
                                                                                : "Ver completo"}
                                                                            </Button>
                                                                          )}
                                                                        </>
                                                                      );
                                                                    }

                                                                    // -------------------------------
                                                                    // Lógica condicional por tipo
                                                                    // -------------------------------
                                                                    if (
                                                                      subCampos.tipo
                                                                    ) {
                                                                      const tipo =
                                                                        subCampos.tipo.toLowerCase();

                                                                      if (
                                                                        tipo ===
                                                                        "texto"
                                                                      ) {
                                                                        // Retornamos el subcomponente con truncado
                                                                        return (
                                                                          <TextoConTruncado
                                                                            texto={
                                                                              subCampos.contenido
                                                                            }
                                                                          />
                                                                        );
                                                                      } else if (
                                                                        tipo ===
                                                                        "fecha"
                                                                      ) {
                                                                        return subCampos.contenido
                                                                          ? reporteFunctions.formatearFecha(
                                                                              subCampos.contenido,
                                                                              false,
                                                                            )
                                                                          : "";
                                                                      } else if (
                                                                        tipo ===
                                                                        "numerico"
                                                                      ) {
                                                                        return reporteFunctions.formatearNumero(
                                                                          subCampos.contenido,
                                                                          subCampos.porcentaje
                                                                            ? true
                                                                            : false,
                                                                        );
                                                                      } else if (
                                                                        tipo ===
                                                                        "booleano"
                                                                      ) {
                                                                        if (
                                                                          subCampos.contenido
                                                                        ) {
                                                                          return "Sí";
                                                                        } else if (
                                                                          subCampos.contenido ===
                                                                          false
                                                                        ) {
                                                                          return "No";
                                                                        } else {
                                                                          return "";
                                                                        }
                                                                      } else if (
                                                                        tipo ===
                                                                        "tabla"
                                                                      ) {
                                                                        return (
                                                                          <div
                                                                            style={{
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <Button
                                                                              variant="text"
                                                                              onClick={() =>
                                                                                handleOpenVerTabla(
                                                                                  subCampos.contenido,
                                                                                  subCampos,
                                                                                )
                                                                              }
                                                                              disabled={
                                                                                editMode
                                                                              }
                                                                              sx={{
                                                                                textTransform:
                                                                                  "none",
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "15px",
                                                                                transition:
                                                                                  "transform 0.2s",
                                                                                "&:hover":
                                                                                  {
                                                                                    transform:
                                                                                      "scale(1.05) translateY(-2px)",
                                                                                  },
                                                                              }}
                                                                              startIcon={
                                                                                <BackupTableIcon />
                                                                              }
                                                                            >
                                                                              Ver
                                                                              Tabla
                                                                            </Button>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    }
                                                                    // Si no existe subCampos.tipo o no coincide
                                                                    return null;
                                                                  })()}
                                                                </Typography>
                                                              </Grid>
                                                            </Grid>
                                                          ),
                                                        )}
                                                      </Box>
                                                    </Grid>
                                                  )}
                                              </Box>
                                            )}
                                          </Draggable>
                                        ),
                                      )}
                                      {provided.placeholder}
                                      {/* Agregar Campo */}
                                      {puedeEditarAtributosCampo() && (
                                        <Grid container justifyContent="center">
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleOpenEditDialog(
                                                null,
                                                indexSeccion,
                                                -1,
                                              )
                                            }
                                            startIcon={<AddCircleOutlineIcon />}
                                          >
                                            Agregar Campo
                                          </Button>
                                        </Grid>
                                      )}
                                    </div>
                                  )}
                                </Droppable>
                              </Paper>
                            )}
                          </Draggable>
                        ) : null;
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* Agregar Sección */}
            {puedeEditarAtributosCampo() && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "20px",
                  mt: 2,
                }}
                onClick={() => {
                  setEditedSection({ titulo: "" });
                  setOpenSectionDialog(true);
                }}
                startIcon={<AddCircleOutlineIcon />}
              >
                Agregar Sección
              </Button>
            )}

            {/* Navbar Evidencias, Descargar Reporte, Guardar Plantilla, Autorizar Reporte */}
            {usuarioLogeado &&
              rolesNavbarEvidencia.includes(parseInt(usuarioLogeado.rol)) && (
                <NavbarEvidencia
                  evidencias={evidencias}
                  refreshEvidencias={refreshEvidencia}
                  usuarioLogeado={usuarioLogeado}
                  rolesGestionarEvidencias={rolesGestionarEvidencias}
                  rolesDescargarReporte={rolesDescargarReporte}
                  rolesGuardarPlantilla={rolesGuardarPlantilla}
                  rolesAutorizarReporte={rolesAutorizarReporte}
                  fetchData={fetchData}
                  reporteAutorizado={reporteAutorizado}
                  mostrarSemaforos={mostrarSemaforos}
                  setMostrarSemaforos={setMostrarSemaforos}
                />
              )}
          </Container>

          {/* Diálogo de edición de campo */}
          <Dialog open={openDialog} maxWidth="lg" fullWidth>
            <DialogContent>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  sx={{ height: "70vh", overflowY: "auto" }}
                  borderColor={"secondary.main"}
                >
                  {/* Campo */}
                  {editedField && (
                    <>
                      <Grid container>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="bold"
                        >
                          {isAdding ? "Agregar Campo" : "Editar Campo"}
                        </Typography>
                        {puedeEditarAtributosCampo() ? (
                          <TextField
                            label="Título del Campo"
                            name="titulo"
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={editedField.titulo}
                            onChange={handleFieldChange}
                            error={errorTituloCampo}
                            helperText={
                              errorTituloCampo
                                ? "Por favor, ingrese un título para el campo"
                                : ""
                            }
                            sx={{ width: "99%" }}
                          />
                        ) : (
                          <TextField
                            label="Título del Campo"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={editedField.titulo}
                            disabled
                            sx={{ width: "99%" }}
                          />
                        )}
                        {puedeEditarAtributosCampo() ? (
                          <TextField
                            label="Descripción del Campo"
                            name="descripcion"
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            value={editedField.descripcion || ""}
                            onChange={handleFieldChange}
                            sx={{ width: "99%" }}
                          />
                        ) : (
                          // Solo mostrar el TextField de descripción si hay contenido
                          editedField.descripcion && (
                            <TextField
                              label="Descripción del Campo"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              margin="normal"
                              value={editedField.descripcion}
                              disabled
                              sx={{ width: "99%" }}
                            />
                          )
                        )}
                        {puedeEditarAtributosCampo() ? (
                          <FormControl fullWidth margin="normal">
                            <InputLabel>Tipo de Dato</InputLabel>
                            <Select
                              label="Tipo de Dato"
                              name="tipo"
                              value={
                                editedField.tipo
                                  ? editedField.tipo.toLowerCase()
                                  : "texto"
                              }
                              sx={{ width: "99%" }}
                              onChange={handleFieldChange}
                            >
                              <MenuItem value="texto">Texto</MenuItem>
                              <MenuItem value="numerico">Número</MenuItem>
                              <MenuItem value="fecha">Fecha</MenuItem>
                              <MenuItem value="booleano">
                                Alternativa Única
                              </MenuItem>
                              <MenuItem value="tabla">Tabla</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            label="Tipo de Dato"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formatTipoDato(editedField.tipo)}
                            disabled
                            sx={{ width: "99%" }}
                          />
                        )}
                        <Grid item xs={12}>
                          {editedField.tipo ? (
                            editedField.tipo.toLowerCase() === "booleano" ? (
                              <FormControl fullWidth margin="normal">
                                <InputLabel>Valor</InputLabel>
                                <Select
                                  label="Valor"
                                  name="contenido"
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  value={editedField.contenido}
                                  onChange={handleFieldChange}
                                  renderValue={(value) => {
                                    if (value === "") {
                                      return "No especificado";
                                    }
                                    if (value === true) {
                                      return "Sí";
                                    }
                                    if (value === false) {
                                      return "No";
                                    }
                                    // Si quisieras contemplar más casos, podrías hacer algo aquí.
                                    return "No especificado";
                                  }}
                                  sx={{ width: "99%" }}
                                >
                                  <MenuItem value={""}>
                                    No especificado
                                  </MenuItem>
                                  <MenuItem value={true}>Sí</MenuItem>
                                  <MenuItem value={false}>No</MenuItem>
                                </Select>
                              </FormControl>
                            ) : editedField.tipo.toLowerCase() === "tabla" ? (
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleOpenTableDialog(
                                    editedField.contenido,
                                    -1,
                                  )
                                }
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  fontStyle: "medium",
                                  fontSize: "1rem",
                                  width: "99%",
                                  mt: 2,
                                }}
                              >
                                Editar Tabla
                              </Button>
                            ) : editedField.tipo.toLowerCase() === "fecha" ? (
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ mt: 0.6, width: "99%" }}
                              >
                                <Grid item xs={11}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={es}
                                  >
                                    <DatePicker
                                      label="Fecha"
                                      name="contenido"
                                      value={
                                        editedField.contenido
                                          ? new Date(editedField.contenido)
                                          : null
                                      }
                                      onChange={(date) => {
                                        // Si el usuario borró la fecha o introdujo algo inválido,
                                        // el picker puede darte `date = null` o un `Date` inválido.
                                        if (!date || isNaN(date.getTime())) {
                                          // Asignamos null a `contenido`
                                          setEditedField((prev) => ({
                                            ...prev,
                                            contenido: null,
                                          }));
                                        } else {
                                          // Aquí tenemos un Date válido, lo convertimos a ISO
                                          const formattedDate =
                                            date.toISOString();
                                          setEditedField((prev) => ({
                                            ...prev,
                                            contenido: formattedDate,
                                          }));
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      inputFormat="dd/MM/yyyy"
                                      sx={{ width: "100%" }}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={1}>
                                  <Tooltip
                                    title="Limpiar Fecha"
                                    placement="bottom"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() =>
                                        setEditedField({
                                          ...editedField,
                                          contenido: null,
                                        })
                                      }
                                      sx={{
                                        transition: "transform 0.2s",
                                        "&:hover": {
                                          transform: "scale(1.1)",
                                        },
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            ) : editedField.tipo.toLowerCase() ===
                              "numerico" ? (
                              <>
                                <TextField
                                  label="Valor"
                                  type="text"
                                  name="contenido"
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  autoComplete="off"
                                  sx={{ width: "99%" }}
                                  value={editedField.contenido}
                                  onChange={handleFieldChange}
                                  helperText="Por favor, no incluya separadores de miles, es libre de utilizar números decimales positivos/negativos con punto o coma. Ejemplo: 1234.56 o 1234,56"
                                  error={errorCampo}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {editedField.porcentaje ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                    inputProps: { step: "any" },
                                  }}
                                />

                                <FormControlLabel
                                  label="Porcentaje"
                                  control={
                                    <Checkbox
                                      checked={editedField.porcentaje}
                                      onChange={(event) => {
                                        setEditedField({
                                          ...editedField,
                                          porcentaje: event.target.checked,
                                        });
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <TextField
                                label="Valor"
                                name="contenido"
                                variant="outlined"
                                fullWidth
                                multiline
                                autoComplete="off"
                                minRows={4}
                                margin="normal"
                                sx={{
                                  width: "99%",
                                  ...(!puedeEditarAtributosCampo() && {
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "primary.main",
                                      },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "primary.main",
                                    },
                                  }),
                                }}
                                value={editedField.contenido || ""}
                                onChange={handleFieldChange}
                              />
                            )
                          ) : null}
                        </Grid>

                        {/* Subcampos */}
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{ mt: 2 }}
                            color="primary.main"
                          >
                            Subcampos
                          </Typography>
                          {editedField.subCampos != null &&
                            editedField.subCampos.map((subCampo, subIndex) => (
                              <Grid
                                container
                                key={subIndex}
                                sx={{ mb: 1, p: 2 }}
                                borderBottom={1}
                                borderColor={"secondary.main"}
                              >
                                <Grid item xs={12}>
                                  {puedeEditarAtributosCampo() ? (
                                    <TextField
                                      label="Título del Subcampo"
                                      name={`subCampos-titulo-${subIndex}`}
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      value={subCampo.titulo}
                                      onChange={(event) => {
                                        const newSubcampos = [
                                          ...editedField.subCampos,
                                        ];
                                        newSubcampos[subIndex].titulo =
                                          event.target.value;
                                        setEditedField({
                                          ...editedField,
                                          subCampos: newSubcampos,
                                        });
                                      }}
                                      error={errorTituloSubcampo[subIndex]}
                                      helperText={
                                        errorTituloSubcampo[subIndex]
                                          ? "Por favor, ingrese un título para el subcampo"
                                          : ""
                                      }
                                    />
                                  ) : (
                                    <TextField
                                      label="Título del Subcampo"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      value={subCampo.titulo}
                                      disabled
                                      sx={{ width: "99%" }}
                                    />
                                  )}
                                  {puedeEditarAtributosCampo() ? (
                                    <TextField
                                      label="Descripción del Subcampo"
                                      name={`subCampos-descripcion-${subIndex}`}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                      rows={4}
                                      margin="normal"
                                      value={subCampo.descripcion || ""}
                                      onChange={(event) => {
                                        const newSubcampos = [
                                          ...editedField.subCampos,
                                        ];
                                        newSubcampos[subIndex].descripcion =
                                          event.target.value;
                                        setEditedField({
                                          ...editedField,
                                          subCampos: newSubcampos,
                                        });
                                      }}
                                    />
                                  ) : (
                                    // Solo mostrar el TextField de descripción si hay contenido
                                    subCampo.descripcion && (
                                      <TextField
                                        label="Descripción del Subcampo"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="normal"
                                        value={subCampo.descripcion}
                                        disabled
                                        sx={{ width: "99%" }}
                                      />
                                    )
                                  )}
                                  {puedeEditarAtributosCampo() ? (
                                    <FormControl fullWidth margin="normal">
                                      <InputLabel>Tipo de Dato</InputLabel>
                                      <Select
                                        label="Tipo de Dato"
                                        name={`subCampos-tipo-${subIndex}`}
                                        value={subCampo.tipo}
                                        onChange={handleFieldChange}
                                      >
                                        <MenuItem value="Texto">Texto</MenuItem>
                                        <MenuItem value="Numerico">
                                          Número
                                        </MenuItem>
                                        <MenuItem value="Fecha">Fecha</MenuItem>
                                        <MenuItem value="Booleano">
                                          Alternativa Única
                                        </MenuItem>
                                        <MenuItem value="Tabla">Tabla</MenuItem>
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <TextField
                                      label="Tipo de Dato"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      value={formatTipoDato(subCampo.tipo)}
                                      disabled
                                      sx={{ width: "99%" }}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  {subCampo.tipo === "Booleano" ? (
                                    <FormControl fullWidth margin="normal">
                                      <InputLabel>
                                        Valor del Subcampo
                                      </InputLabel>
                                      <Select
                                        label="Valor del Subcampo"
                                        name={`subCampos-contenido-${subIndex}`}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={subCampo.contenido}
                                        onChange={(event) => {
                                          const newSubcampos = [
                                            ...editedField.subCampos,
                                          ];
                                          newSubcampos[subIndex].contenido =
                                            event.target.value;
                                          setEditedField({
                                            ...editedField,
                                            subCampos: newSubcampos,
                                          });
                                        }}
                                      >
                                        <MenuItem value={""}>
                                          No especificado
                                        </MenuItem>
                                        <MenuItem value={true}>Sí</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                      </Select>
                                    </FormControl>
                                  ) : subCampo.tipo === "Tabla" ? (
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        handleOpenTableDialog(
                                          subCampo.contenido,
                                          subIndex,
                                        )
                                      }
                                      sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontStyle: "medium",
                                        fontSize: "1rem",
                                        width: "100%",
                                        my: 2,
                                      }}
                                    >
                                      Editar Tabla
                                    </Button>
                                  ) : subCampo.tipo === "Fecha" ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      sx={{ mt: 0.6 }}
                                    >
                                      {/* DatePicker */}
                                      <Grid item xs={11}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                          adapterLocale={es}
                                        >
                                          <DatePicker
                                            label="Fecha del Subcampo"
                                            name={`subCampos-contenido-${subIndex}`}
                                            variant="outlined"
                                            margin="normal"
                                            value={
                                              subCampo.contenido
                                                ? new Date(subCampo.contenido)
                                                : null
                                            }
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                              const newSubcampos = [
                                                ...editedField.subCampos,
                                              ];

                                              // Verificamos si el usuario borró la fecha o ingresó algo inválido.
                                              if (
                                                !date ||
                                                isNaN(date.getTime())
                                              ) {
                                                // Guardamos null en 'contenido'.
                                                newSubcampos[
                                                  subIndex
                                                ].contenido = null;
                                              } else {
                                                // Guardamos la fecha convertida a ISO.
                                                newSubcampos[
                                                  subIndex
                                                ].contenido =
                                                  date.toISOString();
                                              }

                                              setEditedField((prev) => ({
                                                ...prev,
                                                subCampos: newSubcampos,
                                              }));
                                            }}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                fullWidth
                                                {...params}
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </Grid>

                                      {/* Botón para limpiar fecha */}
                                      <Grid item xs={1}>
                                        <Tooltip
                                          title="Limpiar Fecha"
                                          placement="bottom"
                                          arrow
                                        >
                                          <IconButton
                                            onClick={() => {
                                              const newSubcampos = [
                                                ...editedField.subCampos,
                                              ];
                                              newSubcampos[subIndex].contenido =
                                                null;
                                              setEditedField({
                                                ...editedField,
                                                subCampos: newSubcampos,
                                              });
                                            }}
                                            sx={{
                                              transition: "transform 0.2s",
                                              "&:hover": {
                                                transform: "scale(1.1)",
                                              },
                                            }}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  ) : subCampo.tipo === "Numerico" ? (
                                    <>
                                      <TextField
                                        label="Valor del Subcampo"
                                        type="text"
                                        name={`subCampos-contenido-${subIndex}`}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="off"
                                        sx={{ width: "100%", my: 2 }}
                                        value={subCampo.contenido}
                                        error={errorSubCampo[subIndex]}
                                        helperText="Por favor, no incluya separadores de miles, es libre de utilizar números decimales positivos/negativos con punto o coma. Ejemplo: 1234.56 o 1234,56"
                                        onChange={handleFieldChange}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {subCampo.porcentaje ? "%" : ""}{" "}
                                              {/* Muestra '%' al final si está incluido */}
                                            </InputAdornment>
                                          ),
                                          inputProps: { step: "any" }, // Permite decimales
                                        }}
                                      />
                                      <FormControlLabel
                                        label="Porcentaje"
                                        control={
                                          <Checkbox
                                            checked={subCampo.porcentaje}
                                            onChange={(event) => {
                                              const newSubcampos = [
                                                ...editedField.subCampos,
                                              ];
                                              if (event.target.checked) {
                                                newSubcampos[
                                                  subIndex
                                                ].porcentaje = true;
                                              } else {
                                                newSubcampos[
                                                  subIndex
                                                ].porcentaje = false;
                                              }
                                              setEditedField({
                                                ...editedField,
                                                subCampos: newSubcampos,
                                              });
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                      />
                                    </>
                                  ) : (
                                    <TextField
                                      label="Valor del Subcampo"
                                      name={`subCampos-contenido-${subIndex}`}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                      minRows={4}
                                      margin="normal"
                                      value={subCampo.contenido || ""}
                                      onChange={(event) => {
                                        const newSubcampos = [
                                          ...editedField.subCampos,
                                        ];
                                        newSubcampos[subIndex].contenido =
                                          event.target.value;
                                        setEditedField({
                                          ...editedField,
                                          subCampos: newSubcampos,
                                        });
                                      }}
                                      sx={{
                                        width: "99%",
                                        ...(!puedeEditarAtributosCampo() && {
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              borderColor: "primary.main",
                                            },
                                          },
                                          "& .MuiInputLabel-root": {
                                            color: "primary.main",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid container justifyContent="center">
                                  {puedeEditarAtributosCampo() && (
                                    <Button
                                      color="error"
                                      onClick={() =>
                                        handleDeleteSubcampo(subIndex)
                                      }
                                      startIcon={<CloseIcon />}
                                      sx={{
                                        transition: "transform 0.2s",
                                        "&:hover": {
                                          transform: "scale(1.02)",
                                        },
                                      }}
                                    >
                                      Eliminar Subcampo
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                          {puedeEditarAtributosCampo() && (
                            <Button
                              color="cuaternary"
                              variant="text"
                              onClick={() => handleAddSubcampo()}
                              startIcon={<AddCircleOutlineIcon />}
                              sx={{
                                transition: "transform 0.2s",
                                "&:hover": {
                                  transform: "scale(1.02)",
                                },
                              }}
                            >
                              Agregar Subcampo
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  sx={{ px: 2, height: "70vh" }}
                >
                  <Grid item xs={6} sx={{ overflowY: "auto", px: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="bold"
                      >
                        Gestión de Evidencia
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
                        Evidencias del Reporte
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Buscar Evidencias"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        margin="normal"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{
                          width: "100%",
                          ...(!puedeEditarAtributosCampo() && {
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: "primary.main",
                            },
                          }),
                        }}
                      />
                    </Grid>
                    {filteredEvidencia && (
                      <>
                        {filteredEvidencia.map((evidencia, index) => (
                          <Box key={index} sx={{ mt: 2, width: "100%" }}>
                            <Grid
                              container
                              borderBottom={2}
                              borderColor={"secondary.main"}
                              sx={{ py: 1 }}
                            >
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  color="#000000"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {evidencia.nombre}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Button
                                  variant="text"
                                  sx={{
                                    fontSize: "15px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    color: "primary.main",
                                    ml: 2,
                                    p: 0,
                                    transition: "transform 0.2s",
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                  }}
                                  onClick={() => accederEvidencia(evidencia)}
                                >
                                  {evidencia.tipo.toLowerCase() === "archivo"
                                    ? "Archivo"
                                    : "Página Web"}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="flex-end"
                              >
                                <Button
                                  color="cuaternary"
                                  variant="text"
                                  startIcon={<AddCircleOutlineIcon />}
                                  onClick={() =>
                                    handleAgregarEvidenciaAlCampo(evidencia)
                                  }
                                  sx={{
                                    transition: "transform 0.2s",
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                  }}
                                >
                                  Agregar al Campo
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} sx={{ overflowY: "auto", px: 2 }}>
                    <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
                      Evidencias del Campo
                    </Typography>
                    {selectedEvidencias && (
                      <>
                        {selectedEvidencias.map((evidencia, index) => (
                          <Box key={index} sx={{ mt: 2, width: "100%" }}>
                            <Grid
                              container
                              borderBottom={2}
                              borderColor={"secondary.main"}
                              sx={{ py: 1 }}
                            >
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  color="#000000"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {evidencia.nombre}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Button
                                  variant="text"
                                  sx={{
                                    fontSize: "15px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    color: "primary.main",
                                    ml: 2,
                                    p: 0,
                                    transition: "transform 0.2s",
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                  }}
                                  onClick={() => accederEvidencia(evidencia)}
                                >
                                  {evidencia.tipo.toLowerCase() === "archivo"
                                    ? "Archivo"
                                    : "Página Web"}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="flex-end"
                              >
                                <Button
                                  color="error"
                                  variant="text"
                                  startIcon={
                                    <RemoveCircleOutlineOutlinedIcon />
                                  }
                                  onClick={() =>
                                    handleRemoverEvidenciaDelCampo(evidencia)
                                  }
                                >
                                  Remover del Campo
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 1000,
              }}
            >
              <Collapse in={alerta}>
                <Alert severity="error" onClose={handleCloseAlert}>
                  {alertaTexto}
                </Alert>
              </Collapse>
              {isAdding ? null : (
                <Button
                  onClick={() =>
                    handleEliminarCampoDialog(
                      campoActualIndex,
                      seccionActualIndex,
                    )
                  }
                  color="error"
                >
                  Eliminar
                </Button>
              )}
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  setErrorCampo(false);
                  setErrorSubCampo((prev) => prev.fill(false));
                  setErrorTituloCampo(false);
                  setErrorTituloSubcampo((prev) => prev.fill(false));
                }}
                color="secondary"
              >
                Descartar
              </Button>
              <Button onClick={() => handleSaveField()} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para editar el titulo de la sección */}
          <Dialog
            open={openSectionEditDialog}
            onClose={() => handleCloseSectionEditDialog()}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                  >
                    Editar Sección
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  sx={{ mb: 2 }}
                >
                  <IconButton
                    onClick={() => handleCloseSectionEditDialog()}
                    disableRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ px: 2, mb: 2, mt: -1 }}>
                    Introduzca el título de la sección:
                  </Typography>
                  <Box sx={{ px: 2 }}>
                    <TextField
                      label="Título de la Sección"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      value={editedSection.titulo}
                      onChange={(event) =>
                        setEditedSection({
                          ...editedSection,
                          titulo: event.target.value,
                        })
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    onClick={() => handleOpenEliminarSeccion()}
                    color="error"
                  >
                    Eliminar
                  </Button>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => handleCloseSectionEditDialog()}
                  >
                    Descartar
                  </Button>
                  <Button
                    color="cuaternary"
                    variant="text"
                    onClick={() => handleSaveSection(false)}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>

          {/* Diálogo para editar el titulo de la categoría */}
          <Dialog
            open={openCategoryEditDialog}
            onClose={() => setOpenCategoryEditDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                  >
                    Editar Categoría
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  sx={{ mb: 2 }}
                >
                  <IconButton
                    onClick={() => setOpenCategoryEditDialog(false)}
                    disableRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ px: 2, mb: 2, mt: -1 }}>
                    Introduzca el título de la categoría:
                  </Typography>
                  <Box sx={{ px: 2 }}>
                    <TextField
                      label="Título de la Categoría"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      value={tituloIngresado}
                      onChange={(event) =>
                        setTituloIngresado(event.target.value)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={12} container justifyContent="flex-end">
                  {categorias && categorias.length > 1 && (
                    <Button
                      color="error"
                      onClick={() => setOpenEliminarCategoriaDialog(true)}
                    >
                      Eliminar
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setOpenCategoryEditDialog(false)}
                  >
                    Descartar
                  </Button>
                  <Button
                    color="cuaternary"
                    variant="text"
                    onClick={() => handleEditCategory()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>

          {/* Diálogo para eliminar categoría */}
          <Dialog open={openEliminarCategoriaDialog} maxWidth="md" fullWidth>
            <DialogTitle>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                sx={{ mt: 1 }}
              >
                Eliminar Categoría
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                ¿Está seguro de que desea eliminar la categoría "
                {categorias[categoriaActualIndex]}"?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="text"
                onClick={() => setOpenEliminarCategoriaDialog(false)}
              >
                Cancelar
              </Button>
              <Button
                color="error"
                variant="text"
                onClick={() => handleEliminarCategoria()}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para eliminar sección */}
          <Dialog open={openEliminarSeccionDialog} maxWidth="md" fullWidth>
            <DialogTitle>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                sx={{ mt: 1 }}
              >
                Eliminar Sección
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                ¿Está seguro de que desea eliminar la sección "
                {secciones.length > 0
                  ? secciones[seccionActualIndex]
                    ? secciones[seccionActualIndex].titulo
                    : ""
                  : ""}
                "?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="text"
                onClick={() => handleCloseEliminarSeccion()}
              >
                Cancelar
              </Button>
              <Button
                color="error"
                variant="text"
                onClick={() => handleEliminarSeccion()}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para agregar sección */}
          <Dialog
            open={openSectionDialog}
            onClose={() => setOpenSectionDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                  >
                    Agregar Sección
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  sx={{ mb: 2 }}
                >
                  <IconButton
                    onClick={() => setOpenSectionDialog(false)}
                    disableRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} container>
                  <Typography variant="body1">
                    Introduzca el título de la sección:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Título de la Sección"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    value={editedSection.titulo}
                    onChange={(event) =>
                      setEditedSection({
                        ...editedSection,
                        titulo: event.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setOpenSectionDialog(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => handleSaveSection(true)}
                  >
                    Agregar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>

          {/* Diálogo para eliminar campo */}
          <Dialog
            open={openEliminarCampoDialog}
            onClose={() => handleCerrarEliminarCampoDialog()}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                sx={{ mt: 1 }}
              >
                Eliminar Campo
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                ¿Está seguro de que desea eliminar el campo "
                {secciones[seccionActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex]
                  ? secciones[seccionActualIndex].campos[campoActualIndex]
                      .titulo
                  : ""}
                "?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="text"
                onClick={() => handleCerrarEliminarCampoDialog()}
              >
                Cancelar
              </Button>
              <Button
                color="error"
                variant="text"
                onClick={() => handleEliminarCampo()}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para ver tabla */}
          <Dialog
            open={openVerTablaDialog}
            onClose={handleCloseVerTabla}
            maxWidth="xl"
            fullWidth
          >
            {/* Eliminar el DialogTitle */}
            <DialogContent>
              <VerTabla csvString={csvData} tableTitle={currentTableTitle} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDescargarTablaCSV} color="success">
                Descargar CSV
              </Button>
              <Button onClick={handleCloseVerTabla} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para editar tabla */}
          <Dialog open={openTableDialog} maxWidth="xl" fullWidth>
            <DialogTitle>
              <Typography variant="h5" color="primary" fontWeight="bold">
                Editar Tabla
              </Typography>
              <IconButton
                onClick={handleCloseTableDialog}
                style={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Tabla csvString={csvData} onSave={handleSaveTable} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleCloseTableDialog()}
                color="secondary"
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para autorizar campo */}
          <Dialog
            open={openAutorizarCampoDialog}
            onClose={() => setOpenAutorizarCampoDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                sx={{ mt: 1 }}
              >
                {secciones[seccionActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex]
                  .autorizado === true
                  ? "Revocar Autorización"
                  : "Autorizar Campo"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ p: 2 }}>
                {secciones[seccionActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex]
                  .autorizado === true
                  ? "El campo ya ha sido autorizado. ¿Está seguro de que desea revocar la autorización del campo y habilitarlo para la edición?"
                  : "¿Está seguro de que desea autorizar el campo? Una vez autorizado, no podrá realizar cambios en el campo."}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="text"
                onClick={() => setOpenAutorizarCampoDialog(false)}
              >
                Cancelar
              </Button>
              <Button
                color={
                  secciones[seccionActualIndex] &&
                  secciones[seccionActualIndex].campos[campoActualIndex] &&
                  secciones[seccionActualIndex].campos[campoActualIndex]
                    .autorizado === true
                    ? "error"
                    : "cuaternary"
                }
                variant="text"
                onClick={() => handleAutorizarCampo()}
              >
                {secciones[seccionActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex]
                  .autorizado === true
                  ? "Revocar Autorización"
                  : "Autorizar"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Diálogo para ver la evidencia del campo */}
          <Dialog
            open={openEvidenciaDialog}
            onClose={() => setOpenEvidenciaDialog(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                sx={{ mt: 1 }}
              >
                Evidencias del campo "
                {secciones[seccionActualIndex] &&
                secciones[seccionActualIndex].campos[campoActualIndex]
                  ? secciones[seccionActualIndex].campos[campoActualIndex]
                      .titulo
                  : ""}
                "
              </Typography>
            </DialogTitle>
            <DialogContent>
              {selectedEvidencias && (
                <>
                  {selectedEvidencias.map((evidencia, index) => (
                    <Box sx={{ pl: 2, pr: 2, mb: 2 }} key={index}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom={2}
                        borderColor={"secondary.main"}
                        sx={{ mx: 0, py: 1 }}
                      >
                        <Grid item xs={3}>
                          <Typography
                            variant="h6"
                            color="#000000"
                            sx={{ fontWeight: "bold" }}
                          >
                            {evidencia.nombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} container>
                          <Typography
                            variant="h6"
                            color={"primary"}
                            sx={{
                              fontWeight: "medium",
                            }}
                          >
                            {evidencia.tipo.toLowerCase() === "archivo"
                              ? evidencia.nombreOriginal
                              : reporteFunctions.formatUrl(evidencia.url)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent={"flex-end"}>
                          <Tooltip
                            title={
                              evidencia.tipo.toLowerCase() === "archivo"
                                ? "Descargar"
                                : "Abrir"
                            }
                            placement="bottom"
                            arrow
                          >
                            <IconButton
                              variant="outlined"
                              color="primary"
                              onClick={() => accederEvidencia(evidencia)}
                            >
                              {evidencia.tipo.toLowerCase() === "archivo" ? (
                                <CloudDownloadIcon />
                              ) : (
                                <OpenInNewIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="text"
                onClick={() => setOpenEvidenciaDialog(false)}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
          {/* Diálogo para comparar campos */}
          <CompararCampos
            openCompararCampos={openCompararCampos}
            reporte={reporte}
            secciones={secciones}
            categoriaActualIndex={categoriaActualIndex}
            seccionActualIndex={seccionActualIndex}
            campoActualIndex={campoActualIndex}
            setOpenCompararCampos={setOpenCompararCampos}
            idEmpresa={idEmpresa}
          />
        </>
      )}
    </>
  );
}

export default Reporte;

import React from "react";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const BreadcrumbsMUI = ({ breadcrumbs, ultimoBreadcrumb }) => {

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, ml: 4 }} separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs !== undefined ? breadcrumbs.map((breadcrumb, index) => (
                breadcrumb.enabled ? (
                    <Link key={index} underline="hover" color="secondary" href={breadcrumb.ruta}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: "#888888",
                            "&:hover": {
                                color: "primary.main"
                            },
                            transition: "color 0.5s ease",
                        }}>
                        <Typography color="secondary" fontWeight="bold" sx={{
                            color: "inherit",
                            transition: "color 0.5s ease",
                        }}>
                            {breadcrumb.icono ? breadcrumb.icono : null}
                            {breadcrumb.nombre}
                        </Typography>
                    </Link>
                ) : (
                    <Typography key={index} color="text.secondary" fontWeight="bold" sx={{ ml: 1 }}>
                        {breadcrumb.icono ? breadcrumb.icono : null}
                        {breadcrumb.nombre}
                    </Typography>
                )
            )) : <Typography color="primary" fontWeight="bold" />
            }
            <Typography color="secondary" fontWeight="bold">
                {ultimoBreadcrumb.icono ? ultimoBreadcrumb.icono : null}
                {ultimoBreadcrumb.nombre}
            </Typography>
        </Breadcrumbs>
    );
}

export default BreadcrumbsMUI;
// Funciones útiles para los reportes.

// Función para convertir una dirección en una URL válida. 
// Ejemplo: 'www.google.com' -> 'https://www.google.com/'
export const formatUrl = (address) => {
    address = address.trim();

    if (!address.startsWith('http://') && !address.startsWith('https://')) {
        address = 'https://' + address;
    }

    if (!address.endsWith('/') && !address.includes('?') && !address.includes('#')) {
        address = address + '/';
    }

    return address;
};

// Función para formatear una fecha con el formato 'dd/mm/aa' o 'dd/mm/aa hh:mm:ss'.
export const formatearFecha = (fecha, mostrarHoras) => {
    const nuevaFecha = new Date(fecha);
    const dia = String(nuevaFecha.getDate()).padStart(2, '0');
    const mes = String(nuevaFecha.getMonth() + 1).padStart(2, '0');
    const año = String(nuevaFecha.getFullYear()).padStart(4, '0');
    
    if (!mostrarHoras) {
        return `${dia}/${mes}/${año}`;
    }
    
    const horas = String(nuevaFecha.getHours()).padStart(2, '0');
    const minutos = String(nuevaFecha.getMinutes()).padStart(2, '0');
    const segundos = String(nuevaFecha.getSeconds()).padStart(2, '0');
    
    return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
};

// Función para formatear un número con separador de miles y decimales.
export const formatearNumero = (value, porcentaje) => {
    let number = String(value); // Convertir a string para evitar errores con números.

    if (number.charAt(number.length - 1) === '%') {
        porcentaje = true;
        number = number.slice(0, -1); // Elimina el '%'
    }

    number = parseFloat(number.replace(',', '.'));

    if (isNaN(number)) return '';

    const formattedNumber = new Intl.NumberFormat('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
    }).format(number);

    return porcentaje ? `${formattedNumber}%` : formattedNumber;
};


// Función para comparar dos evidencias por su número.
export const compareEvidencias = (a, b) => {
    // Función de comparación personalizada.
    const regex = /\d+/; // Expresión regular para encontrar números.

    // Extraer los números de los nombres de las evidencias
    const numA = parseInt(a.nombre.match(regex)?.[0]) || 0;
    const numB = parseInt(b.nombre.match(regex)?.[0]) || 0;

    // Comparar los números como cadenas para tener en cuenta la longitud.
    const strNumA = numA.toString();
    const strNumB = numB.toString();

    if (strNumA.length !== strNumB.length) {
        return strNumA.length - strNumB.length; // Ordenar por longitud ascendente.
    } else {
        return strNumA.localeCompare(strNumB); // Ordenar como cadenas.
    }
};


import React, { useState, useEffect } from "react";
import { Container, Typography, IconButton, InputAdornment, Paper, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Navbar from "./Navbar";
import empresaService from "../services/EmpresaService";
import { Link } from "react-router-dom";
import usuarioService from "../services/UsuarioService";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";


function CrearUsuarioEmpresa() {
    const { idEmpresa } = useParams();
    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [contrasenia, setContrasenia] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [rol, setRol] = useState(2);
    const [empresa, setEmpresa] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [usuarioLogeado, setUsuarioLogeado] = useState(null);

    // Estados para errores
    const [correoError, setCorreoError] = useState("");
    const [contraseniaError, setContraseniaError] = useState("");

    const theme = useTheme();

    useEffect(() => {
        empresaService.getEmpresa(idEmpresa)
            .then(response => setEmpresa(response.data))
            .catch(error => console.error("Error al obtener la empresa:", error));

        usuarioService.getUsuarioLogueado()
            .then(response => setUsuarioLogeado(response.data))
            .catch(error => console.error("Error al obtener el usuario logeado:", error));
    }, []);

    useEffect(() => {
        // Se ejecuta cuando los estados esten cargados.
        if (usuarioLogeado && empresa) {
            const usuarioNoAutorizado = !(
                usuarioLogeado.rol &&
                (parseInt(usuarioLogeado.rol) === 0 ||
                    parseInt(usuarioLogeado.rol) === 6 ||
                    empresa.creadoresUsuarios.includes(usuarioLogeado.id))
            );

            if (usuarioNoAutorizado) {
                window.location.href = "/";
            }
        }
    }, [usuarioLogeado, empresa]);


    const handleCrearUsuario = async (event) => {
        event.preventDefault();

        // Validaciones
        const correoPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const contraseniaPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

        let isValid = true;

        if (!correoPattern.test(correo)) {
            setCorreoError("Por favor, ingrese un correo electrónico válido.");
            isValid = false;
        } else {
            setCorreoError("");
        }

        if (!contraseniaPattern.test(contrasenia)) {
            setContraseniaError("La contraseña debe tener al menos 8 caracteres, incluir 1 mayúscula, 1 minúscula, 1 número y 1 símbolo.");
            isValid = false;
        } else {
            setContraseniaError("");
        }

        if (!isValid) return;

        const userData = {
            nombre: nombre,
            correo: correo,
            contrasenia: contrasenia,
            rol: rol,
            empresas: [empresa.id]
        };

        try {
            await usuarioService.crearUsuario(userData);
            clearForm();
            setOpenDialog(true);
        } catch (error) {
            console.error('Error al crear el usuario:', error);

            // Verificar si es error de correo existente
            if (error.response && error.response.status === 500 &&
                error.response.data === "Usuario ya existe") {
                Swal.fire({
                    icon: "error",
                    title: "Lo sentimos",
                    text: "El correo electrónico ya está registrado en el sistema",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: theme.palette.primary.main
                });
            } else {
                // Manejo general de otros errores
                Swal.fire({
                    icon: "error",
                    title: "Lo sentimos",
                    text: "Ocurrió un error al crear el usuario. Por favor, intente nuevamente.",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: theme.palette.primary.main
                });
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const clearForm = () => {
        setNombre('');
        setCorreo('');
        setContrasenia('');
        setRol('');
        setCorreoError('');
        setContraseniaError('');
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Navbar />
            <Container>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h4" color={"primary.main"} fontWeight={"bold"} sx={{ mb: 2 }}>Crear Usuario</Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Link to={`/empresas/${idEmpresa}`} style={{ textDecoration: "none" }}>
                                <Button variant="outlined" color="primary" sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic", mr: 1, fontSize: "1.1rem", maxHeight: 0.7 }}>
                                    Volver
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleCrearUsuario}>
                        <Grid container spacing={2} sx={{ p: 1 }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nombre"
                                    name="nombre"
                                    variant="outlined"
                                    helperText="Nombre completo del usuario"
                                    fullWidth
                                    required
                                    onChange={(event) => setNombre(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Correo"
                                    name="correo"
                                    type="email"
                                    variant="outlined"
                                    helperText={correoError || "Correo electrónico del usuario"}
                                    error={!!correoError}
                                    fullWidth
                                    required
                                    onChange={(event) => setCorreo(event.target.value)}
                                    value={correo}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Contraseña"
                                    name="contrasenia"
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    helperText={contraseniaError || "Contraseña asignada al usuario"}
                                    error={!!contraseniaError}
                                    fullWidth
                                    required
                                    onChange={(event) => setContrasenia(event.target.value)}
                                    value={contrasenia}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel>Perfil</InputLabel>
                                    <Select
                                        label="Rol"
                                        name="rol"
                                        value={rol}
                                        onChange={(event) => setRol(event.target.value)}
                                    >
                                        {usuarioService.listaRoles
                                            .filter((_, index) => index >= 1) // Filtra desde el índice 1 en adelante
                                            .map((rol, index) => (
                                                <MenuItem key={index + 1} value={index + 1}>
                                                    {rol}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic", mr: 1, fontSize: "1.1rem" }}
                                >
                                    Crear Usuario
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Usuario creado"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            El usuario ha sido creado exitosamente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Link to="/usuarios" style={{ textDecoration: "none" }}>
                            <Button onClick={() => handleCloseDialog()} color="primary" autoFocus>
                                OK
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}

export default CrearUsuarioEmpresa;

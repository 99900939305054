import React from "react";

import { useEffect, useState } from "react";
import { Container, Paper, Grid, Typography, Box, FormControlLabel, Switch, TextField, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Navbar from "./Navbar";
import usuarioService from "../services/UsuarioService";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";


const AjustesUsuario = () => {
    const [usuarioLogeado, setUsuarioLogeado] = useState(null);
    const [rol, setRol] = useState(2);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const seccionActual = "";
    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];

    const [notificacionesCorreo, setNotificacionesCorreo] = useState(false);
    const [frecuenciaNotificaciones, setFrecuenciaNotificaciones] = useState(24);

    const notificacionesEditado = usuarioLogeado && (usuarioLogeado.ajustesUsuario &&
        notificacionesCorreo !== usuarioLogeado.ajustesUsuario.notificacionesCorreo ||
        frecuenciaNotificaciones !== usuarioLogeado.ajustesUsuario.intervaloNotificacionesCorreo);

    // Cargar la lista de empresas al cargar el componente
    useEffect(() => {
        setLoading(true);
        (async () => {
            await fetchData();
            setLoading(false);
        })();
    }, []);

    // Función para obtener la lista de empresas
    const fetchData = async () => {
        setLoading(true);
        try {
            const usuarioResponse = await usuarioService.getUsuarioLogueado();
            setUsuarioLogeado(usuarioResponse.data);
            setNotificacionesCorreo(usuarioResponse.data.ajustesUsuario.notificacionesCorreo);
            setFrecuenciaNotificaciones(usuarioResponse.data.ajustesUsuario.intervaloNotificacionesCorreo);
            localStorage.setItem('userName', usuarioResponse.data.nombre);
        } catch (error) {
            console.error('Error al obtener la lista de empresas:', error);
        }
        setLoading(false);
    };

    const handleNotificacionesCorreoChange = (event) => {
        setNotificacionesCorreo(event.target.checked);
    };

    const handleFrecuenciaNotificacionesChange = (event) => {
        const value = event.target.value;
        // Permitir solo números enteros positivos
        if (/^\d*$/.test(value)) {
            setFrecuenciaNotificaciones(value ? parseInt(value, 10) : "");
        }
    };

    return (
        <>
            <Navbar seccionActual={seccionActual} useSectionMode={false} secciones={secciones} seccionesRutas={seccionesRutas} />
            {loading && (<Loading />)}
            {!loading && usuarioLogeado && (
                <Container sx={{ pb: '100px' }}>
                    <Paper sx={{ mt: 2, p: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="h4" color="primary" fontWeight="bold" sx={{ mb: 2 }}>Ajustes de Usuario</Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic", mr: 1, fontSize: "1.1rem", maxHeight: 0.7 }}
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => navigate(-1)}
                                >
                                    Volver
                                </Button>
                            </Grid>
                        </Grid>
                        <Box sx={{ pl: 2, pr: 2, mt: 2 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Notificaciones</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notificacionesCorreo}
                                                onChange={handleNotificacionesCorreoChange}
                                                name="notificacionesCorreo"
                                                color="primary"
                                            />
                                        }
                                        label="Recibir notificaciones al correo"
                                    />
                                    {notificacionesCorreo && (
                                        <TextField
                                            label="Frecuencia de notificaciones (horas)"
                                            type="number"
                                            value={frecuenciaNotificaciones}
                                            onChange={handleFrecuenciaNotificacionesChange}
                                            onKeyDown={(e) => {
                                                // Permitir solo números y teclas de control (bloqueando punto y coma)
                                                if (
                                                    !(
                                                        (e.key >= "0" && e.key <= "9") || // Números
                                                        e.key === "Backspace" ||
                                                        e.key === "Delete" ||
                                                        e.key === "ArrowLeft" ||
                                                        e.key === "ArrowRight" ||
                                                        e.key === "ArrowUp" ||
                                                        e.key === "ArrowDown" ||
                                                        e.key === "Tab"
                                                    )
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            InputProps={{ inputProps: { min: 1, step: 1 } }}
                                            fullWidth
                                            margin="normal"
                                        />
                                    )}

                                </AccordionDetails>
                            </Accordion>
                            {notificacionesEditado && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={async () => {
                                        try {
                                            await usuarioService.actualizarUsuario({
                                                ...usuarioLogeado,
                                                ajustesUsuario: {
                                                    ...usuarioLogeado.ajustesUsuario,
                                                    notificacionesCorreo: notificacionesCorreo,
                                                    intervaloNotificacionesCorreo: frecuenciaNotificaciones
                                                }
                                            });
                                            await fetchData();
                                        } catch (error) {
                                            console.error('Error al actualizar los ajustes del usuario:', error);
                                        }
                                    }}
                                >
                                    Guardar
                                </Button>
                            )}
                        </Box>
                    </Paper>
                </Container>
            )}
        </>
    );
}

export default AjustesUsuario;
import React, { useState, useEffect } from "react";
import { Container, Typography, Tooltip, Paper, Button, Grid, Box, Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import Navbar from "./Navbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import reporteService from "../services/ReporteService";
import empresaService from "../services/EmpresaService";
import Loading from "./Loading";
import BreadcrumbsMUI from "./BreadcrumbsMUI";
import usuarioService from "../services/UsuarioService";
import Swal from "sweetalert2";



function VerEmpleados() {
    const { idEmpresa, idReporte } = useParams(); // Capturar parámetros dinámicos
    const navigate = useNavigate();

    const theme = useTheme();

    const secciones = ["Roles", "Colaboradores"];
    const seccionesRutas = [
        `/empresas/${idEmpresa}/reportes/${idReporte}/roles`,
        `/empresas/${idEmpresa}/reportes/${idReporte}/empleados`,
    ];
    const seccionActual = "Colaboradores";
    const [listaEmpleados, setListaEmpleados] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [nombreEmpresa, setNombreEmpresa] = useState("");
    const [tituloReporte, setTituloReporte] = useState("Reporte");

    useEffect(() => {
        fetchData();
    }, [idReporte, idEmpresa]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const [rolesResponse, tituloReporte, empleadosResponse, nombreEmpresaResponse] = await Promise.all([
                reporteService.getRoles(idReporte),
                reporteService.obtenerReporte(idReporte).then(res => res.data).then(data => data.titulo),
                empresaService.obtenerEmpleados(idEmpresa),
                empresaService.getEmpresa(idEmpresa).then(res => res.data).then(data => data.nombre),
            ]);

            // Filtrar empleados para excluir superadministradores
            const fetchedEmpleados = (empleadosResponse.data || []).filter(empleado => empleado.rol !== "0");
            const fetchedRoles = rolesResponse.data || [];

            setRoles(fetchedRoles);
            setTituloReporte(tituloReporte);
            setListaEmpleados(fetchedEmpleados);
            setNombreEmpresa(nombreEmpresaResponse);

            // Inicializar selectedRole con el rol actual de cada usuario
            const initialSelectedRole = fetchedRoles.reduce((acc, rol) => {
                rol.idUsuarios.forEach(userId => {
                    acc[userId] = rol.id;
                });
                return acc;
            }, {});

            setSelectedRole(initialSelectedRole);

        } catch (error) {
            console.error("Error al cargar datos:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRoleChange = (empleadoId, nuevoRol) => {
        setSelectedRole(prev => {
            const updated = { ...prev };

            if (nuevoRol === "") {
                // Si el nuevo rol es vacío, eliminamos el rol del usuario
                delete updated[empleadoId];
            } else {
                // Si no, asignamos el nuevo rol
                updated[empleadoId] = nuevoRol;
            }

            return updated;
        });
        setEdit(true); // Habilitar la edición
    };


    const handleGuardarCambios = async () => {
        try {
            const cambios = Object.entries(selectedRole).map(([empleadoId, rolId]) => ({
                empleadoId,
                rolId: rolId.toString(),
            }));

            if (cambios.length === 0) return;

            // Actualizar los roles manteniendo su estructura completa
            const updatedRoles = roles.map(rol => ({
                id: rol.id,
                nombre: rol.nombre,
                descripcion: rol.descripcion || '',
                permisos: rol.permisos || [],
                idCategorias: rol.idCategorias || [],
                idEmpresa: rol.idEmpresa,
                idUsuarios: [...rol.idUsuarios.filter(
                    userId => !cambios.some(cambio => cambio.empleadoId === userId)
                )]
            }));

            // Asignar los usuarios a sus nuevos roles
            cambios.forEach(({ empleadoId, rolId }) => {
                const targetRole = updatedRoles.find(rol => rol.id === rolId);
                if (targetRole && !targetRole.idUsuarios.includes(empleadoId)) {
                    targetRole.idUsuarios.push(empleadoId);
                }
            });

            await reporteService.actualizarRoles(idReporte, updatedRoles);
            await fetchData();

            setEdit(false);
            setOpenDialog(false);

            console.log('Roles actualizados:', updatedRoles);

            Swal.fire({
                icon: 'success',
                title: 'Roles actualizados',
                text: 'Los roles han sido actualizados correctamente',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: theme.palette.primary.main
            });

        } catch (error) {
            console.error("Error al guardar cambios:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudieron guardar los cambios. Por favor, intente nuevamente.',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: theme.palette.terciary.main
            });
        }
    };

    return (
        <>
            <Navbar
                useSectionMode={true}
                secciones={secciones}
                seccionesRutas={seccionesRutas}
                seccionActual={seccionActual}
            />
            <BreadcrumbsMUI
                breadcrumbs={[
                    { nombre: "Empresas", ruta: "/empresas", enabled: true },
                    { nombre: nombreEmpresa, ruta: `/empresas/${idEmpresa}/reportes`, enabled: true },
                    {
                        nombre: tituloReporte, ruta: `/empresas/${idEmpresa}/reportes/${idReporte}`, enabled: true
                    }]}
                ultimoBreadcrumb={{ nombre: "Colaboradores" }}
            />
            {loading && (
                <Loading />
            )}
            {!loading && (
                <Container sx={{ mt: 2, pb: '100px' }}>
                    <Paper sx={{ mt: 2, p: 2 }}>
                        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                            <Grid item>
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    Colaboradores{nombreEmpresa ? ` de ${nombreEmpresa}` : ""}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Volver al reporte" arrow>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ArrowBackIcon />}
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => navigate(`/empresas/${idEmpresa}/reportes/${idReporte}`)}
                                    >
                                        Volver
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {listaEmpleados.length > 0 && !loading && (
                            listaEmpleados.map((empleado, index) => (
                                <Box sx={{ pl: 2, pr: 2 }} key={index}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        borderBottom={2}
                                        borderColor={"secondary.main"}
                                        sx={{ mx: 0, mb: 1, py: 1 }}
                                    >
                                        {/* Nombre del empleado */}
                                        <Grid item xs={4}>
                                            <Typography
                                                variant="h5"
                                                color={"#000000"}
                                                sx={{ fontFamily: "Segoe UI", ml: -2 }}
                                            >
                                                {empleado.nombre}
                                            </Typography>
                                        </Grid>

                                        {/* Rol/Perfil del empleado */}
                                        <Grid item xs={4}>
                                            {empleado.rol !== "0" && (
                                                <Typography
                                                    variant="h6"
                                                    color={"#000000"}
                                                >
                                                    {usuarioService.listaRoles[empleado.rol]}
                                                </Typography>
                                            )}
                                        </Grid>

                                        {/* Selector de rol */}
                                        <Grid item xs={4} container justifyContent="flex-end">
                                            {!["0", "6", "5", "4"].includes(empleado.rol) && (
                                                <FormControl fullWidth size="small" sx={{ maxWidth: 200 }}>
                                                    <InputLabel>Asignar Rol</InputLabel>
                                                    <Select
                                                        value={selectedRole[empleado.id] || ""}
                                                        onChange={(e) => handleRoleChange(empleado.id, e.target.value)}
                                                        label="Asignar Rol"
                                                        sx={{ zIndex: 100 }}

                                                    >
                                                        {roles.length > 0 ? (
                                                            roles.map((rol, rolIndex) => (
                                                                <MenuItem value={rol.id} key={rolIndex}>
                                                                    {rol.nombre}
                                                                </MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem disabled>No hay roles disponibles...</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                        )}
                        {listaEmpleados.length === 0 && !loading && (
                            <Typography variant="body1" color="textSecondary" sx={{ fontStyle: "italic" }}>
                                No se encontraron empleados.
                            </Typography>
                        )}
                    </Paper>
                </Container>
            )}

            <Box bgcolor="#fff" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)", height: '80px', zIndex: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                            bgcolor: edit ? "primary.main" : "grey.400",
                            "&:hover": {
                                bgcolor: edit ? "primary.dark" : "grey.500",
                            },
                            cursor: edit ? "pointer" : "not-allowed",
                            color: edit ? "#fff" : "rgba(255, 255, 255, 0.7)",
                        }}
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                        disabled={!edit}
                    >
                        Guardar Cambios
                    </Button>

                </Box>
            </Box>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    <Typography variant="h5" color="primary" fontWeight="bold" sx={{ mt: 1 }}>Guardar Cambios</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">¿Estás seguro de que quieres guardar los cambios?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            setEdit(false);
                            setOpenDialog(false);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setEdit(false);
                            setOpenDialog(false);
                            handleGuardarCambios();
                        }}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default VerEmpleados;

import React from "react";
import {
    Box,
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    useTheme,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import loginService from "../services/LoginService";
import successImage from "../assets/success.png"; // Importa la imagen directamente

function ForgotPassword() {
    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState("");
    const [correoEnviado, setCorreoEnviado] = React.useState(false);

    const theme = useTheme();

    const handleSendEmail = async () => {
        if (!email) {
            setError("Por favor, complete todos los campos.");
        } else if (!validateEmail(email)) {
            setError("Por favor, introduzca un correo electrónico válido.");
        } else {
            setError("");

            // Mostrar SweetAlert2 de carga
            Swal.fire({
                title: "Enviando correo...",
                text: "Por favor, espere unos segundos.",
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading(); // Muestra el spinner de carga
                },
            });

            try {
                await loginService.forgotPassword(email);

                // Cerrar la alerta de carga
                Swal.close();

                setCorreoEnviado(true);
                Swal.fire({
                    icon: "success",
                    title: "Correo enviado",
                    text: "Si el correo está registrado, recibirá las instrucciones para restablecer la contraseña.",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: theme.palette.primary.main,
                });
            } catch (error) {
                // Cerrar la alerta de carga
                Swal.close();

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se pudo enviar el correo. Por favor, intente de nuevo más tarde.",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: theme.palette.terciary.main,
                    background: "#ffffff",
                    color: "#000000",
                });
            }
        }
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const useSectionMode = false;
    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];
    const seccionActual = "Empresas";

    return (
        <>
            <Navbar
                seccionActual={seccionActual}
                useSectionMode={useSectionMode}
                secciones={secciones}
                seccionesRutas={seccionesRutas}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    marginTop: "-64px",
                }}
            >
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
                        {!correoEnviado && (
                            <>
                                <LockPersonIcon
                                    fontSize="large"
                                    sx={{
                                        fontSize: 120,
                                        marginBottom: 4,
                                        color: "rgb(212 169 43 / 87%)",
                                    }}
                                />
                                <Typography variant="h4">Restablecer contraseña</Typography>
                                {error && (
                                    <Typography variant="body2" color={"terciary.main"} sx={{ marginTop: 2 }}>
                                        {error}
                                    </Typography>
                                )}
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: "center", marginTop: 2 }}
                                >
                                    Ingrese su correo electrónico y le enviaremos un enlace
                                    para restablecer su contraseña.
                                </Typography>

                                <TextField
                                    label="Correo electrónico"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    sx={{ marginTop: 2 }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <Button
                                    variant="contained"
                                    onClick={handleSendEmail}
                                    sx={{ marginTop: 2 }}
                                >
                                    Enviar Correo de Restablecimiento
                                </Button>
                            </>
                        )}
                        {correoEnviado && (
                            <>
                                <img
                                    src={successImage}
                                    alt="Éxito"
                                    style={{
                                        width: "120px",
                                        height: "120px",
                                        marginBottom: "16px",
                                    }}
                                />
                                <Typography variant="h4">¡El correo fue enviado con éxito!</Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: "left", marginTop: 2 }}
                                >
                                    {"Revise su bandeja de entrada y siga las instrucciones para restablecer su contraseña."}
                                </Typography>
                            </>
                        )}
                    </Paper>
                </Container>
            </Box>
        </>
    );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	Container,
	Grid,
	Typography,
	Paper,
	Avatar,
	Box,
	Button,
	CircularProgress,
	TextField,
	Autocomplete,
	Select,
	MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import usuarioService from "../services/UsuarioService";
import empresaService from "../services/EmpresaService";
import Navbar from "./Navbar";

function PerfilUsuario() {
	const { id } = useParams();
	const navigate = useNavigate();

	const [usuario, setUsuario] = useState({});
	const [usuarioOriginal, setUsuarioOriginal] = useState(null); // Para comparar cambios
	const [usuarioLogeado, setUsuarioLogeado] = useState(null);
	const [loading, setLoading] = useState(true);
	const [editMode, setEditMode] = useState(false);
	const [selectedEmpresas, setSelectedEmpresas] = useState([]);
	const [filteredEmpresas, setFilteredEmpresas] = useState([]);
	const [editPassword, setEditPassword] = useState(false);

	// Estados para manejo de contraseñas
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [passwordValidation, setPasswordValidation] = useState("");

	// Estados para errores en los campos
	const [correoError, setCorreoError] = useState(false);
	const [correoErrorText, setCorreoErrorText] = useState("");

	const [oldPasswordError, setOldPasswordError] = useState(false);
	const [oldPasswordErrorText, setOldPasswordErrorText] = useState("");

	const [newPasswordError, setNewPasswordError] = useState(false);
	const [newPasswordErrorText, setNewPasswordErrorText] = useState("");

	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");

	const [mismoUsuario, setMismoUsuario] = useState(false);

	// Regex de validaciones
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
	const correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	useEffect(() => {
		fetchUsuarioLogeado();
		fetchUsuario();
		// eslint-disable-next-line
	}, [id]);

	const fetchUsuarioLogeado = async () => {
		try {
			const response = await usuarioService.getUsuarioLogueado();
			setUsuarioLogeado(response.data);
			// Si no es superadmin y el ID no coincide, redireccionar
			if (response.data.rol !== "0" && response.data.id !== id) {
				navigate("/login");
			}
			// Verificamos si el usuario logueado es el mismo que estamos viendo
			if (response.data.id === id) {
				setMismoUsuario(true);
			}
		} catch (error) {
			console.error("Error al obtener el usuario logueado:", error);
		}
	};

	const fetchUsuario = async () => {
		setLoading(true);
		try {
			const userResponse = await usuarioService.getUsuario(id);
			const userData = userResponse.data;

			// Obtener todas las empresas
			const response = await empresaService.getListaEmpresas();
			const empresas = response.data || [];
			setFilteredEmpresas(empresas);

			// Mapeamos IDs -> objetos en la lista
			const userEmpresas = (userData.empresas || [])
				.map((idEmpresa) => empresas.find((emp) => emp.id === idEmpresa))
				.filter((emp) => emp !== undefined);

			const usuarioConEmpresas = {
				...userData,
				empresas: userEmpresas,
			};

			setUsuario(usuarioConEmpresas);
			setUsuarioOriginal(usuarioConEmpresas);

			if (userData.rol === "0") {
				// Si es superadmin, puede tener acceso a todas
				setSelectedEmpresas(empresas);
			} else {
				// Si no, solo las que tiene
				setSelectedEmpresas(userEmpresas);
			}
		} catch (error) {
			if (error.response && error.response.status === 404) {
				setFilteredEmpresas([]);
			} else {
				console.error("Error al obtener usuario o empresas:", error);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleInputChange = (field, value) => {
		if (field === "rol" && value === "0") {
			// Si es superadmin, seleccionar todas las empresas
			setSelectedEmpresas(filteredEmpresas);
		}
		setUsuario((prevUsuario) => ({
			...prevUsuario,
			[field]: value,
		}));
	};

	const handleCorreoChange = (e) => {
		const newCorreo = e.target.value;
		setUsuario((prevUsuario) => ({
			...prevUsuario,
			correo: newCorreo,
		}));
		// Validar formato de correo en tiempo real
		if (!correoRegex.test(newCorreo)) {
			setCorreoError(true);
			setCorreoErrorText("El correo electrónico no es válido.");
		} else {
			setCorreoError(false);
			setCorreoErrorText("");
		}
	};

	const handlePasswordChange = (e) => {
		const newPass = e.target.value;
		setNewPassword(newPass);
	
		if (newPass !== "") {
			setEditPassword(true);
		} else {
			setEditPassword(false);
		}

		// Validación de requisitos
		let errors = [];
		if (newPass.length < 8) errors.push("Debe tener al menos 8 caracteres.");
		if (!/[A-Z]/.test(newPass)) errors.push("Debe incluir al menos una letra mayúscula.");
		if (!/[a-z]/.test(newPass)) errors.push("Debe incluir al menos una letra minúscula.");
		if (!/\d/.test(newPass)) errors.push("Debe incluir al menos un número.");
		if (!/[\W_]/.test(newPass)) errors.push("Debe incluir al menos un carácter especial.");

		if (errors.length > 0) {
			setNewPasswordError(true);
			// Convertimos el array en un string con saltos de línea
			setNewPasswordErrorText(errors.join("\n"));
		} else {
			setNewPasswordError(false);
			setNewPasswordErrorText("");
		}

		// Validamos también si coincide con la confirmación actual
		if (passwordValidation && newPass !== passwordValidation) {
			setConfirmPasswordError(true);
			setConfirmPasswordErrorText("Las contraseñas no coinciden.");
		} else {
			setConfirmPasswordError(false);
			setConfirmPasswordErrorText("");
		}
	};

	const handlePasswordValidationChange = (e) => {
		const validationPassword = e.target.value;
		setPasswordValidation(validationPassword);

		if (validationPassword !== newPassword) {
			setConfirmPasswordError(true);
			setConfirmPasswordErrorText("Las contraseñas no coinciden.");
		} else {
			setConfirmPasswordError(false);
			setConfirmPasswordErrorText("");
		}
	};

	/**
	 * Determina si realmente hay cambios para evitar guardar innecesariamente
	 */
	const hayCambios = () => {
		if (!usuarioOriginal) return true;

		// Nombre
		if (usuario.nombre !== usuarioOriginal.nombre) return true;
		// Correo
		if (usuario.correo !== usuarioOriginal.correo) return true;
		// Rol
		if (usuario.rol !== usuarioOriginal.rol) return true;
		// Empresas
		const empresasActualesIds = selectedEmpresas.map((e) => e.id).sort();
		const empresasOriginalIds = usuarioOriginal.empresas.map((e) => e.id).sort();

		if (
			empresasActualesIds.length !== empresasOriginalIds.length ||
			empresasActualesIds.some((id, i) => id !== empresasOriginalIds[i])
		) {
			return true;
		}

		// Contraseña
		if (newPassword.trim() !== "") {
			return true;
		}

		return false;
	};

	/**
	 * Realiza la validación de campos antes de guardar y setea los errores en los inputs.
	 * Retorna true si todo es válido, false en caso contrario.
	 */
	const validateFields = () => {
		let isValid = true;

		// Reiniciar errores antes de validar
		setCorreoError(false);
		setCorreoErrorText("");
		setOldPasswordError(false);
		setOldPasswordErrorText("");
		setNewPasswordError(false);
		setNewPasswordErrorText("");
		setConfirmPasswordError(false);
		setConfirmPasswordErrorText("");

		// Validar correo
		if (!correoRegex.test(usuario.correo)) {
			setCorreoError(true);
			setCorreoErrorText("El correo electrónico no es válido.");
			isValid = false;
		}

		// Si el usuario quiere cambiar la contraseña (escribió algo en newPassword)
		if (newPassword.trim() !== "") {
			// Validar requisitos de la contraseña
			if (!passwordRegex.test(newPassword)) {
				setNewPasswordError(true);
				setNewPasswordErrorText(
					"La contraseña debe tener al menos 8 caracteres, incluir mayúsculas, minúsculas, números y un carácter especial."
				);
				isValid = false;
			}

			// Validar confirmación
			if (newPassword !== passwordValidation) {
				setConfirmPasswordError(true);
				setConfirmPasswordErrorText("Las contraseñas no coinciden.");
				isValid = false;
			}

			// Si es el mismo usuario, se requiere oldPassword
			if (mismoUsuario && oldPassword.trim() === "") {
				setOldPasswordError(true);
				setOldPasswordErrorText("Debes ingresar la contraseña actual.");
				isValid = false;
			}
		}

		return isValid;
	};

	const saveChanges = async () => {
		// Si introdujo alguna contraseña nueva, forzamos editPassword
		if (newPassword !== "") {
			setEditPassword(true);
		}

		// Validar campos antes de enviar
		const isValid = validateFields();
		if (!isValid) {
			// Si hay errores, no mostrar popup, solo no continuamos
			return;
		}

		// Comprobar si NO hay cambios
		if (!hayCambios()) {
			// Si no hay cambios, simplemente salimos del modo edición y listo
			setEditMode(false);
			// Reseteamos los campos de contraseña por si estaban abiertos
			resetPasswordFields();
			return;
		}

		console.log("Guardando cambios...");

		// Preparamos el objeto a enviar
		const updatedFields = {
			id: usuario.id,
			nombre: usuario.nombre,
			correo: usuario.correo,
			rol: usuario.rol,
			empresas: selectedEmpresas.map((e) => e.id),
			contrasenia: editPassword ? newPassword : undefined,
			recienCreado: !mismoUsuario ? editPassword : undefined,
		};

		// Quitar claves undefined
		Object.keys(updatedFields).forEach((key) => {
			if (updatedFields[key] === undefined) {
				delete updatedFields[key];
			}
		});

		// Validar contraseña antigua si está editando la suya
		if (editPassword && mismoUsuario) {
			try {
				await usuarioService.validarContrasenia(usuario.id, oldPassword);
			} catch (error) {
				if (error.response && error.response.status === 400) {
					// Error de contraseña antigua
					Swal.fire({
						title: "Lo sentimos",
						text: "La contraseña antigua no es correcta.",
						icon: "warning",
						confirmButtonText: "Aceptar",
						confirmButtonColor: "#FF6060",
					});
				} else {
					console.error("Error al validar la contraseña:", error);
					Swal.fire({
						title: "Lo sentimos",
						text: error.response?.data || "Ocurrió un problema al validar la contraseña.",
						icon: "warning",
						confirmButtonText: "Aceptar",
						confirmButtonColor: "#FF6060",
					});
				}
				// Volvemos a cargar el usuario original para revertir cambios
				fetchUsuario();
				return;
			}
		}

		// Llamar al servicio para actualizar
		try {
			// Confirmación si está cambiando su propio correo
			if (mismoUsuario && usuario.correo !== usuarioOriginal.correo) {
				const confirmacion = await Swal.fire({
					title: "Confirmación",
					text: "Al modificar el correo electrónico, es posible que se cierre la sesión. ¿Desea continuar?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Sí",
					cancelButtonText: "Cancelar",
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#FF6060",
				});

				if (!confirmacion.isConfirmed) {
					// Si el usuario cancela el cambio, recargamos el usuario original
					fetchUsuario();
					return;
				}
			}

			console.log("Enviando:", updatedFields);
			await usuarioService.actualizarUsuario(updatedFields);

			Swal.fire({
				title: "Éxito",
				text: "Usuario actualizado correctamente.",
				icon: "success",
				confirmButtonText: "Aceptar",
				confirmButtonColor: "#3085d6",
			});

			// Volvemos a recargar para ver datos actualizados
			await fetchUsuario();
			// Salimos del modo edición
			setEditMode(false);
			// Reseteamos campos de contraseña y banderas
			resetPasswordFields();
		} catch (error) {
			if (error.response && error.response.status === 400 && error.response.data.includes("correo ingresado ya se encuentra registrado")) {
				setCorreoError(true);
				setCorreoErrorText("El correo ingresado ya se encuentra registrado.");
			} else if (error.response && error.response.status === 400) {
				// Error devuelto por el servidor
				Swal.fire({
					title: "Lo sentimos",
					text: error.response.data,
					icon: "warning",
					confirmButtonText: "Aceptar",
					confirmButtonColor: "#FF6060",
				});
			} else {
				Swal.fire({
					title: "Lo sentimos",
					text: "Ocurrió un problema al actualizar el usuario.",
					icon: "warning",
					confirmButtonText: "Aceptar",
					confirmButtonColor: "#FF6060",
				});
			}
			console.error("Error al actualizar el usuario:", error);
		}
	};

	/**
	 * Resetea todos los campos de contraseña y errores relacionados
	 * después de guardar o descartar cambios.
	 */
	const resetPasswordFields = () => {
		setEditPassword(false);
		setOldPassword("");
		setNewPassword("");
		setPasswordValidation("");
		setNewPasswordError(false);
		setNewPasswordErrorText("");
		setConfirmPasswordError(false);
		setConfirmPasswordErrorText("");
		setOldPasswordError(false);
		setOldPasswordErrorText("");
	};

	return (
		<>
			<Navbar useSectionMode={false} />
			{loading ? (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						bgcolor: "background.default",
					}}
				>
					<CircularProgress size={80} />
					<Typography variant="h6" sx={{ mt: 2 }}>
						Cargando...
					</Typography>
				</Box>
			) : (
				<Container sx={{ mt: 4 }}>
					<Paper sx={{ p: 4, mt: 2, position: "relative" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								position: "absolute",
								top: 16,
								right: 16,
							}}
						>
							<Button
								variant="contained"
								sx={{
									textTransform: "none",
									fontWeight: "bold",
								}}
								startIcon={<ArrowBackIcon />}
								onClick={() => navigate(-1)}
							>
								Volver
							</Button>
						</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								mb: 4,
							}}
						>
							<Avatar
								sx={{
									width: 120,
									height: 120,
									bgcolor: "primary.main",
									fontSize: "3rem",
								}}
							>
								{usuario.nombre ? usuario.nombre.charAt(0) : ""}
							</Avatar>
							<Typography variant="h4" sx={{ mt: 2 }}>
								{usuario.nombre || ""}
							</Typography>
						</Box>

						<Typography
							variant="h5"
							color="primary"
							fontWeight="bold"
							sx={{ mb: 2 }}
						>
							Información del Usuario
						</Typography>

						<Grid container spacing={2}>
							{/* Nombre */}
							<Grid item xs={12} sm={6}>
								<Typography variant="h6">Nombre</Typography>
								{(editMode && usuarioLogeado && usuarioLogeado.rol === "0") ? (
									<TextField
									fullWidth
									value={usuario.nombre || ""}
									onChange={(e) => handleInputChange("nombre", e.target.value)}
									/>
								) : (
									<Typography>{usuario.nombre || "Sin nombre"}</Typography>
								)}
							</Grid>

							{/* Correo */}
							<Grid item xs={12} sm={6}>
								<Typography variant="h6">Correo Electrónico</Typography>
								{(editMode && usuarioLogeado && usuarioLogeado.rol === "0") ? (
									<TextField
									fullWidth
									value={usuario.correo || ""}
									onChange={handleCorreoChange}
									error={correoError}
									helperText={correoError ? correoErrorText : ""}
									/>
								) : (
									<Typography>{usuario.correo || "Sin correo"}</Typography>
								)}
							</Grid>

							{/* Rol */}
							{/* Solo superadmin puede cambiar el rol */}
								<Grid item xs={12} sm={6}>
									<Typography variant="h6">Rol</Typography>
									{(editMode && usuarioLogeado && usuarioLogeado.rol === "0") ? (
										<Select
											fullWidth
											value={usuario.rol || ""}
											onChange={(e) => handleInputChange("rol", e.target.value)}
										>
											{Object.entries(usuarioService.listaRoles).map(
												([key, value]) => (
													<MenuItem key={key} value={key}>
														{value}
													</MenuItem>
												)
											)}
										</Select>
									) : (
										<Typography>
											{usuarioService.listaRoles[usuario.rol] || "Sin rol"}
										</Typography>
									)}
								</Grid>

							{/* Contraseña */}
							<Grid item xs={12} sm={6}>
								<Typography variant="h6">Contraseña</Typography>
								{editMode ? (
									<>
										{mismoUsuario && (
											<TextField
												fullWidth
												type="password"
												value={oldPassword}
												onChange={(e) => setOldPassword(e.target.value)}
												label="Contraseña Actual"
												error={oldPasswordError}
												helperText={oldPasswordError ? oldPasswordErrorText : "Ingrese su contraseña actual."}
												sx={{ mb: 2 }}
											/>
										)}

										<TextField
											fullWidth
											type="password"
											value={newPassword}
											onChange={handlePasswordChange}
											error={newPasswordError}
											helperText={
												newPasswordError
													? newPasswordErrorText
													: "Ingrese su nueva contraseña."
											}
											label="Nueva Contraseña"
										/>

										<TextField
											fullWidth
											type="password"
											value={passwordValidation}
											onChange={handlePasswordValidationChange}
											error={confirmPasswordError}
											helperText={confirmPasswordError ? confirmPasswordErrorText : "Ingrese la contraseña nuevamente."}
											label="Confirmar Contraseña"
											sx={{ mt: 2 }}
										/>
									</>
								) : (
									<Typography>********</Typography>
								)}
							</Grid>

							{/* Empresas (solo para superadmin) */}
							{usuarioLogeado && usuarioLogeado.rol === "0" && (
								<Grid item xs={12}>
									<Typography variant="h6" sx={{ mb: 2 }}>
										Acceso a Empresas
									</Typography>
									{editMode && usuario.rol !== "0" ? (
										<Autocomplete
											disablePortal
											fullWidth
											multiple
											options={filteredEmpresas}
											getOptionLabel={(empresa) => empresa.nombre}
											value={selectedEmpresas}
											onChange={(event, newValue) => {
												setSelectedEmpresas(
													newValue.sort((a, b) => a.nombre.localeCompare(b.nombre))
												);
												setEditMode(true);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Empresas"
													variant="outlined"
												/>
											)}
										/>
									) : (
										<Box sx={{ p: 1, mt: -1 }}>
											<Typography>
												{selectedEmpresas.map((e) => e.nombre).join(", ") ||
													"Sin empresas asignadas"}
											</Typography>
										</Box>
									)}
								</Grid>
							)}
						</Grid>

						<Grid container spacing={2} sx={{ mt: 4 }} justifyContent="flex-end">
							<Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
								<Button
									variant="contained"
									color="primary"
									sx={{ textTransform: "none", fontWeight: "bold" }}
									onClick={async () => {
										if (editMode) {
											// Guardar cambios
											await saveChanges();
										} else {
											// Activar modo edición
											setEditMode(true);
										}
									}}
								>
									{editMode ? "Guardar Cambios" : "Editar"}
								</Button>
							</Box>
						</Grid>
					</Paper>
				</Container>
			)}
		</>
	);
}

export default PerfilUsuario;

import React, { useEffect, useState } from "react";
import { Box, Button, Container, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import Navbar from "./Navbar";
import CircularProgress from '@mui/material/CircularProgress';
import usuarioService from "../services/UsuarioService";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useNavigate, Link } from "react-router-dom";


const Notificaciones = () => {
    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];
    const seccionActual = "";

    const [loading, setLoading] = useState(true);
    const [usuarioLogeado, setUsuarioLogeado] = useState(null);
    const usuarioAdministrador = usuarioLogeado ? parseInt(usuarioLogeado.rol) === 0 : false;

    const [notificaciones, setNotificaciones] = useState([]);
    const [paginaNotificaciones, setPaginaNotificaciones] = useState(0);
    const [tieneMas, setTieneMas] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const usuarioResponse = await usuarioService.getUsuarioLogueado();
                setUsuarioLogeado(usuarioResponse.data);
                localStorage.setItem('userName', usuarioResponse.data.nombre);
            } catch (error) {
                console.error('Error al obtener el usuario logueado:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    // Este useEffect solo se ejecutará cuando el usuario haya sido cargado
    useEffect(() => {
        if (usuarioLogeado) {
            cargarMasNotificaciones(usuarioLogeado.id);
        }
    }, [usuarioLogeado]); // Solo se ejecuta cuando cambia usuarioLogeado

    const cargarMasNotificaciones = async (idUsuario) => {
        if (!tieneMas) return; // Si no hay más, no cargar nada
        if (!idUsuario) return; // Si el ID de usuario es nulo, no intentar cargar

        try {
            const nuevasNotificaciones = await usuarioService.cargarTodasLasNotificaciones(idUsuario, paginaNotificaciones).then(response => response.data);

            if (nuevasNotificaciones.length < 100) {
                // Si se cargaron menos de 100, asume que es la última página
                setTieneMas(false);
            }

            setNotificaciones(prevNotificaciones => [
                ...prevNotificaciones,
                ...nuevasNotificaciones
            ]);

            setPaginaNotificaciones(prevPagina => prevPagina + 1);
        } catch (error) {
            console.error("Error al cargar notificaciones:", error);
        }
    };

    // Función para convertir texto entre asteriscos en negritas
    const formatTextWithBold = (text) => {
        return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    };

    // Función para eliminar una notificación
    const handleEliminarNotificacion = async (index) => {
        try {
            await usuarioService.eliminarNotificacion(usuarioLogeado.id, index);
            setNotificaciones(prevNotificaciones =>
                prevNotificaciones.filter((_, i) => i !== index)
            );

        } catch (error) {
            console.error("Error al eliminar notificación", error);
        }
    };

    // Función para ir al detalle de una notificación
    const handleVerDetalles = (notificacion) => {
        const { idCampo, idSeccion, idCategoria, idEmpresa, idReporte } = notificacion;
        // Construimos los parámetros de consulta según los valores disponibles
        const params = new URLSearchParams();
        let queryString = "";
        let path = "";
        // Caso en que se trate de un cambio / creación del el reporte.
        if (idEmpresa && idReporte) {
            if (idCampo || idSeccion || idCategoria) {
                if (idCampo) params.append("idCampo", idCampo);
                if (idSeccion) params.append("idSeccion", idSeccion);
                if (idCategoria) params.append("idCategoria", idCategoria);

                queryString = params.toString();

                // Navegamos a la ruta con los parámetros de consulta
                path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
                navigate(`${path}?${queryString}`);
            } else {
                // Navegamos a la ruta sin los parámetros de consulta
                path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
                navigate(path);
            }
        } else if (idEmpresa) {
            path = `/empresas/${idEmpresa}/reportes`;
            navigate(path);
        }
    };


    return (
        <>
            <Navbar seccionActual={seccionActual} useSectionMode={usuarioAdministrador} secciones={secciones} seccionesRutas={seccionesRutas} />
            <Container sx={{ pb: '100px' }}>
                <Paper sx={{ mt: 2, p: 2 }}>
                    {loading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                bgcolor: 'background.default',
                            }}
                        >
                            <CircularProgress size={80} />
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Cargando...
                            </Typography>
                        </Box>
                    )}
                    {!loading && (
                        <>
                            <Box sx={{ pl: 2, pr: 2, mb: 2 }} display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h4" color={"primary.main"} fontWeight={"bold"}>Notificaciones</Typography>
                                <Link to={-1}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "1rem",
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                            mr: 1,
                                        }}
                                        startIcon={<ArrowBackIcon />}
                                    >
                                        Volver
                                    </Button>
                                </Link>
                            </Box>
                            {notificaciones.length > 0 ? (
                                notificaciones.map((notificacion, index) => (
                                    <Box sx={{ pl: 2, pr: 2, mb: 2 }} key={index}>
                                        <Grid container alignItems="center" justifyContent="space-between" key={index} borderBottom={2} borderColor={"secondary.main"} sx={{ mb: 2 }}>
                                            <Grid item xs={9}>
                                                <Typography
                                                    variant="h6"
                                                    color={"#000000"}
                                                    sx={{ fontFamily: "Segoe UI" }}
                                                    dangerouslySetInnerHTML={{ __html: formatTextWithBold(notificacion.mensaje) }}
                                                    fullWidth
                                                >
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} container justifyContent="flex-end" alignItems="center" spacing={1}>
                                                <Tooltip title="Eliminar notificación" placement="bottom">
                                                    <IconButton variant="outlined" color="secondary" sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic", mr: 1 }} onClick={() => handleEliminarNotificacion(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Ver detalles" placement="bottom">
                                                    <IconButton variant="outlined" color="primary" sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic" }}
                                                        onClick={() => handleVerDetalles(notificacion)}>
                                                        <ArrowForwardIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                                                <Typography>
                                                    <strong>Fecha:</strong> {new Date(notificacion.fecha).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                            <Divider fullWidth />
                                        </Grid>
                                    </Box>
                                ))
                            ) : (
                                <Box sx={{ pl: 2, pr: 2, mt: 2 }}>
                                    <Typography variant="body1" color="textSecondary" sx={{ fontStyle: "italic" }}>
                                        Aún no tienes notificaciones.
                                    </Typography>
                                </Box>
                            )}

                            {tieneMas && (
                                <Button
                                    variant="contained"
                                    onClick={() => cargarMasNotificaciones(usuarioLogeado?.id)}
                                    fullWidth
                                    sx={{ textTransform: "none", fontWeight: "bold", fontStyle: "italic", fontSize: "1rem", mb: 2, width: "250px" }}
                                >
                                    Cargar más
                                </Button>
                            )}
                        </>
                    )}
                </Paper>
            </Container>
        </>
    );
}

export default Notificaciones;

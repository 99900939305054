import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Typography,
    Grid,
    TextField,
    CircularProgress,
    Box,
    Divider,
    Link,
    Button,
    MenuItem,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import empresaService from "../services/EmpresaService";
import Navbar from "./Navbar";
import { RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select } from "@mui/material";
import usuarioService from "../services/UsuarioService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function EditarPerfilEmpresa() {
    const { idEmpresa } = useParams();
    const navigate = useNavigate();
    const [empresa, setEmpresa] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = React.useState({});
    const tiposSociedad = ["Sociedad Anónima", "Sociedad de Responsabilidad Limitada", "Empresa Individual", "Otra"];
    const [listaPaises, setListaPaises] = useState([]);
    const [usuarioLogeado, setUsuarioLogeado] = useState(null);
    const [puedeCrearUsuario, setPuedeCrearUsuario] = useState(false);

    useEffect(() => {
        // Obtener la lista de países en español
        const fetchCountries = async () => {
            try {
                const response = await fetch("https://restcountries.com/v3.1/all?fields=name,translations,cca2");
                const data = await response.json();

                // Crear lista con nombres en español
                const countriesInSpanish = data.map((country) => ({
                    code: country.cca2, // Código del país (ISO Alpha-2)
                    name: country.translations.spa.common, // Nombre en español
                }));

                // Ordenar lista alfabéticamente
                countriesInSpanish.sort((a, b) => a.name.localeCompare(b.name));

                // Actualizar el estado
                setListaPaises(countriesInSpanish);
            } catch (error) {
                console.error("Error al obtener la lista de países:", error);
            }
        };

        fetchCountries();

    }, []);

    // Obtener los detalles de la empresa
    useEffect(() => {
        const fetchEmpresa = async () => {
            try {
                // Obtener la empresa
                const responseEmpresa = await empresaService.getEmpresa(idEmpresa);
                const empresaData = responseEmpresa.data;
                setEmpresa(empresaData);
            } catch (error) {
                console.error("Error al obtener los detalles de la empresa:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUsuarioLogeado = async () => {
            try {
                const response = await usuarioService.getUsuarioLogueado();
                setUsuarioLogeado(response.data);
            } catch (error) {
                console.error("Error al obtener el usuario logeado:", error);
            }
        };
        fetchEmpresa();
        fetchUsuarioLogeado();

    }, [idEmpresa]);


    useEffect(() => {
        if (usuarioLogeado && empresa) {
            const puedeCrearUsuarioVar =
                parseInt(usuarioLogeado.rol) === 0 ||
                parseInt(usuarioLogeado.rol) === 6;

            setPuedeCrearUsuario(puedeCrearUsuarioVar);
        }
    }, [usuarioLogeado, empresa]);

    // Formatear el RUT
    const formatRut = (rut) => {
        return rut.trim(); // Elimina espacios en blanco
    };

    // Validar los campos del formulario
    const validateFields = (fields = empresa) => {
        const validationErrors = {};

        if (!fields.nombre || fields.nombre.trim() === "") {
            validationErrors.nombre = "El nombre de la empresa es obligatorio.";
        }

        if (!fields.tipoSociedad || fields.tipoSociedad.trim() === "") {
            validationErrors.tipoSociedad = "El tipo de sociedad es obligatorio.";
        }

        if (!fields.pais || fields.pais.trim() === "") {
            validationErrors.pais = "El campo de país es obligatorio.";
        }

        if (!fields.rut || fields.rut.trim() === "") {
            validationErrors.rut = fields.tipoRut === "Número Tributario"
                ? "El Número Tributario es obligatorio."
                : "El RUT es obligatorio.";
        } else {
            const rutError = validateIdentificacion(fields.rut, fields.tipoRut || "RUT");
            if (rutError) validationErrors.rut = rutError;
        }

        if (!fields.razonSocial || fields.razonSocial.trim() === "") {
            validationErrors.razonSocial = "La razón social es obligatoria.";
        }

        if (!fields.email || fields.email.trim() === "") {
            validationErrors.email = "El correo electrónico es obligatorio.";
        } else if (!validateEmail(fields.email)) {
            validationErrors.email = "Correo electrónico inválido.";
        }

        if (!fields.domicilioEmpresa || fields.domicilioEmpresa.trim() === "") {
            validationErrors.domicilioEmpresa = "La dirección comercial es obligatoria.";
        }

        if (fields.paginaWeb) {
            const urlError = validateURL(fields.paginaWeb);
            if (urlError) {
                validationErrors.paginaWeb = urlError;
            }
        }

        return validationErrors;
    };

    // Validar URL
    const validateURL = (url) => {
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
        return urlPattern.test(url) ? "" : "URL inválida. Debe ser una dirección válida (ej. https://www.ejemplo.com).";
    };

    // Validar RUT o DNI
    const validateIdentificacion = (value, tipo) => {
        if (tipo === "RUT") {
            const rutPattern = /^[0-9]+-[0-9Kk]$/;
            if (!rutPattern.test(value)) {
                return "RUT inválido. Debe estar en formato 12345678-9.";
            }
    
            const [rut, dv] = value.split("-");
            const calculatedDv = calcularDigitoVerificador(rut);
    
            if (calculatedDv.toString().toUpperCase() !== dv.toUpperCase()) {
                return "RUT inválido. El dígito verificador no coincide.";
            }
    
            return ""; // RUT válido
        } else if (tipo === "Número Tributario") {
            const dniPattern = /^[a-zA-Z0-9]{7,}$/;
            return dniPattern.test(value) ? "" : "Número Tributario inválido. Debe contener al menos 7 caracteres.";
        }
        return "";
    };
    
    const calcularDigitoVerificador = (rut) => {
        let suma = 0;
        let factor = 2;
    
        // Recorremos los dígitos del RUT de derecha a izquierda
        for (let i = rut.length - 1; i >= 0; i--) {
            suma += parseInt(rut[i], 10) * factor;
            factor = factor === 7 ? 2 : factor + 1; // Ciclo de factores de 2 a 7
        }
    
        const resto = suma % 11;
        const dv = 11 - resto;
    
        // Retornamos el dígito verificador calculado
        if (dv === 10) return "K";
        if (dv === 11) return "0";
        return dv;
    };
    // Validar Email
    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    // Validar Teléfono
    const validateTelefono = (telefono) => {
        const telefonoPattern = /^\+?[0-9]{9,12}$/;
        return telefonoPattern.test(telefono);
    };

    // Manejar cambios en los campos del formulario
    const handleInputChange = (field, value) => {
        setEmpresa((prev) => ({ ...prev, [field]: value }));

        let validationErrors = { ...errors };

        // Validar campo específico
        switch (field) {
            case "nombre":
                if (!value || value.trim() === "") {
                    validationErrors.nombre = "El nombre de la empresa es obligatorio.";
                } else {
                    delete validationErrors.nombre;
                }
                break;

            case "tipoSociedad":
                if (!value || value.trim() === "") {
                    validationErrors.tipoSociedad = "El tipo de sociedad es obligatorio.";
                } else {
                    delete validationErrors.tipoSociedad;
                }
                break;

            case "pais":
                if (!value || value.trim() === "") {
                    validationErrors.pais = "El campo de país es obligatorio.";
                } else {
                    delete validationErrors.pais;
                }
                break;

            case "rut":
                // Validar RUT o DNI en tiempo real
                const rutError = validateIdentificacion(value, empresa.tipoRut || "RUT");
                if (rutError) {
                    validationErrors.rut = rutError;
                } else {
                    delete validationErrors.rut;
                }
                break;

            case "tipoRut":
                // Cambiar el tipo de identificación limpia el error del RUT
                delete validationErrors.rut;
                break;

            case "razonSocial":
                if (!value || value.trim() === "") {
                    validationErrors.razonSocial = "La razón social es obligatoria.";
                } else {
                    delete validationErrors.razonSocial;
                }
                break;

            case "email":
                if (!value || value.trim() === "") {
                    validationErrors.email = "El Email es obligatorio.";
                } else if (!validateEmail(value)) {
                    validationErrors.email = "Correo electrónico inválido.";
                } else {
                    delete validationErrors.email;
                }
                break;

            case "telefono":
                if (!validateTelefono(value) && value !== "") {
                    validationErrors.telefono = "Teléfono inválido. Debe contener entre 9 y 12 dígitos.";
                } else {
                    delete validationErrors.telefono;
                }
                break;

            case "paginaWeb":
                const urlError = validateURL(value);
                if (value && urlError) {
                    validationErrors.paginaWeb = urlError;
                } else {
                    delete validationErrors.paginaWeb;
                }
                break;

            default:
                break;
        }

        // Actualizar los errores
        setErrors(validationErrors);
    };

    const handleSaveChanges = async () => {
        const validationErrors = validateFields();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            // Formatear RUT
            const formattedRut = formatRut(empresa.rut);

            // Verificar RUT/DNI en el backend
            const response = await empresaService.verificarRut(formattedRut);
            if (response.data && response.data.id !== empresa.id) {
                setErrors({
                    rut: "El Identificador ingresado ya está en uso por otra empresa. Por favor ingrese otro.",
                });
                return;
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("RUT no encontrado, puede usarse.");
            } else {
                console.error("Error al verificar el RUT:", error);
                setErrors({
                    rut: "Ocurrió un problema al verificar el RUT. Intente nuevamente más tarde.",
                });
                return;
            }
        }

        try {
            // Actualizar empresa en el backend
            await empresaService.actualizarEmpresa(empresa);

            // Salir del modo de edición
            setIsEditMode(false);
        } catch (error) {
            console.error("Error al actualizar la empresa:", error);
        }
    };

    return (
        <>
            <Navbar />
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Container>
                    <Paper sx={{ p: 4, mt: 3 }}>
                        {/* Título y Botón Volver */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h4" color="primary.main" fontWeight="bold">
                                    Editar Empresa
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => navigate(-1)}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                    }}
                                    startIcon={<ArrowBackIcon />}
                                >
                                    Volver
                                </Button>

                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2, borderWidth: 2, borderColor: "secondary.main" }} />

                        {/* Nombre de Fantasía */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Nombre de fantasía:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        label="Nombre de fantasía"
                                        fullWidth
                                        variant="outlined"
                                        value={empresa.nombre || ""}
                                        required
                                        onChange={(e) => handleInputChange("nombre", e.target.value)}
                                        error={!!errors.nombre}
                                        helperText={errors.nombre || "Nombre de la empresa"}
                                    />
                                ) : (
                                    <Typography>{empresa.nombre || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Tipo de Sociedad */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Tipo de sociedad:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={empresa.tipoSociedad || ""}
                                        onChange={(e) => handleInputChange("tipoSociedad", e.target.value)}
                                        error={!!errors.tipoSociedad}
                                        helperText={errors.tipoSociedad}
                                    >
                                        {tiposSociedad.map((tipo, index) => (
                                            <MenuItem key={index} value={tipo}>
                                                {tipo}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <Typography>{empresa.tipoSociedad || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* País */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">País:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>País</InputLabel>
                                        <Select
                                            label="País*"
                                            value={empresa.pais || ""}
                                            onChange={(e) => handleInputChange("pais", e.target.value)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                        overflowY: "auto",
                                                    },
                                                },
                                            }}
                                        >
                                            {listaPaises.map((country) => (
                                                <MenuItem key={country.code} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Typography>{empresa.pais || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Tipo de Identificación */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Tipo de identificación:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <RadioGroup
                                        row
                                        value={empresa.tipoRut || "RUT"}
                                        onChange={(e) => handleInputChange("tipoRut", e.target.value)}
                                    >
                                        <FormControlLabel value="RUT" control={<Radio />} label="RUT" />
                                        <FormControlLabel value="Número Tributario" control={<Radio />} label="Número Tributario" />
                                    </RadioGroup>
                                ) : (
                                    <Typography>{empresa.tipoRut || "RUT"}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* RUT / DNI */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">{empresa.tipoRut === "Número Tributario" ? "Número Tributario" : "RUT"}:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        label={empresa.tipoRut === "Número Tributario" ? "Número Tributario" : "RUT"}
                                        fullWidth
                                        variant="outlined"
                                        value={empresa.rut || ""}
                                        onChange={(e) => handleInputChange("rut", e.target.value)}
                                        error={!!errors.rut}
                                        helperText={
                                            errors.rut ||
                                            (empresa.tipoRut === "Número Tributario"
                                                ? "Debe contener al menos 7 caracteres."
                                                : "Sin puntos y con guión.")
                                        }
                                    />

                                ) : (
                                    <Typography>{empresa.rut || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Domicilio */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Dirección comercial:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={empresa.domicilioEmpresa || ""}
                                        onChange={(e) => handleInputChange("domicilioEmpresa", e.target.value)}
                                        error={!!errors.domicilioEmpresa}
                                        helperText={errors.domicilioEmpresa || "Domicilio de la empresa"}
                                    />
                                ) : (
                                    <Typography>{empresa.domicilioEmpresa || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Página Web */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Página web:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={empresa.paginaWeb || ""}
                                        onChange={(e) => handleInputChange("paginaWeb", e.target.value)}
                                        error={!!errors.paginaWeb}
                                        helperText={errors.paginaWeb ? errors.paginaWeb : "Página web de la empresa"}

                                    />
                                ) : (
                                    <Typography>{empresa.paginaWeb || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Razón Social */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Razón social:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={empresa.razonSocial || ""}
                                        onChange={(e) => handleInputChange("razonSocial", e.target.value)}
                                        error={!!errors.razonSocial}
                                        helperText={errors.razonSocial || "Razón Social de la empresa"}
                                        required
                                    />
                                ) : (
                                    <Typography>{empresa.razonSocial || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Email */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Correo electrónico:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        label="Correo electrónico"
                                        variant="outlined"
                                        type="email"
                                        required
                                        value={empresa.email || ""}
                                        onChange={(e) => handleInputChange("email", e.target.value)}
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email : "Correo electrónico de la empresa"}
                                    />
                                ) : (
                                    <Typography>{empresa.email || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Domicilio Contacto */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Domicilio de contacto:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Dirección de contacto"
                                        value={empresa.domicilioContacto || ""}
                                        onChange={(e) => handleInputChange("domicilioContacto", e.target.value)}
                                        error={!!errors.domicilioContacto}
                                        helperText="Domicilio de contacto de la empresa"
                                    />
                                ) : (
                                    <Typography>{empresa.domicilioContacto || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Teléfono */}
                        <Grid container alignItems="center" sx={{ py: 1 }}>
                            <Grid item xs={4}>
                                <Typography variant="body1" fontWeight="bold">Teléfono:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {isEditMode ? (
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        variant="outlined"
                                        value={empresa.telefono || ""}
                                        onChange={(e) => handleInputChange("telefono", e.target.value)}
                                        error={!!errors.telefono}
                                        helperText={errors.telefono ? errors.telefono : "Teléfono de contacto de la empresa"}
                                    />
                                ) : (
                                    <Typography>{empresa.telefono || ""}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: 1, borderColor: "secondary.main" }} />

                        {/* Botones Guardar/Editar */}
                        <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="flex-end">
                            {/* Botón Crear Usuario */}
                            {puedeCrearUsuario && (
                                <Link href={`/empresas/${idEmpresa}/crear-usuario`}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            mr: 2,
                                        }}
                                    >
                                        Crear Usuario
                                    </Button>
                                </Link>
                            )}

                            {isEditMode ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveChanges}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        mr: 2,
                                    }}
                                >
                                    Guardar
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsEditMode(true)}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        mr: 2,
                                    }}
                                >
                                    Editar
                                </Button>
                            )}
                        </Grid>
                    </Paper>
                </Container>
            )}
        </>
    );
}

export default EditarPerfilEmpresa;

import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Paper,
    Container,
    IconButton,
    InputAdornment,
    useTheme,
    Alert,
} from "@mui/material";
import Navbar from "./Navbar";
import Loading from "./Loading";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import LoginService from "../services/LoginService";

function ResetPassword({ usuarioNuevo, usuarioLogeado }) {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [idUsuario, setIdUsuario] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [error, setError] = useState("");
    const [passwordGuidelines, setPasswordGuidelines] = useState({
        length: false,
        upperLowerCase: false,
        specialChar: false,
    });

    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [hayToken, setHayToken] = useState(false);
    const [email, setEmail] = useState("");
    const [tokenEmail, setTokenEmail] = useState("");

    const theme = useTheme();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        const setupInitialState = async () => {
            try {
                if (usuarioNuevo) {
                    // Para usuarios nuevos solo necesitamos el email
                    setEmail("");
                    setTokenEmail(usuarioLogeado.correo);
                    setHayToken(false);
                    setFieldsDisabled(false);
                    return;
                }

                if (tokenFromUrl) {
                    // Para reset de contraseña validamos el token
                    setToken(tokenFromUrl);
                    const response = await LoginService.validateToken(tokenFromUrl);
                    if (response) {
                        setEmail("");
                        setIdUsuario(response.userId);
                        setTokenEmail(response.email);
                        setHayToken(true);
                        setFieldsDisabled(false);
                    }
                } else {
                    throw new Error("No se proporcionó la información necesaria");
                }
            } catch (error) {
                console.error('Error en setup:', error);
                if (!usuarioNuevo) {
                    Swal.fire({
                        icon: "error",
                        title: "Token inválido",
                        text: "El enlace ha expirado o no es válido. Por favor, solicite uno nuevo.",
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: theme.palette.primary.main,
                    }).then(() => {
                        window.location.href = "/login";
                    });
                    setFieldsDisabled(true);
                }
            }
        };

        if (usuarioLogeado || tokenFromUrl) {
            setupInitialState();
        } else {
            localStorage.removeItem("token");
            window.location.href = "/login";
        }
    }, [usuarioNuevo, usuarioLogeado]);

    const isPasswordValid = (password) => {
        const length = password.length >= 8;
        const upperLowerCase = /[a-z]/.test(password) && /[A-Z]/.test(password);
        const specialChar = /[\W_]/.test(password);

        setPasswordGuidelines({ length, upperLowerCase, specialChar });

        return length && upperLowerCase && specialChar;
    };

    const handleChangePassword = async () => {
        if (!password || !verifyPassword || !email) {
            setError("Por favor, completa todos los campos.");
            return;
        }

        if (!usuarioNuevo && email !== tokenEmail) {
            setError("El correo electrónico no coincide con el asociado al enlace de recuperación.");
            return;
        }

        if (!isPasswordValid(password)) {
            setError("La contraseña no cumple con los requisitos mínimos");
            return;
        }

        if (password !== verifyPassword) {
            setError("Las contraseñas no coinciden");
            return;
        }

        try {
            setLoading(true);
            // Para usuarios nuevos, no enviamos token
            await LoginService.resetPassword(usuarioNuevo ? null : token, password, email);

            Swal.fire({
                icon: "success",
                title: usuarioNuevo ? "¡Bienvenido!" : "¡Contraseña actualizada!",
                text: usuarioNuevo
                    ? "Su contraseña ha sido establecida correctamente. Ahora puede iniciar sesión."
                    : "Su contraseña ha sido actualizada correctamente. Puede iniciar sesión con su nueva contraseña.",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            }).then(() => {
                window.location.href = "/login";
            });
        } catch (error) {
            console.error('Error:', error);
            let errorMessage = error.response.data;
            if (errorMessage.includes("Error al restablecer la contraseña: ")) {
                errorMessage = errorMessage.replace("Error al restablecer la contraseña: ", "");
            }
            Swal.fire({
                icon: "warning",
                title: "Lo sentimos",
                text: errorMessage,
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];
    const seccionActual = "Empresas";

    return (
        <>
            <Navbar
                seccionActual={seccionActual}
                secciones={secciones}
                seccionesRutas={seccionesRutas}
            />
            {loading && <Loading />}
            {!loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                        marginTop: "-64px",
                    }}
                >
                    <Container maxWidth="sm">
                        <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
                            <LockPersonIcon
                                fontSize="large"
                                sx={{
                                    fontSize: 120,
                                    marginBottom: 2,
                                    color: "rgb(212 169 43 / 87%)",
                                }}
                            />
                            <Typography variant="h4" sx={{ marginBottom: 2 }} gutterBottom>
                                {hayToken
                                    ? "Recuperación de Contraseña"
                                    : "Nueva Contraseña"
                                }
                            </Typography>
                            {error && (
                                <Typography variant="body2" color={"terciary.main"}>
                                    {error}
                                </Typography>
                            )}
                            <Typography variant="body2" sx={{ textAlign: "center", marginY: 2 }}>
                                {hayToken
                                    ? "Para recuperar su cuenta, por favor, ingrese su nueva contraseña."
                                    : "Por su seguridad, el sistema solicita que cambie su contraseña. Esto es debido a que es la primera vez que inicia sesión.\nPor favor, ingrese su nueva contraseña para continuar."}
                            </Typography>
                            <Alert severity="info" sx={{ textAlign: "left", marginBottom: 2 }}>
                                <Typography variant="body2">
                                    La contraseña debe cumplir con los siguientes criterios:
                                </Typography>
                                <ul>
                                    <li style={{ color: passwordGuidelines.length ? "green" : "red" }}>
                                        Al menos 8 caracteres
                                    </li>
                                    <li
                                        style={{
                                            color: passwordGuidelines.upperLowerCase ? "green" : "red",
                                        }}
                                    >
                                        Al menos una letra mayúscula y una minúscula
                                    </li>
                                    <li
                                        style={{
                                            color: passwordGuidelines.specialChar ? "green" : "red",
                                        }}
                                    >
                                        Al menos un símbolo (Ej: @, #, $, %)
                                    </li>
                                </ul>
                            </Alert>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Correo Electrónico"
                                        variant="outlined"
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        helperText="Ingrese el correo electrónico asociado a la recuperación"
                                        error={!!error && !email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contraseña"
                                        variant="outlined"
                                        fullWidth
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        helperText="Ingrese su nueva contraseña"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            isPasswordValid(e.target.value);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={fieldsDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Confirme su nueva contraseña"
                                        variant="outlined"
                                        fullWidth
                                        type={showVerifyPassword ? "text" : "password"}
                                        value={verifyPassword}
                                        helperText="Confirme su contraseña"
                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={fieldsDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleChangePassword}
                                        sx={{ marginTop: 2 }}
                                        disabled={fieldsDisabled}
                                    >
                                        {hayToken ? "Recuperar Contraseña" : "Confirmar nueva contraseña"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </Box>
            )}
        </>
    );
}

export default ResetPassword;

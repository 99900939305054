import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const login = async (credenciales) => {
    try {
        // Realizar solicitud de login
        const response = await axios.post('/api/auth', credenciales);
        const { token } = response.data;
        const decodedToken = jwtDecode(token);

        // Almacenar datos del token en el localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('userId', decodedToken.id);
        localStorage.setItem('userRol', decodedToken.rol);

        console.log('Inicio de sesión exitoso');

        // Retornar datos de respuesta
        return response.data;

    } catch (error) {
        // Manejo detallado de errores
        if (error.response) {
            console.error('Error durante el inicio de sesión:', error.response.data);
            throw new Error(error.response.data || 'Error durante el inicio de sesión.');
        } else {
            console.error('Error desconocido durante el inicio de sesión:', error.message);
            throw new Error('Error desconocido durante el inicio de sesión.');
        }
    }
};

const forgotPassword = async (email) => {
    try {
        const response = await axios.post('/api/auth/forgot-password', { email });
        console.log('forgotPassword response:', response);
    } catch (error) {
        console.error('Error during forgotPassword:', error);
        throw error;
    }
};

const validateToken = async (token, email = null) => {
    try {
        let params = { token };
        if (email) {
            params.email = email;
        }

        const response = await axios.get(`/api/auth/validate-token`, { params });
        return response.data;
    } catch (error) {
        console.error('Error during validateToken:', error);
        throw error;
    }
};

const resetPassword = async (token, newPassword, email) => {
    try {
        const data = {
            token: token || 'new-user',
            newPassword,
            email
        };
        
        const response = await axios.put('/api/usuario/reset-password', data);
        return response.data;
    } catch (error) {
        console.error('Error during password reset:', error);
        throw error;
    }
};

export default {
    login,
    forgotPassword,
    validateToken,
    resetPassword
};
import React, { useState } from "react";
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Paper,
    Container,
    IconButton,
    InputAdornment,
    Link,
    useTheme,
} from "@mui/material";
import Navbar from "./Navbar";
import LoginService from "../services/LoginService";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        if (!email || !password) {
            Swal.fire({
                title: "Campos incompletos",
                text: "Por favor complete todos los campos",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
            return;
        }

        if (!validateEmail(email)) {
            Swal.fire({
                title: "Correo inválido",
                text: "Por favor ingrese un correo electrónico válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
            return;
        }

        // Mostrar Swal de carga
        Swal.fire({
            title: 'Iniciando sesión',
            text: 'Por favor espere...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            await LoginService.login({ correo: email, contrasenia: password });
            window.location.href = "/";
        } catch (error) {
            let title = "Acceso denegado";
            let icon = "error";

            if (error.message.includes("Error interno del servidor:")) {
                error.message = error.message.replace("Error interno del servidor:", "");
            }

            // Personalizar mensaje según el tipo de error
            if (error.message.includes("bloqueado")) {
                if (error.message.includes("múltiples intentos")) {
                    title = "Usuario bloqueado temporalmente";
                } else {
                    title = "Usuario bloqueado";
                }
            } else if (error.message.includes("incorrectos")) {
                title = "Credenciales incorrectas";
            }

            Swal.fire({
                title: title,
                text: error.message,
                icon: icon,
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    backgroundColor: "alt.main"
                }}
            >
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
                        <img src={require("../assets/Logo Reporte Integral.png")} style={{ width: "175px" }} />
                        <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 4, fontWeight: "bold" }} color="secondary">
                            Iniciar Sesión
                        </Typography>
                        <form onSubmit={handleLogin}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Correo Electrónico"
                                        variant="outlined"
                                        sx={{
                                            // Raíz del Textfield
                                            "& .MuiOutlinedInput-root": {
                                                color: "secondary.main",
                                                background: "alt.main",
                                                backgroundColor: "alt.main",
                                                // Borde del textfield
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "secondary.main",
                                                },
                                                // Borde cuando está seleccionado el textfield
                                                "&.Mui-focused": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "secondary.main",
                                                    },
                                                },
                                                // Borde cuando se le hace hover al textfield
                                                "&:hover:not(.Mui-focused)": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "secondary.main",
                                                    },
                                                },
                                            },
                                            // Color de la etiqueta del textfield
                                            "& .MuiInputLabel-outlined": {
                                                color: "secondary.main",
                                                // Color de la etiqueta del textfield cuando está seleccionado
                                                "&.Mui-focused": {
                                                    color: "secondary.main",
                                                    fontWeight: "bold",
                                                },
                                            },
                                        }}
                                        fullWidth
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contraseña"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        sx={{
                                            // Raíz del Textfield
                                            "& .MuiOutlinedInput-root": {
                                                color: "secondary.main",
                                                background: "alt.main",
                                                backgroundColor: "alt.main",
                                                // Borde del textfield
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "secondary.main",
                                                },
                                                // Borde cuando está seleccionado el textfield
                                                "&.Mui-focused": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "secondary.main",
                                                    },
                                                },
                                                // Borde cuando se le hace hover al textfield
                                                "&:hover:not(.Mui-focused)": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "secondary.main",
                                                    },
                                                },
                                            },
                                            // Color de la etiqueta del textfield
                                            "& .MuiInputLabel-outlined": {
                                                color: "secondary.main",
                                                // Color de la etiqueta del textfield cuando está seleccionado
                                                "&.Mui-focused": {
                                                    color: "secondary.main",
                                                    fontWeight: "bold",
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                    sx={{
                                                        background: "alt.main",
                                                        backgroundColor: "alt.main",
                                                        color: "alt.main"
                                                    }}>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        sx={{
                                                            "& .MuiTouchRipple-child": {
                                                                backgroundColor: "hover.main",
                                                                background: "hover.main"
                                                            }
                                                        }}
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff color="primary" />
                                                        ) : (
                                                            <Visibility color="primary" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Link href="/forgot-password">
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            sx={{ mt: 4, display: "block" }}
                                        >
                                            ¿Olvidó su contraseña?
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Typography color="white" sx={{ fontWeight: "bold" }}>Iniciar</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Container>
            </Box>
        </>
    );
}

export default Login;

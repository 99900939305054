import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Container, Tooltip, Typography, Paper, Button, Grid, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./Navbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import reporteService from "../services/ReporteService";
import empresaService from "../services/EmpresaService";
import BreadcrumbsMUI from "./BreadcrumbsMUI";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function VerRol() {
    const { idEmpresa, idReporte } = useParams();
    const secciones = ["Roles", "Colaboradores"];
    const seccionesRutas = [`/empresas/${idEmpresa}/reportes/${idReporte}/roles`, `/empresas/${idEmpresa}/reportes/${idReporte}/empleados`];
    const seccionActual = "Roles";
    const [listaRoles, setListaRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [rolAEliminar, setRolAEliminar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tituloReporte, setTituloReporte] = useState("");
    const [nombreEmpresa, setNombreEmpresa] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        fetchEmpresa();
        fetchReporte();
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await reporteService.getRoles(idReporte);
            setListaRoles(response.data.reverse() || []);
        } catch (error) {
            console.error("Error al obtener los roles:", error);
            setListaRoles([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchEmpresa = async () => {
        try {
            const response = await empresaService.getEmpresa(idEmpresa);
            setNombreEmpresa(response.data.nombre || response.data.razonSocial || "");
        } catch (error) {
            console.error("Error al obtener la empresa:", error);
            setNombreEmpresa("");
        }
    };

    const fetchReporte = async () => {
        try {
            const response = await reporteService.obtenerReporte(idReporte);
            setTituloReporte(response.data.titulo || "");
        } catch (error) {
            console.error("Error al obtener el reporte:", error);
            setTituloReporte("");
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRoles = Array.isArray(listaRoles)
        ? listaRoles.filter(rol =>
            rol.nombre.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const handleEliminar = async (rol) => {
        try {
            await reporteService.eliminarRol(idReporte, rol.id);
            fetchRoles();
        } catch (error) {
            console.error("Error al eliminar el rol:", error);
        }
        setOpenDialog(false);
    };

    return (
        <>
            <Navbar
                useSectionMode={true}
                secciones={secciones}
                seccionesRutas={seccionesRutas}
                seccionActual={seccionActual}
            />
            <BreadcrumbsMUI
                breadcrumbs={[
                    { nombre: "Empresas", ruta: "/empresas", enabled: true },
                    { nombre: nombreEmpresa, ruta: `/empresas/${idEmpresa}/reportes`, enabled: true },
                    { nombre: tituloReporte, ruta: `/empresas/${idEmpresa}/reportes/${idReporte}`, enabled: true }
                ]}
                ultimoBreadcrumb={{ nombre: "Roles" }}
            />
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        bgcolor: 'background.default',
                    }}
                >
                    <CircularProgress size={80} />
                    <Typography variant="h6" sx={{ mt: 2 }}>Cargando roles del reporte...</Typography>
                </Box>
            )}
            {!loading && (
                <Container sx={{ mt: 2, pb: '100px' }}>
                    <Paper sx={{ mt: 2, p: 2 }}>
                        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                            <Grid item>
                                <Typography variant="h4" color="primary.main" fontWeight="bold">
                                    Roles {tituloReporte !== "" ? `- ${tituloReporte} ` : ""} ({nombreEmpresa !== "" ? `${nombreEmpresa}` : ""})
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Volver al reporte" arrow>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ArrowBackIcon />}
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            ml: 2,
                                            height: "fit-content",
                                        }}
                                        onClick={() => navigate(`/empresas/${idEmpresa}/reportes/${idReporte}`)}
                                    >
                                        Volver
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <TextField
                            label="Buscar Rol"
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{ mb: 2 }}
                        />

                        {filteredRoles && filteredRoles.length !== 0 && filteredRoles.map((rol, index) => (
                            <Box sx={{ pl: 2, pr: 2 }} key={index}>
                                <Grid container alignItems="center" justifyContent="space-between" borderBottom={2} borderColor={"secondary.main"} sx={{ mx: 0, mb: 1, py: 1 }}>
                                    <Grid item xs={6}>
                                        <Typography variant="h5" color={"#000000"}>{rol.nombre}</Typography>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent="flex-end" spacing={1}>
                                        <Grid item>
                                            <Tooltip
                                                arrow
                                                placement="bottom"
                                                title="Editar Rol"
                                            >
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => navigate(`/empresas/${idEmpresa}/reportes/${idReporte}/roles/${rol.id}/editar`)}
                                                >
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip
                                                arrow
                                                placement="bottom"
                                                title="Eliminar Rol"
                                            >
                                                <IconButton
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => {
                                                        setRolAEliminar(rol);
                                                        setOpenDialog(true);
                                                    }}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Box>
                        ))}
                        {filteredRoles && filteredRoles.length === 0 && (
                            <Typography variant="body1" color="textSecondary" sx={{ fontStyle: "italic" }}>
                                No se encontraron roles.
                            </Typography>
                        )}
                    </Paper>
                </Container>

            )}
            <Box bgcolor="#fff" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)", height: '80px' }}>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ textTransform: "none", fontWeight: "bold", mr: 1 }}
                        onClick={() => {
                            navigate("agregar", { replace: false });
                        }}
                    >
                        Agregar Rol
                    </Button>
                </Box>
            </Box>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>Eliminar Rol</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        ¿Está seguro de que desea eliminar el rol <strong>{rolAEliminar?.nombre}</strong>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={() => handleEliminar(rolAEliminar)} color="error">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default VerRol;

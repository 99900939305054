import CloseIcon from "@mui/icons-material/Close";
import {
	Autocomplete,
	Box,
	Button,
	Chip,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import Navbar from "./Navbar";
import { Link, useParams, useNavigate } from "react-router-dom";
import empresaService from "../services/EmpresaService";
import reporteService from "../services/ReporteService";
import InformacionEmpresa from "./InformacionEmpresa";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "./Loading";
import usuarioService from "../services/UsuarioService";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import BreadcrumbsMUI from "./BreadcrumbsMUI";
import Swal from "sweetalert2";

function ListaReportes() {
	const useSectionMode = false;
	const secciones = ["Empresas", "Usuarios"];
	const seccionesRutas = ["/empresas", "/usuarios"];
	const seccionActual = "Empresa A";
	const [listaReportes, setReportes] = useState([]);
	const [infoEmpresa, setInfoEmpresa] = useState({});
	const { idEmpresa } = useParams();
	const [openEliminarReporte, setOpenEliminarReporte] = useState(false);
	const [reporteAEliminar, setReporteAEliminar] = useState(null);
	const [openCrearReporte, setOpenCrearReporte] = useState(false);
	const [openUtilizarPreconfiguracion, setOpenUtilizarPreconfiguracion] =
		useState(false);
	const [openSinPreconfiguraciones, setOpenSinPreconfiguraciones] =
		useState(false);
	const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState(null);
	const [preconfiguraciones, setPreconfiguraciones] = useState([]);
	const [tituloReporte, setTituloReporte] = useState("");
	const [anioReporte, setAnioReporte] = useState(null);
	const [tituloReporteError, setTituloReporteError] = useState(false);
	const [anioReporteError, setAnioReporteError] = useState(false);
	const [openDescargarReporteDialog, setOpenDescargarReporteDialog] =
		useState(false);
	const [idReporte, setIdReporte] = useState(null);
	const [loading, setLoading] = useState(true);
	const [usuarioLogeado, setUsuarioLogeado] = useState(null); // Información del usuario logeado
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false); // Estado para el diálogo de confirmación
	const [plantillaAEliminar, setPlantillaAEliminar] = useState(null); // Plantilla a eliminar
	const [reportesComparacion, setReportesComparacion] = useState([]);

	const navigate = useNavigate();

	const handleReporteComparacionChange = (event, newValues) => {
		setReportesComparacion(newValues.map((item) => item.id)); // Guarda las ids de los reportes.
	};

	const handleOpenConfirmDelete = (plantillaId) => {
		setPlantillaAEliminar(plantillaId);
		setOpenConfirmDelete(true);
	};

	const handleCloseConfirmDelete = () => {
		setPlantillaAEliminar(null);
		setOpenConfirmDelete(false);
	};

	const handleDeleteConfirmed = () => {
		handleEliminarPreconfiguracion(plantillaAEliminar);
		handleCloseConfirmDelete();
	};

	const handleOpenCrearReporte = () => {
		setOpenCrearReporte(true);
	};

	const handleCloseCrearReporte = () => {
		setOpenCrearReporte(false);
		setAnioReporte(null);
		setTituloReporte("");
	};

	const handleTituloReporteChange = (event) => {
		setTituloReporte(event.target.value);
	};

	const handleOpenUtilizarPreconfiguracion = () => {
		setOpenUtilizarPreconfiguracion(true);
		handleCloseCrearReporte();
	};

	const handleCloseUtilizarPreconfiguracion = () => {
		setOpenUtilizarPreconfiguracion(false);
		setAnioReporte(null);
		setTituloReporte("");
		setPlantillaSeleccionadaId(null);
	};

	const handlePlantillaSeleccionadaChange = (event) => {
		setPlantillaSeleccionadaId(event.target.value);
	};

	const handleOpenSinPreconfiguraciones = () => {
		setOpenSinPreconfiguraciones(true);
		handleCloseUtilizarPreconfiguracion();
		handleCloseCrearReporte();
	};

	const handleCloseSinPreconfiguraciones = () => {
		setOpenSinPreconfiguraciones(false);
		setAnioReporte(null);
		setTituloReporte("");
	};

	const handleOpenEliminarReporte = (reporteId) => {
		const reporte = listaReportes.find((reporte) => reporte.id === reporteId);
		setReporteAEliminar(reporte);
		setOpenEliminarReporte(true);
	};

	// Función para crear un reporte con una preconfiguración / preset
	const handleUtilizarPreconfiguracion = () => {
		if (!tituloReporte || !anioReporte) {
			setTituloReporteError(!tituloReporte);
			setAnioReporteError(!anioReporte);
			return;
		}
		// El preset seleccionado está en plantillaSeleccionadaId
		// El titulo del reporte está en tituloReporte
		reporteService
			.obtenerPreset(plantillaSeleccionadaId)
			.then((response) => response.data)
			.then((data) => {
				const categorias = data.categorias;
				const reporte = {
					titulo: tituloReporte,
					anio: anioReporte,
					categorias: categorias,
					reportesComparacion: reportesComparacion,
				};
				setOpenUtilizarPreconfiguracion(false);
				setTituloReporte("");
				setAnioReporte(null);
				(async () => {
					// Mostrar Swal de carga
					Swal.fire({
						title: "Creando Reporte",
						text: "Por favor espere...",
						allowOutsideClick: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
					const reporteNuevo = await reporteService.crearReporte(
						idEmpresa,
						reporte,
					);
					Swal.close();
					navigate(
						"/empresas/" + idEmpresa + "/reportes/" + reporteNuevo.data.id,
					);
				})();
			})
			.catch((error) =>
				console.error("Error al obtener la preconfiguración:", error),
			);
	};

	// Función para crear un reporte sin preconfiguración / preset
	const handleCrearReporteSinPreconfiguracion = async () => {
		if (!tituloReporte || !anioReporte) {
			setTituloReporteError(!tituloReporte);
			setAnioReporteError(!anioReporte);
			return;
		}
		// El titulo del reporte está en tituloReporte
		const reporte = {
			titulo: tituloReporte,
			anio: anioReporte,
			categorias: [
				{
					titulo: "Nueva Categoría 1",
					secciones: [],
				},
			],
			reportesComparacion: reportesComparacion,
		};
		setOpenSinPreconfiguraciones(false);
		setTituloReporte("");
		setAnioReporte(null);
		// Mostrar Swal de carga
		Swal.fire({
			title: "Creando Reporte",
			text: "Por favor espere...",
			allowOutsideClick: false,
			showConfirmButton: false,
			willOpen: () => {
				Swal.showLoading();
			},
		});
		const reporteNuevo = await reporteService.crearReporte(idEmpresa, reporte);
		Swal.close();
		navigate("/empresas/" + idEmpresa + "/reportes/" + reporteNuevo.data.id);
	};

	const handleOpenDescargarReporteDialog = (idReporte) => {
		setIdReporte(idReporte);
		setOpenDescargarReporteDialog(true);
	};

	// Función para eliminar el reporte
	const handleEliminarReporte = async (idReporte) => {
		await reporteService.eliminarReporte(idReporte);
		await fetchData();
		setOpenEliminarReporte(false);
		window.location.reload();
	};

	const handleEliminarPreconfiguracion = async (id) => {
		await reporteService
			.eliminarReporte(id)
			.then(() => {
				// Actualizar la lista de preconfiguraciones después de eliminar
				setPreconfiguraciones(
					preconfiguraciones.filter(
						(preconfiguracion) => preconfiguracion.id !== id,
					),
				);
			})
			.catch((error) => {
				console.error("Error al eliminar la preconfiguración:", error);
			});
	};

	// Función para descargar el reporte en un formato específico.
	const handleDescargarReporte = async (formato) => {
		try {
			// Mostrar Swal de carga
			Swal.fire({
				title: "Preparando descarga",
				text: "Dependiendo del reporte esto puede tomar tiempo, por favor espere...",
				allowOutsideClick: false,
				showConfirmButton: false,
				willOpen: () => {
					Swal.showLoading();
				},
			});
			setOpenDescargarReporteDialog(false);
			const response = await reporteService.descargarReporte(
				idReporte,
				formato,
			);

			Swal.close();

			if (!response || !response.data) {
				throw new Error("No se recibió una respuesta válida del servidor.");
			}

			// Obtener el reporte actual para usar su título
			const reporteActual = listaReportes.find(
				(reporte) => reporte.id === idReporte,
			);
			const nombreReporte = reporteActual
				? `${reporteActual.titulo}_${reporteActual.anio}`
				: "reporte";

			// Determinar el tipo de contenido basado en el formato
			let contentType;
			let extension;
			if (formato === "pdf") {
				contentType = "application/pdf";
				extension = "pdf";
			} else if (formato === "word") {
				contentType =
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
				extension = "docx";
			} else {
				throw new Error("Formato no soportado.");
			}

			// Convertir la respuesta a un blob
			const blob = new Blob([response.data], { type: contentType });

			if (formato === "pdf") {
				// Crear una URL para el blob y abrirla en una nueva pestaña para PDFs
				const url = URL.createObjectURL(blob);
				window.open(url);
				setTimeout(() => {
					URL.revokeObjectURL(url);
				}, 1000);
			} else if (formato === "word") {
				// Crear una URL para el blob y simular un clic para descargar el archivo para Word
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				// Usar el nombre del reporte para el archivo
				const nombreArchivoSeguro = nombreReporte.replace(
					/[/\\?%*:|"<>]/g,
					"-",
				);
				a.download = `${nombreArchivoSeguro}.${extension}`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error("Error al descargar el reporte:", error);
		}
		setOpenDescargarReporteDialog(false);
		setIdReporte(null);
	};

	const formatearFecha = (fechaString) => {
		if (!fechaString) return '';
		return fechaString.split(' ')[0]; // Toma solo la parte de la fecha, ignorando la hora
	};

	// Función para obtener la lista de reportes, preconfiguraciones y la información de la empresa
	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			await usuarioService
				.getUsuarioLogueado()
				.then((response) => response.data)
				.then((data) => {
					setUsuarioLogeado(data);
					if (
						data.empresas &&
						!data.empresas.includes(idEmpresa) &&
						parseInt(data ? data.rol : 2) != 0
					) {
						window.location.href = "/";
					}
					return data;
				})
				.catch((error) =>
					console.error("Error al obtener el usuario logueado:", error),
				);

			await reporteService
				.obtenerListaReportes(idEmpresa)
				.then((response) => response.data)
				.then((data) => {
					if (data) {
						// Ordenar de más reciente a más antiguo usando fechaModificacion
						const dataOrdenada = data.sort(
							(a, b) =>
								new Date(b.fechaModificacion) - new Date(a.fechaModificacion),
						);
						setReportes(dataOrdenada);
					} else {
						setReportes([]);
					}
				})
				.catch((error) =>
					console.error("Error al obtener la lista de reportes:", error),
				);

			await reporteService
				.obtenerPresets()
				.then((response) => response.data)
				.then((data) => {
					if (data) {
						setPreconfiguraciones(data);
					} else {
						setPreconfiguraciones([]);
					}
				})
				.catch((error) =>
					console.error(
						"Error al obtener la lista de preconfiguraciones:",
						error,
					),
				);

			await empresaService
				.getEmpresa(idEmpresa)
				.then((response) => response.data)
				.then((data) => setInfoEmpresa(data))
				.catch((error) =>
					console.error(
						"Error al obtener la información de la empresa:",
						error,
					),
				);
		} catch (error) {
			console.error("Error al obtener la información:", error);
		}
		setLoading(false);
	}, [idEmpresa]);

	// Cargar la lista de reportes, plantillas y la información de la empresa al cargar el componente
	useEffect(() => {
		setLoading(true);
		(async () => {
			await fetchData();
			setLoading(false);
		})();
	}, [fetchData]);

	return (
		<>
			<Navbar
				useSectionMode={useSectionMode}
				secciones={secciones}
				seccionesRutas={seccionesRutas}
				seccionActual={seccionActual}
			/>
			{loading && <Loading />}
			{!loading && (
				<>
					<BreadcrumbsMUI
						breadcrumbs={[
							{
								nombre: "Empresas",
								ruta: "/empresas",
								enabled: !(
									usuarioLogeado.empresas &&
									usuarioLogeado.empresas.length === 1 &&
									parseInt(usuarioLogeado.rol) !== 0
								),
							},
						]}
						ultimoBreadcrumb={{
							nombre: infoEmpresa.nombre
								? infoEmpresa.nombre
								: infoEmpresa.razonSocial
									? infoEmpresa.razonSocial
									: "Empresa",
						}}
					/>
					<Container sx={{ pb: "100px" }}>
						<Grid container spacing={2}>
							{/*Informacion empresa*/}
							<InformacionEmpresa
								infoEmpresa={infoEmpresa}
								usuarioLogeado={usuarioLogeado}
							/>
							{/*Lista de reportes*/}
							<Grid item xs={12} md={9}>
								<Paper sx={{ mt: 2, p: 2 }}>
									<Grid container>
										<Grid item xs={6}>
											<Typography
												variant="h4"
												color="primary"
												fontWeight="bold"
												sx={{ mb: 2 }}
											>
												Lista de Reportes
											</Typography>
										</Grid>
										<Grid item xs={6} container justifyContent="flex-end">
											{usuarioLogeado &&
												usuarioLogeado.empresas &&
												(usuarioLogeado.empresas.length > 1 ||
													parseInt(usuarioLogeado.rol) === 0) && (
													<span style={{ display: "inline-block" }}>
														<Tooltip
															title="Volver a la lista de empresas"
															arrow
															slotProps={{
																popper: {
																	modifiers: [
																		{
																			name: "offset",
																			options: {
																				offset: [0, 10],
																			},
																		},
																	],
																},
															}}
														>
															<Link to="/empresas">
																<Button
																	variant="contained"
																	sx={{
																		textTransform: "none",
																		fontWeight: "bold",
																		mr: 1,
																		color: "#ffffff",
																	}}
																	startIcon={<ArrowBackIcon />}
																>
																	Volver
																</Button>
															</Link>
														</Tooltip>
													</span>
												)}
										</Grid>
									</Grid>
									{listaReportes.length === 0 && (
										<Typography
											variant="body1"
											color="secondary"
											sx={{
												fontStyle: "italic",
												fontWeight: "bold",
												mt: 2,
												ml: 2,
											}}
										>
											No hay reportes disponibles.
										</Typography>
									)}
									{listaReportes.map((reporte) => (
										<Box key={reporte.id} sx={{ pl: 2, pr: 2 }}>
											<Grid
												container
												alignItems="center"
												justifyContent="space-between"
												borderBottom={2}
												borderColor={"secondary.main"}
												sx={{ mx: 0, mb: 1, py: 1 }}
											>
												<Grid item xs={4}>
													<Typography variant="h5" color={"#000000"}>
														{reporte.titulo + " " + reporte.anio}
													</Typography>
													<Typography variant="body2">
														Fecha de creación: {formatearFecha(reporte.fechaCreacion)}
													</Typography>
													<Typography variant="body2">
														Fecha de modificación: {formatearFecha(reporte.fechaModificacion)}
													</Typography>
													<Typography variant="body2">
														Estado: {reporte.estado}
													</Typography>
												</Grid>
												<Grid
													item
													xs={8}
													container
													justifyContent="flex-end"
													spacing={1}
												>
													<Link to={`${reporte.id}`}>
														<Button
															variant="outlined"
															sx={{
																textTransform: "none",
																fontWeight: "bold",
																mr: 1,
															}}
														>
															Abrir Reporte
														</Button>
													</Link>
													{usuarioLogeado &&
														(() => {
															const roles = [0, 1, 3, 4, 5, 6];
															return roles.includes(
																parseInt(usuarioLogeado.rol),
															);
														})() && (
															<Tooltip
																arrow
																placement="bottom"
																title="Descargar reporte"
															>
																<IconButton
																	variant="outlined"
																	onClick={() =>
																		handleOpenDescargarReporteDialog(reporte.id)
																	}
																	color="secondary"
																>
																	<DownloadIcon color="cuaternary" />
																</IconButton>
															</Tooltip>
														)}
													{usuarioLogeado &&
														(parseInt(usuarioLogeado.rol) === 0 ||
															parseInt(usuarioLogeado.rol) === 6) && (
															<Tooltip
																arrow
																placement="bottom"
																title={
																	reporte.estado === "Autorizado"
																		? "Reporte autorizado"
																		: "Eliminar"
																}
															>
																<IconButton
																	variant="outlined"
																	color="error"
																	value={reporte.id}
																	onClick={() =>
																		handleOpenEliminarReporte(reporte.id)
																	}
																	disabled={reporte.estado === "Autorizado"}
																>
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														)}
												</Grid>
											</Grid>
										</Box>
									))}
								</Paper>
							</Grid>
						</Grid>
					</Container>

					{usuarioLogeado &&
						(() => {
							const roles = [0, 4, 6];
							return roles.includes(parseInt(usuarioLogeado.rol));
						})() && (
							<Box
								bgcolor="#fff"
								sx={{
									position: "fixed",
									bottom: 0,
									left: 0,
									right: 0,
									boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)",
									height: "80px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										mt: 2,
										mb: 2,
									}}
								>
									<Button
										variant="contained"
										onClick={handleOpenCrearReporte}
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											mr: 1,
										}}
									>
										Crear Reporte
									</Button>
								</Box>
							</Box>
						)}

					{/* Diálogo de confirmación de eliminación */}
					<Dialog
						open={openEliminarReporte}
						onClose={() => setOpenEliminarReporte(false)}
					>
						<DialogContent>
							<Grid container>
								<Grid item xs={12}>
									<Typography
										variant="h5"
										color="primary"
										fontWeight="bold"
										sx={{ mt: 1 }}
									>
										Eliminar Reporte
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mt: 2 }}
								>
									<Typography variant="body1">
										{reporteAEliminar
											? `¿Está seguro que desea eliminar el reporte "${reporteAEliminar.titulo} ${reporteAEliminar.anio}"?`
											: "¿Está seguro que desea eliminar el reporte?"}
									</Typography>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={
									reporteAEliminar
										? () => handleEliminarReporte(reporteAEliminar.id)
										: () => setOpenEliminarReporte(false)
								}
								color="error"
								variant="contained"
							>
								Eliminar
							</Button>
							<Button
								onClick={() => setOpenEliminarReporte(false)}
								color="primary"
								variant="outlined"
							>
								Cancelar
							</Button>
						</DialogActions>
					</Dialog>

					{/* Diálogo de creación de reporte */}
					<Dialog open={openCrearReporte} onClose={handleCloseCrearReporte}>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography
										variant="h5"
										color="primary"
										fontWeight="bold"
										sx={{ mt: 1 }}
									>
										Crear Reporte
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									justifyContent="flex-end"
									sx={{ mb: 1 }}
								>
									<IconButton onClick={handleCloseCrearReporte} disableRipple>
										<CloseIcon />
									</IconButton>
								</Grid>
								<Grid item xs={12} container justifyContent={"center"}>
									<Typography variant="body1" borderColor={"secondary"}>
										¿Desea utilizar una plantilla para el reporte?
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignItems={"center"}
									justifyContent={"center"}
								>
									<Button
										variant="contained"
										color="cuaternary"
										onClick={handleOpenUtilizarPreconfiguracion}
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											fontStyle: "italic",
											mr: 2,
										}}
									>
										Sí
									</Button>
									<Button
										variant="contained"
										color="terciary"
										onClick={handleOpenSinPreconfiguraciones}
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											fontStyle: "italic",
										}}
									>
										No
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<Grid item xs={4} container justifyContent="flex-start">
									<Typography
										variant="body1"
										color="primary"
										sx={{ ml: 2, fontStyle: "italic", fontWeight: "bold" }}
									>
										{infoEmpresa.nombre}
									</Typography>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>

					{/* Diálogo de utilización de Preconfiguración */}
					<Dialog
						open={openUtilizarPreconfiguracion}
						onClose={handleCloseUtilizarPreconfiguracion}
					>
						<DialogContent>
							<Grid container spacing={2} sx={{ mt: -3 }}>
								<Grid item xs={8}>
									<Typography
										variant="h5"
										color="primary"
										fontWeight="bold"
										sx={{ mt: 1 }}
									>
										Preconfiguración
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									container
									justifyContent="flex-end"
									sx={{ mb: 2 }}
								>
									<IconButton
										onClick={handleCloseUtilizarPreconfiguracion}
										disableRipple
									>
										<CloseIcon />
									</IconButton>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Introduzca el título del reporte:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent={"center"}
									sx={{ mb: 2 }}
								>
									<TextField
										label="Titulo del Reporte"
										variant="outlined"
										fullWidth
										value={tituloReporte}
										onChange={handleTituloReporteChange}
										error={tituloReporteError}
										helperText={
											tituloReporteError ? "Este campo es obligatorio" : ""
										}
									/>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Introduzca el año del reporte:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mb: 2 }}
								>
									<TextField
										label="Año del Reporte"
										variant="outlined"
										fullWidth
										value={anioReporte}
										onChange={(event) => setAnioReporte(event.target.value)}
										error={anioReporteError}
										helperText={
											anioReporteError ? "Este campo es obligatorio" : ""
										}
									/>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Seleccione una plantilla para el reporte:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mb: 2 }}
								>
									<FormControl fullWidth>
										<InputLabel>Plantilla</InputLabel>
										<Select
											label="Preconfiguración"
											value={plantillaSeleccionadaId}
											onChange={handlePlantillaSeleccionadaChange}
											fullWidth
										>
											{preconfiguraciones &&
												preconfiguraciones.length >= 1 &&
												preconfiguraciones.map((preconfiguracion) => (
													<MenuItem
														key={preconfiguracion.id}
														value={preconfiguracion.id}
													>
														<Grid
															container
															alignItems="center"
															justifyContent="space-between"
														>
															<Grid item>{preconfiguracion.titulo}</Grid>
															<Grid item>
																{/* Mostrar el icono solo si la plantilla no está seleccionada */}
																{plantillaSeleccionadaId !==
																	preconfiguracion.id &&
																	(parseInt(usuarioLogeado.rol) === 0 ||
																		parseInt(usuarioLogeado.rol) === 6) && (
																		<IconButton
																			onClick={() =>
																				handleOpenConfirmDelete(
																					preconfiguracion.id,
																				)
																			}
																			color="error"
																			size="small"
																		>
																			<DeleteIcon />
																		</IconButton>
																	)}
															</Grid>
														</Grid>
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Reportes de comparación:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mb: 2 }}
								>
									<FormControl fullWidth>
										{/* Aquí agregar autocomplete multiple: https://mui.com/material-ui/react-autocomplete/ */}
										<Autocomplete
											multiple
											options={listaReportes}
											getOptionLabel={(option) =>
												option.titulo + " " + option.anio
											} // Muestra el titulo junto con el año del reporte.
											value={listaReportes.filter((opt) =>
												reportesComparacion.includes(opt.id),
											)}
											onChange={handleReporteComparacionChange}
											renderTags={(tagValue, getTagProps) =>
												tagValue.map((option, index) => (
													<Chip
														key={option.id}
														label={option.titulo}
														{...getTagProps({ index })}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Seleccione los reportes de comparación"
													placeholder="Escriba aquí para buscar el reporte..."
												/>
											)}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<Grid item xs={4} container justifyContent="flex-start">
									<Typography
										variant="body1"
										color="primary"
										sx={{ ml: 2, fontStyle: "italic", fontWeight: "bold" }}
									>
										{infoEmpresa.nombre}
									</Typography>
								</Grid>
								<Grid item xs={8} container justifyContent="flex-end">
									<Button
										onClick={handleUtilizarPreconfiguracion}
										color="primary"
										variant="contained"
										sx={{ textTransform: "none" }}
										disabled={
											!tituloReporte || !anioReporte || !plantillaSeleccionadaId
										}
									>
										Crear Reporte
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
					{/* Diálogo de confirmación de eliminación */}
					<Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
						<DialogContent>
							<Typography variant="h6">
								¿Está seguro que desea eliminar esta plantilla?
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleDeleteConfirmed}
								color="error"
								variant="contained"
							>
								Confirmar
							</Button>
							<Button
								onClick={handleCloseConfirmDelete}
								color="primary"
								variant="outlined"
							>
								Cancelar
							</Button>
						</DialogActions>
					</Dialog>
					{/* Diálogo sin Preconfiguraciones */}
					<Dialog
						open={openSinPreconfiguraciones}
						onClose={handleCloseSinPreconfiguraciones}
					>
						<DialogContent>
							<Grid container spacing={2} sx={{ mt: -3 }}>
								<Grid item xs={8}>
									<Typography
										variant="h5"
										color="primary"
										fontWeight="bold"
										sx={{ mt: 1 }}
									>
										Crear Reporte
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									container
									justifyContent="flex-end"
									sx={{ mb: 2 }}
								>
									<IconButton
										onClick={handleCloseSinPreconfiguraciones}
										disableRipple
									>
										<CloseIcon />
									</IconButton>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Introduzca el título del reporte:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent={"center"}
									sx={{ mb: 2 }}
								>
									<TextField
										label="Titulo del Reporte"
										variant="outlined"
										fullWidth
										value={tituloReporte}
										onChange={handleTituloReporteChange}
										error={tituloReporteError}
										helperText={
											tituloReporteError ? "Este campo es obligatorio" : ""
										}
									/>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Introduzca el año del reporte:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mb: 2 }}
								>
									<TextField
										label="Año del Reporte"
										variant="outlined"
										fullWidth
										value={anioReporte}
										onChange={(event) => setAnioReporte(event.target.value)}
										error={anioReporteError}
										helperText={
											anioReporteError ? "Este campo es obligatorio" : ""
										}
									/>
								</Grid>
								<Grid item xs={12} container>
									<Typography variant="body1">
										Reportes de comparación:
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent="center"
									sx={{ mb: 2 }}
								>
									<FormControl fullWidth>
										{/* Aquí agregar autocomplete multiple: https://mui.com/material-ui/react-autocomplete/ */}
										<Autocomplete
											multiple
											options={listaReportes}
											getOptionLabel={(option) =>
												option.titulo + " " + option.anio
											} // Muestra el titulo junto con el año del reporte.
											value={listaReportes.filter((opt) =>
												reportesComparacion.includes(opt.id),
											)}
											onChange={handleReporteComparacionChange}
											renderTags={(tagValue, getTagProps) =>
												tagValue.map((option, index) => (
													<Chip
														key={option.id}
														label={option.titulo}
														{...getTagProps({ index })}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Seleccione los reportes de comparación"
													placeholder="Escriba aquí para buscar el reporte..."
												/>
											)}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<Grid item xs={4} container justifyContent="flex-start">
									<Typography
										variant="body1"
										color="primary"
										sx={{ ml: 2, fontStyle: "italic", fontWeight: "bold" }}
									>
										{infoEmpresa.nombre}
									</Typography>
								</Grid>
								<Grid item xs={8} container justifyContent="flex-end">
									<Button
										color="primary"
										variant="contained"
										onClick={handleCrearReporteSinPreconfiguracion}
										sx={{ textTransform: "none" }}
										disabled={!tituloReporte || !anioReporte}
									>
										Crear Reporte
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>

					{/* Diálogo de descargar reporte */}
					<Dialog
						open={openDescargarReporteDialog}
						onClose={() => setOpenDescargarReporteDialog(false)}
						maxWidth="md"
						fullWidth
					>
						{/* Contenido del diálogo */}
						<DialogContent>
							<Grid container direction="column" spacing={2}>
								<Grid item container>
									<Grid item xs={8}>
										<Typography
											variant="h5"
											color="primary"
											fontWeight="bold"
											sx={{ mt: 1 }}
										>
											Descargar Reporte
										</Typography>
									</Grid>
									<Grid item xs={4} container justifyContent="flex-end">
										<IconButton
											onClick={() => setOpenDescargarReporteDialog(false)}
										>
											<CloseIcon />
										</IconButton>
									</Grid>
								</Grid>

								<Grid item container justifyContent="center">
									<Typography variant="h6" sx={{ mb: 1 }}>
										Seleccione el formato de descarga
									</Typography>
								</Grid>

								<Grid item container justifyContent="center">
									<Button
										variant="contained"
										color="error"
										onClick={() => handleDescargarReporte("pdf")}
										startIcon={<CloudDownloadIcon />}
										sx={{
											mr: 4,
											textTransform: "none",
											fontWeight: "bold",
											fontStyle: "italic",
											fontSize: "1rem",
										}}
									>
										Descargar PDF
									</Button>
									<Button
										variant="contained"
										color="primary"
										onClick={() => handleDescargarReporte("word")}
										startIcon={<CloudDownloadIcon />}
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											fontStyle: "italic",
											fontSize: "1rem",
										}}
									>
										Descargar Word
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<Grid item xs={12} container justifyContent="flex-end">
									<Button
										color="secondary"
										variant="text"
										onClick={() => setOpenDescargarReporteDialog(false)}
									>
										Cancelar
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
}

export default ListaReportes;

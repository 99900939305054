import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Paper,
    Button,
    Grid,
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Navbar from "./Navbar";
import usuarioService from "../services/UsuarioService";
import empresaService from "../services/EmpresaService";
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';
import {
    Block as BlockIcon,
    Visibility as VisibilityIcon,
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
} from "@mui/icons-material";

function Usuarios() {
    // Datos de la barra de navegación
    const useSectionMode = true;
    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];
    const seccionActual = "Usuarios";
    const [usuarioLogueado, setUsuarioLogueado] = useState(null);

    // Listas de usuarios y roles
    const [listaUsuarios, setListaUsuarios] = useState([]);

    // Estado para el estado de carga
    const [loading, setLoading] = useState(true);

    // Obtener la lista de usuarios al cargar el componente
    useEffect(() => {
        setLoading(true);
        (async () => {
            await fetchData();
            await usuarioService.getUsuarioLogueado().then((response) => {
                setUsuarioLogueado(response.data);
            });
            setLoading(false);
        })();
    }, []);

    // Función para obtener la lista de usuarios
    const fetchData = async () => {
        try {
            // Obtener la lista de usuarios
            const usuariosResponse = await usuarioService.getListaUsuarios();

            // Obtener la lista de empresas
            const empresasResponse = await empresaService.getListaEmpresas();
            const empresas = empresasResponse.data || [];

            // Mapear los IDs de las empresas a sus nombres
            const empresasMap = {};
            empresas.forEach(empresa => {
                empresasMap[empresa.id] = empresa.nombre; // Crear un mapa de id a nombre
            });

            // Convertir los IDs de las empresas de cada usuario a nombres
            const usuariosConNombres = usuariosResponse.data.map(usuario => ({
                ...usuario,
                empresas: usuario.empresas.map(id => empresasMap[id] || id) // Reemplazar ID por nombre
            }));

            setListaUsuarios(usuariosConNombres); // Actualizar la lista de usuarios con nombres de empresas

        } catch (error) {
            console.error('Error al obtener la lista de usuarios:', error);
        }
    };

    // Estado para el valor del campo de búsqueda
    const [searchValue, setSearchValue] = useState("");

    // Estado para controlar la apertura y cierre del diálogo
    const [openDialog, setOpenDialog] = useState(false);

    // Estado para almacenar el nombre del usuario a eliminar
    const [usuarioAEliminar, setUsuarioAEliminar] = useState("");

    // Estado para almacenar el nombre ingresado por el usuario
    const [nombreIngresado, setNombreIngresado] = useState("");

    // Función para manejar cambios en el campo de búsqueda
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    // Función para limpiar el campo de búsqueda
    const handleClearSearch = () => {
        setSearchValue("");
    };

    // Función para abrir el diálogo de eliminación
    const handleOpenDialog = (usuario) => {
        setUsuarioAEliminar(usuario);
        setNombreIngresado(""); // Limpiar el nombre ingresado cada vez que se abre el diálogo
        setOpenDialog(true);
    };

    // Función para cerrar el diálogo de eliminación
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Función para manejar cambios en el nombre ingresado por el usuario
    const handleNombreIngresadoChange = (event) => {
        setNombreIngresado(event.target.value);
    };

    // Función para manejar la eliminación del usuario
    const handleEliminarUsuario = () => {
        if (nombreIngresado.toLowerCase() === usuarioAEliminar.nombre.toLowerCase()) {
            const id = usuarioAEliminar.id;
            usuarioService.deleteUsuario(usuarioAEliminar.id);
            setOpenDialog(false);
            setListaUsuarios(listaUsuarios.filter(usuario => usuario.id !== id));
        } else {
            alert("El nombre ingresado no coincide con el usuario a eliminar.");
        }
    };

    // Filtrar la lista de usuarios según el término de búsqueda
    const listaUsuariosFiltrada = listaUsuarios.filter(usuario =>
        usuario.nombre.toLowerCase().includes(searchValue.toLowerCase())
    );

    const theme = useTheme();

    const handleToggleBlock = async (usuario) => {
        try {
            await usuarioService.toggleBlockUser(usuario.id);
            // Actualizar la lista de usuarios
            await fetchData();

            Swal.fire({
                icon: "success",
                title: "Operación exitosa",
                text: `Usuario ${usuario.bloqueado ? "desbloqueado" : "bloqueado"} correctamente`,
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudo cambiar el estado del usuario",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.error.main,
            });
        }
    };

    return (
        <>
            <Navbar seccionActual={seccionActual} useSectionMode={useSectionMode} secciones={secciones} seccionesRutas={seccionesRutas} />
            {loading && (
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            bgcolor: 'background.default',
                        }}
                    >
                        <CircularProgress size={80} />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Cargando...
                        </Typography>
                    </Box>
                </>
            )}
            {!loading && (
                <>
                    <Container sx={{ pb: '100px' }}>
                        <Paper sx={{ mt: 2, p: 2 }} maxWidth="lg">
                            <Typography variant="h4" color={"primary.main"} fontWeight={"bold"} sx={{ mb: 2 }}>Usuarios</Typography>
                            {/* Campo de búsqueda */}
                            <TextField
                                label="Buscar Usuario"
                                variant="outlined"
                                value={searchValue}
                                onChange={handleSearchChange}
                                fullWidth
                                InputProps={{       /* Botón para limpiar la búsqueda */
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchValue && (
                                                <IconButton onClick={handleClearSearch} edge="end">
                                                    <ClearIcon />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                                sx={{ mb: 2 }}
                            />

                            {/* Lista de usuarios filtrada (Usando el filtro de búsqueda) */}
                            {listaUsuariosFiltrada.map((usuario, index) => {
                                return (
                                    <Box sx={{ pl: 2, pr: 2 }} key={index}>
                                        <Grid container alignItems="center" justifyContent="space-between" borderBottom={2} spacing={2} borderColor={"secondary.main"} sx={{ mx: 0, mb: 1, py: 1 }}>
                                            {/* Nombre del usuario */}
                                            <Grid item xs={4}>
                                                <Typography variant="h5" color={"#000000"} sx={{ ml: -2 }}>
                                                    {usuario.nombre}
                                                </Typography>
                                            </Grid>
                                            {/* Rol asignado al usuario */}
                                            <Grid item xs={3}>
                                                <Typography variant="h6" color={"#000000"}>
                                                    {usuarioService.listaRoles[usuario.rol]}
                                                </Typography>
                                            </Grid>
                                            {/* Empresas a las que pertenece el usuario */}
                                            <Grid item xs={3}>
                                                {usuario.rol === "0" ? (
                                                    <Typography variant="h6" color="#000000">
                                                        Acceso a todo
                                                    </Typography>
                                                ) : usuario.empresas && usuario.empresas.length !== 0 ?
                                                    (usuario.empresas.length > 1 ? (
                                                            <Tooltip
                                                                arrow
                                                                placement="bottom"
                                                                disableInteractive
                                                                title={
                                                                    <div style={{ maxHeight: '150px', maxWidth: '300px', overflowY: 'auto', whiteSpace: 'nowrap' }}>
                                                                        <List disablePadding>
                                                                            {usuario.empresas.map((empresa, index) => (
                                                                                <ListItem key={index} disablePadding>
                                                                                    <ListItemText primary={empresa} />
                                                                                </ListItem>
                                                                            ))}
                                                                        </List>
                                                                    </div>
                                                                }
                                                            >
                                                                <span style={{ display: 'inline-block' }}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        color="#000000"
                                                                        sx={{
                                                                            textDecoration: "underline",
                                                                            cursor: "default"
                                                                        }}
                                                                    >
                                                                        Ver Empresas
                                                                    </Typography>
                                                                </span>
                                                            </Tooltip>
                                                        ) :
                                                        <Typography
                                                            variant="h6"
                                                            color="#000000"
                                                        >
                                                            {usuario.empresas[0]}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="h6" color="#000000">
                                                            Sin Empresa
                                                        </Typography>
                                                    )}
                                            </Grid>
                                            <Grid item xs={2} container justifyContent="flex-end" spacing={1}>
                                                {usuarioLogueado && usuarioLogueado.rol === "0" && usuarioLogueado.id !== usuario.id && (
                                                    <>
                                                        <Tooltip title={usuario.bloqueado ? "Usuario Bloqueado" : "Usuario Desbloqueado"} arrow>
                                                            <IconButton
                                                                color={usuario.bloqueado ? "warning" : "success"}
                                                                onClick={() => handleToggleBlock(usuario)}
                                                            >
                                                                {usuario.bloqueado ? <LockIcon /> : <LockOpenIcon />}
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Ver detalles" arrow>
                                                            <IconButton
                                                                color="primary"
                                                                component={Link}
                                                                to={`/usuarios/${usuario.id}`}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Eliminar" arrow>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleOpenDialog(usuario)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                            })}
                        </Paper>
                    </Container>
                    <Box bgcolor="#fff" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)", height: '80px' }}>
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                            <Link to="/usuarios/crear" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" sx={{ textTransform: "none", fontWeight: "bold", mr: 1 }}>
                                    Agregar Usuario
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    {/* Diálogo de eliminación */}
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Eliminar Usuario</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Usted está a punto de eliminar el usuario "<Typography component="span" variant="body1" color="primary.main" sx={{ fontStyle: "italic" }}>{usuarioAEliminar.nombre}</Typography>", para confirmar la <strong><Typography component="span" variant="body1" color="error.main">eliminación</Typography></strong> ingrese nuevamente el nombre de usuario en el cuadro de texto:
                            </Typography>

                            <TextField
                                autoFocus
                                margin="dense"
                                label="Nombre de Usuario a Eliminar"
                                type="text"
                                fullWidth
                                value={nombreIngresado}
                                onChange={handleNombreIngresadoChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary" variant="outlined">
                                Cancelar
                            </Button>
                            <Button onClick={handleEliminarUsuario} color="error" variant="contained">
                                <DeleteIcon sx={{ ml: -0.5, mr: 0.5 }} /> Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
}
export default Usuarios;
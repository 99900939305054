import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Paper,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import CategoryIcon from '@mui/icons-material/Category';
import SectionIcon from '@mui/icons-material/ViewList';
import FieldIcon from '@mui/icons-material/Label';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ContentIcon from '@mui/icons-material/Description';
import reporteService from "../services/ReporteService";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import * as reporteFunctions from "../utils/reporteFunctions";
import VerTabla from "./VerTabla";
import { useNavigate, Link } from "react-router-dom";

function CompararReportes({
  openCompararCampos,
  setOpenCompararCampos,
  reporte,
  secciones,
  categoriaActualIndex,
  seccionActualIndex,
  campoActualIndex,
  idEmpresa,
}) {
  // Constantes
  const UMBRAL_BUSQUEDA = 0.8; // Que tan similar tiene que ser a la entrada, 1 = 100%, 0 = 0%
  const RESULTADOS_POR_BUSQUEDA = 1; // Numero de resultados a mostrar por reporte.

  // Estados reporte
  const [reportesComparacion, setReportesComparacion] = useState([]);
  const [reporteActual, setReporteActual] = useState(reporte || null);
  const [campoActual, setCampoActual] = useState(null);
  const [resultadosBusqueda, setResultadosBusqueda] = useState([]);
  const [tipoResultado, setTipoResultado] = useState([]);
  const [openCompararCamposReal, setOpenCompararCamposReal] = useState(null); // Se usa este para establecer un delay al querer abrir el dialog, es un parche.

  // Estados tabla (Extraidos de Reporte.jsx)
  const [openVerTablaDialog, setOpenVerTablaDialog] = useState(false); // Estado para abrir el pop up de visualización de la tabla.
  const [csvData, setCsvData] = useState(""); // Estado para los datos de la tabla.
  const [openTableDialog, setOpenTableDialog] = useState(false); // Estado para abrir el pop up de edición de la tabla.
  const [currentTableTitle, setCurrentTableTitle] = useState("");

  // Navigate
  const navigate = useNavigate();

  // Función mejorada para agrupar y ordenar los resultados
  const agruparYOrdenarResultados = (resultados) => {
    // Agrupar por reporteId
    const reportesAgrupados = resultados.reduce((acc, res) => {
      const reporteId = res.reporteId;
      if (!acc[reporteId]) {
        acc[reporteId] = [];
      }
      acc[reporteId].push({ ...res }); // Clonamos el objeto para evitar referencias compartidas
      return acc;
    }, {});

    // Convertir a matriz asegurando que los resultados no se repiten y tomando solo los 3 mejores
    const matrizResultados = Object.values(reportesAgrupados).map(
      (reportes) => {
        // Hacer una copia del array para evitar modificar el original
        const reportesCopia = [...reportes];

        // Ordenar cada reporte por score de menor a mayor (mejor precisión primero)
        reportesCopia.sort((a, b) => a.score - b.score);

        // Eliminar duplicados basados en el campoTitulo para evitar resultados repetidos
        const reportesUnicos = [];
        const titulosVistos = new Set();

        for (let reporte of reportesCopia) {
          if (!titulosVistos.has(reporte.campoTitulo)) {
            reportesUnicos.push(reporte);
            titulosVistos.add(reporte.campoTitulo);
          }
          if (reportesUnicos.length === RESULTADOS_POR_BUSQUEDA) break; // Solo tomamos los 3 mejores
        }

        return reportesUnicos;
      },
    );

    return matrizResultados;
  };

  useEffect(() => {
    // Función para obtener los reportes de comparación asociados al reporte principal.
    const obtenerReportesComparacion = async () => {
      let listaIdsReportes = reporte.reportesComparacion; // Se obtiene la lista de IDs de los reportes de comparación.
      if (listaIdsReportes && listaIdsReportes.length > 0) {
        Swal.fire({
          title: "Obteniendo reportes de comparación",
          text: "Por favor espere...",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          // Se genera un array de promesas.
          const reportes = await Promise.all(
            listaIdsReportes.map(async (idReporte) => {
              const response = await reporteService.obtenerReporte(idReporte);
              return response.data;
            }),
          );
          // Se actualiza el estado con la nueva lista de reportes.
          setReportesComparacion((prevReportes) => {
            const nuevoEstado = [...prevReportes, ...reportes];
            return nuevoEstado;
          });
          setOpenCompararCamposReal(true);
        } catch (error) {
          console.error("Error obteniendo reportes de comparación:", error);
        }
        Swal.close();
      }
    };

    if (openCompararCampos) {
      setReporteActual(reporte);
      let campo = secciones[seccionActualIndex]?.campos[campoActualIndex];
      if (campo) {
        setCampoActual(campo);
        obtenerReportesComparacion();
      }
    }
  }, [
    reporte,
    secciones,
    campoActualIndex,
    seccionActualIndex,
    categoriaActualIndex,
    openCompararCampos,
  ]);

  useEffect(() => {
    // Función que realiza la busqueda de los campos.
    const realizarBusqueda = () => {
      setTipoResultado([]); // Limpiar resultados previos

      let campoPrincipal = campoActual.titulo;
      let seccionPrincipal = secciones[seccionActualIndex].titulo;
      let categoriaPrincipal = reporte.categorias[categoriaActualIndex].titulo;

      // Aplanar los reportes de comparación

      let flatReportesComparacion =
        reportesComparacion?.flatMap(
          (reporte) =>
            reporte.categorias?.flatMap(
              (categoria) =>
                categoria.secciones?.flatMap(
                  (seccion) =>
                    seccion.campos?.flatMap((campo, campoIndex) => {
                      const baseData = {
                        reporteId: reporte.id,
                        reporteTitulo: reporte.titulo,
                        reporteAnio: reporte.anio,
                        categoriaTitulo: categoria.titulo,
                        seccionTitulo: seccion.titulo,
                        categoriaIndex: reporte.categorias.indexOf(categoria),
                        seccionIndex: categoria.secciones.indexOf(seccion),
                      };

                      const campoInfo = {
                        ...baseData,
                        campoTitulo: campo.titulo,
                        campoTipo: campo.tipo,
                        campoTituloTabla: campo.titulotabla || null,
                        campoContenido: campo.contenido,
                        campoPorcentaje: campo.porcentaje || null,
                        campoIndex: campoIndex,
                        esSubcampo: false,
                      };

                      const subcampos =
                        campo.subCampos?.map((subcampo, subcampoIndex) => ({
                          ...baseData,
                          campoTitulo: subcampo.titulo,
                          campoTipo: subcampo.tipo,
                          campoTituloTabla: subcampo.titulotabla || null,
                          campoContenido: subcampo.contenido,
                          campoPorcentaje: subcampo.porcentaje || null,
                          campoIndex: [campoIndex, subcampoIndex],
                        })) || [];

                      return [
                        {
                          ...campoInfo,
                          subcampos: subcampos,
                        },
                      ];
                    }) || [],
                ) || [],
            ) || [],
        ) || [];

      // Búsqueda por Categoría
      let fuseCategoria = new Fuse(flatReportesComparacion, {
        keys: ["categoriaTitulo"],
        threshold: 1 - UMBRAL_BUSQUEDA,
        findAllMatches: true,
        includeScore: true,
      });

      let categoriaResultados = fuseCategoria.search(categoriaPrincipal);

      if (categoriaResultados.length === 0) {
        return;
      }

      // Filtrar por categoría
      let reportesFiltrados = categoriaResultados.map((res) => ({
        ...res.item,
        score: res.score,
      }));

      // Búsqueda por Sección
      let fuseSeccion = new Fuse(reportesFiltrados, {
        keys: ["seccionTitulo"],
        threshold: 1 - UMBRAL_BUSQUEDA,
        findAllMatches: true,
        includeScore: true,
      });

      let seccionResultados = fuseSeccion.search(seccionPrincipal);

      if (seccionResultados.length === 0) {
        return;
      }
      // Preparar por reporte individual
      let reportesFiltradosSeccion = seccionResultados.map((res) => ({
        ...res.item,
        score: res.score + res.item.score, // score acumulado de categoría + sección
      }));

      // Agrupar por reporteId
      const reportesPorId = reportesFiltradosSeccion.reduce((acc, item) => {
        if (!acc[item.reporteId]) acc[item.reporteId] = [];
        acc[item.reporteId].push(item);
        return acc;
      }, {});

      let resultadosCampo = [];
      let resultadosTipo = [];

      // Buscar campo por cada reporte individualmente
      for (const reporteId in reportesPorId) {
        const items = reportesPorId[reporteId];

        const fuseCampo = new Fuse(items, {
          keys: ["campoTitulo"],
          threshold: 1 - UMBRAL_BUSQUEDA,
          findAllMatches: true,
          includeScore: true,
        });

        const campoResultados = fuseCampo.search(campoPrincipal);

        if (campoResultados.length > 0) {
          const camposConScore = campoResultados.map((res) => ({
            ...res.item,
            score: res.score + res.item.score,
          }));
          resultadosCampo.push(...camposConScore);
        } else {
          // Campo no encontrado, pero sí la sección
          const item = items[0];
          resultadosTipo.push({
            reporteId: item.reporteId,
            reporteTitulo: item.reporteTitulo,
            reporteAnio: item.reporteAnio,
            categoriaTitulo: item.categoriaTitulo,
            seccionTitulo: item.seccionTitulo,
            categoriaIndex: item.categoriaIndex,
            seccionIndex: item.seccionIndex,
          });
        }
      }

      console.log("Resultados:", resultadosCampo);

      // Setear resultados según lo encontrado
      setTipoResultado(resultadosTipo);

      if (resultadosCampo.length > 0) {
        const resultadosAgrupados = agruparYOrdenarResultados(resultadosCampo);
        setResultadosBusqueda(resultadosAgrupados);
      } else {
        setResultadosBusqueda([]); // Asegurarse que quede vacío si no hay campos
      }
    };

    if (reportesComparacion.length > 0) {
      realizarBusqueda();
    }
  }, [
    reportesComparacion,
    campoActual,
    categoriaActualIndex,
    reporte,
    seccionActualIndex,
    secciones,
  ]);

  // Función para ir al detalle de la sección. (Función editada de Notificaciones.jsx)
  const handleVerDetalles = (detalles) => {
    const { idSeccion, idCategoria, idReporte } = detalles;
    // Construimos los parámetros de consulta según los valores disponibles
    const params = new URLSearchParams();
    let queryString = "";
    let path = "";
    // Caso en que se trate de un cambio / creación del el reporte.
    if (idEmpresa && idReporte) {
      if (idSeccion !== null || idCategoria !== null) {
        if (idSeccion !== null) params.append("idSeccion", idSeccion);
        if (idCategoria !== null) params.append("idCategoria", idCategoria);

        queryString = params.toString();
        setOpenCompararCampos(false);

        setResultadosBusqueda([]);
        setReportesComparacion([]);
        setReporteActual(null);
        setCampoActual(null);
        // Navegamos a la ruta con los parámetros de consulta
        path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
        navigate(`${path}?${queryString}`);
      } else {
        // Navegamos a la ruta sin los parámetros de consulta
        path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
        navigate(path);
      }
    } else if (idEmpresa) {
      path = `/empresas/${idEmpresa}/reportes`;
      navigate(path);
    }
  };

  // ------------------------ TABLAS (Casi todo extraído de Reporte.jsx) ------------------------

  // Función para abrir el diálogo de visualización de la tabla (Ligeramente editado)
  const handleOpenVerTabla = (data, campo) => {
    // Asegurarse de que los datos estén en formato CSV
    let csvData;
    if (typeof data === "string") {
      csvData = data;
    } else if (data instanceof File) {
      // Si es un archivo, necesitamos leerlo primero
      const reader = new FileReader();
      reader.onload = (e) => {
        csvData = e.target.result;
        setCsvData(csvData);
        setCurrentTableTitle(campo?.campoTitulo || ""); // Editado
        setOpenVerTablaDialog(true);
      };
      reader.readAsText(data);
      return;
    } else {
      console.error("Formato de datos no soportado:", data);
      return;
    }

    setCsvData(csvData);
    setCurrentTableTitle(campo?.campoTitulo || ""); // Editado
    setOpenVerTablaDialog(true);
  };

  // Función para cerrar el diálogo de visualización de la tabla.
  const handleCloseVerTabla = () => {
    setOpenVerTablaDialog(false);
  };

  // Función para descargar la tabla en formato CSV
  const handleDescargarTablaCSV = () => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    // Usar el título actual o un nombre por defecto
    const fileName = (currentTableTitle || "tabla") + ".csv";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Dialog
        open={openCompararCamposReal}
        onClose={() => {
          setOpenCompararCamposReal(false);
          setOpenCompararCampos(false);
          setTimeout(() => {
            setResultadosBusqueda([]);
            setReportesComparacion([]);
            setReporteActual(null);
            setCampoActual(null);
          }, 200);
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                fontSize={"25px"}
                fontWeight="bold"
                color="primary"
              >
                Comparación del campo: {campoActual ? campoActual.titulo : ""}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* Se itera en los resultados de la busqueda */}
              {resultadosBusqueda && resultadosBusqueda.length > 0 && (
                <>
                  {resultadosBusqueda.map((reporte, index) => (
                    <Grid
                      key={index}
                      spacing={2}
                      sx={{
                        mb: 2,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          fontSize={"20px"}
                          color="primary"
                          fontWeight="bold"
                          sx={{ p: 1 }}
                        >
                          {reporte.length > 0
                            ? reporte[0].reporteTitulo +
                            " - " +
                            reporte[0].reporteAnio
                            : "Sin resultados para este reporte"}
                        </Typography>
                      </Grid>

                      {reporte.length > 0 ? (
                        reporte.map((campo, campoIndex) => (
                          <Paper
                            key={campoIndex}
                            elevation={1}
                            sx={{
                              p: 2,
                              mb: 2,
                              backgroundColor: "rgba(0, 0, 0, 0.02)",
                              borderRadius: 2,
                              border: "1px solid #e0e0e0",
                              transition: "all 0.2s ease-in-out",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "translateY(-2px)",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                              }
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                              }}
                            >
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                  <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                                  <Typography
                                    fontSize="18px"
                                    sx={{
                                      color: "secondary.main",
                                      fontWeight: "medium",
                                    }}
                                  >
                                    Categoría: {campo.categoriaTitulo}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                  <SectionIcon sx={{ mr: 1, color: 'primary.main' }} />
                                  <Typography
                                    fontSize="18px"
                                    sx={{
                                      color: "secondary.main",
                                      fontWeight: "medium",
                                    }}
                                  >
                                    Sección: {campo.seccionTitulo}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <FieldIcon sx={{ mr: 1, color: 'primary.main' }} />
                                  <Typography
                                    fontSize="18px"
                                    sx={{
                                      color: "secondary.main",
                                      fontWeight: "medium",
                                    }}
                                  >
                                    Campo: {campo.campoTitulo}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box>
                                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <ContentIcon sx={{ mr: 1, color: 'primary.main' }} />
                                    <Typography
                                      fontSize="18px"
                                      sx={{
                                        color: "secondary.main",
                                        fontWeight: "medium",
                                      }}
                                    >
                                      Contenido:
                                    </Typography>
                                  </Box>
                                  <Box sx={{ p: 2, backgroundColor: 'rgba(0, 0, 0, 0.02)', borderRadius: 1 }}>
                                    <Typography
                                      fontSize={"18px"}
                                      sx={{
                                        color: "secondary.main",
                                        fontWeight: "medium",
                                      }}
                                    >
                                      {(() => {
                                        if (!campo.campoTipo) return null;

                                        // 1. Tipo "texto"
                                        if (
                                          campo.campoTipo.toLowerCase() === "texto"
                                        ) {
                                          return campo.campoContenido;
                                        }
                                        // 2. Tipo "fecha"
                                        else if (campo.campoTipo === "fecha") {
                                          return campo.campoContenido
                                            ? reporteFunctions.formatearFecha(
                                              campo.campoContenido,
                                              false,
                                            )
                                            : "";
                                        }
                                        // 3. Tipo "numerico"
                                        else if (campo.campoTipo === "numerico") {
                                          return reporteFunctions.formatearNumero(
                                            campo.campoContenido,
                                            campo.campoPorcentaje,
                                          );
                                        }
                                        // 4. Tipo "booleano"
                                        else if (
                                          campo.campoTipo.toLowerCase() ===
                                          "booleano"
                                        ) {
                                          if (campo.campoContenido === true) return "Sí";
                                          if (campo.campoContenido === false) return "No";
                                          return "No especificado";
                                        }
                                        // 5. Tipo "tabla"
                                        else if (
                                          campo.campoTipo.toLowerCase() === "tabla"
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Button
                                                variant="text"
                                                onClick={() =>
                                                  handleOpenVerTabla(
                                                    campo.campoContenido,
                                                    campo,
                                                  )
                                                }
                                                sx={{
                                                  textTransform: "none",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                  transition: "transform 0.2s",
                                                  "&:hover": {
                                                    transform:
                                                      "scale(1.05) translateY(-2px)",
                                                  },
                                                }}
                                                startIcon={<BackupTableIcon />}
                                              >
                                                Ver Tabla
                                              </Button>
                                            </div>
                                          );
                                        }

                                        // Caso por defecto
                                        return null;
                                      })()}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            {/* Subcampos (si es que hay) */}
                            {campo.subcampos && campo.subcampos.length > 0 && (
                              <Box
                                sx={{
                                  mt: 2,
                                  pl: 4,
                                  borderLeft: "2px solid #e0e0e0",
                                  position: 'relative',
                                  '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-2px',
                                    top: '0',
                                    width: '2px',
                                    height: '100%',
                                    background: 'linear-gradient(to bottom, #e0e0e0 50%, transparent 50%)',
                                    backgroundSize: '4px 4px',
                                  }
                                }}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                  <SubdirectoryArrowRightIcon sx={{ mr: 1, color: 'primary.main' }} />
                                  <Typography
                                    fontSize={"18px"}
                                    fontWeight={"bold"}
                                    color={"secondary"}
                                  >
                                    Subcampos:
                                  </Typography>
                                </Box>
                                {campo.subcampos.map((subcampo, i) => (
                                  <Paper
                                    key={i}
                                    elevation={0}
                                    sx={{
                                      p: 2,
                                      mb: 1,
                                      backgroundColor: "rgba(0, 0, 0, 0.02)",
                                      borderRadius: 1,
                                      transition: "all 0.2s ease-in-out",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                        transform: "translateX(4px)",
                                      }
                                    }}
                                  >
                                    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Grid item xs={6}>
                                        <Typography
                                          fontSize="16px"
                                          sx={{
                                            color: "secondary.main",
                                            fontWeight: "medium",
                                          }}
                                        >
                                          {subcampo.campoTitulo}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography
                                          fontSize="16px"
                                          sx={{
                                            color: "secondary.main",
                                            fontWeight: "medium",
                                          }}
                                        >
                                          {(() => {
                                            const tipo = subcampo.campoTipo;
                                            const contenido = subcampo.campoContenido;
                                            const porcentaje = subcampo.campoPorcentaje;

                                            if (!tipo) return null;

                                            switch (tipo.toLowerCase()) {
                                              case "texto":
                                                return contenido;
                                              case "fecha":
                                                return contenido
                                                  ? reporteFunctions.formatearFecha(
                                                    contenido,
                                                    false,
                                                  )
                                                  : "";
                                              case "numerico":
                                                return reporteFunctions.formatearNumero(
                                                  contenido,
                                                  porcentaje,
                                                );
                                              case "booleano":
                                                if (contenido === true) return "Sí";
                                                if (contenido === false) return "No";
                                                return "No especificado";
                                              case "tabla":
                                                return (
                                                  <div
                                                    style={{
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <Button
                                                      variant="text"
                                                      onClick={() =>
                                                        handleOpenVerTabla(
                                                          contenido,
                                                          subcampo,
                                                        )
                                                      }
                                                      sx={{
                                                        textTransform: "none",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                        transition:
                                                          "transform 0.2s",
                                                        "&:hover": {
                                                          transform:
                                                            "scale(1.05) translateY(-2px)",
                                                        },
                                                      }}
                                                      startIcon={
                                                        <BackupTableIcon />
                                                      }
                                                    >
                                                      Ver Tabla
                                                    </Button>
                                                  </div>
                                                );
                                              default:
                                                return null;
                                            }
                                          })()}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                ))}
                              </Box>
                            )}
                          </Paper>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <Box sx={{ p: 2 }}>
                            <Typography
                              fontSize={"20px"}
                              sx={{
                                color: "secondary.main",
                                fontWeight: "medium",
                              }}
                            >
                              No hay datos para este reporte.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </>
              )}

              {tipoResultado.length > 0 && (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="warning.main"
                    sx={{ mt: 4, mb: 2 }}
                  >
                    Se encontró la sección en los siguientes reportes, pero no
                    el campo:
                  </Typography>
                  {tipoResultado.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ p: 2, borderBottom: "1px solid #ddd" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            fontSize="18px"
                            sx={{ fontWeight: "medium" }}
                          >
                            {item.reporteTitulo} - {item.reporteAnio}
                          </Typography>
                          <Typography
                            fontSize="16px"
                            sx={{ color: "text.secondary" }}
                          >
                            {item.categoriaTitulo + " > " + item.seccionTitulo}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title={"Abrir sección en nueva pestaña"}
                            placement={"bottom"}
                            arrow
                          >
                            <span>
                              <IconButton
                                onClick={() =>
                                  handleVerDetalles({
                                    idSeccion: item.seccionIndex,
                                    idCategoria: item.categoriaIndex,
                                    idReporte: item.reporteId,
                                  })
                                }
                              >
                                <OpenInNewIcon color={"primary"} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Grid>
              )}

              {resultadosBusqueda.length === 0 &&
                tipoResultado.length === 0 && (
                  <Typography variant="h6" color="#373737">
                    No hay resultados disponibles.
                  </Typography>
                )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              setOpenCompararCampos(false);
              setOpenCompararCamposReal(false);
              setTimeout(() => {
                setResultadosBusqueda([]);
                setReportesComparacion([]);
                setReporteActual(null);
                setCampoActual(null);
              }, 200);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Diálogo para ver tabla */}
      <Dialog
        open={openVerTablaDialog}
        onClose={handleCloseVerTabla}
        maxWidth="xl"
        fullWidth
      >
        {/* Eliminar el DialogTitle */}
        <DialogContent>
          <VerTabla csvString={csvData} tableTitle={currentTableTitle} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDescargarTablaCSV} color="success">
            Descargar CSV
          </Button>
          <Button onClick={handleCloseVerTabla} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CompararReportes;

import {
	Grid,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Typography,
	TextField,
	Tooltip,
	Select,
	MenuItem,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import reporteService from "../services/ReporteService";
import usuarioService from "../services/UsuarioService";
import Swal from "sweetalert2";

function NavbarEvidencia({
	evidencias,
	refreshEvidencias,
	usuarioLogeado,
	rolesGestionarEvidencias,
	rolesDescargarReporte,
	rolesGuardarPlantilla,
	rolesAutorizarReporte,
	fetchData,
	reporteAutorizado,
	mostrarSemaforos,
	setMostrarSemaforos,
}) {
	const { idEmpresa, idReporte } = useParams();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [openDialogAdjuntarEvidencia, setOpenDialogAdjuntarEvidencia] =
		React.useState(false);
	const [evidenciaActual, setEvidenciaActual] = React.useState(null);
	const [tipoEvidencia, setTipoEvidencia] = React.useState("archivo");
	const [paginaEvidencia, setPaginaEvidencia] = React.useState("");
	const [nombreEvidencia, setNombreEvidencia] = React.useState("");
	const [openDescargarReporteDialog, setOpenDescargarReporteDialog] =
		React.useState(false);
	const [openGuardarPresetDialog, setOpenGuardarPresetDialog] =
		React.useState(false);
	const [openDialogAutorizarReporte, setOpenDialogAutorizarReporte] =
		React.useState(false);
	const [tituloPreset, setTituloPreset] = React.useState("");
	const rol = localStorage.getItem("userRol")
		? usuarioService.listaRoles[localStorage.getItem("userRol")]
		: "Usuario no autorizado";
	const navigate = useNavigate();
	const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
	const [selectedEvidencia, setSelectedEvidencia] = React.useState(null);
	const [errors, setErrors] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(false);

	const handleOpenGuardarPreset = () => {
		setOpenGuardarPresetDialog(true);
	};

	const handleCloseGuardarPreset = () => {
		setOpenGuardarPresetDialog(false);
		setTituloPreset("");
	};

	const handleGuardarPreset = async () => {
		try {
			const response = await reporteService.crearPreset({
				id: idReporte,
				nombre: tituloPreset,
			});
			if (response.status === 200) {
				refreshEvidencias(); // Asumiendo que quieres refrescar las evidencias después de guardar el preset
				handleCloseGuardarPreset();
			} else {
				console.error("Error al guardar el preset:", response.statusText);
			}
		} catch (error) {
			console.error("Error al guardar el preset:", error);
		}
	};

	const handleOpenAdjuntarEvidencia = () => {
		setOpenDialogAdjuntarEvidencia(true);
	};
	const handleOpenConfirmDialog = (evidencia) => {
		setSelectedEvidencia(evidencia);
		setOpenConfirmDialog(true);
	};

	const handleCloseConfirmDialog = () => {
		setOpenConfirmDialog(false);
		setSelectedEvidencia(null);
	};

	const handleCloseAdjuntarEvidencia = () => {
		setOpenDialogAdjuntarEvidencia(false);
		setEvidenciaActual(null);
		setTipoEvidencia("archivo");
		setPaginaEvidencia("");
	};

	const handleDescargarReporte = async (formato) => {
		try {
			Swal.fire({
				title: "Preparando descarga",
				text: "Dependiendo del reporte esto puede tomar tiempo, por favor espere...",
				allowOutsideClick: false,
				showConfirmButton: false,
				willOpen: () => {
					Swal.showLoading();
				},
			});
			setOpenDescargarReporteDialog(false);
			const response = await reporteService.descargarReporte(
				idReporte,
				formato,
			);
			Swal.close();
			if (!response || !response.data) {
				throw new Error("No se recibió una respuesta válida del servidor.");
			}

			// Determinar el tipo de contenido basado en el formato
			let contentType;
			let extension;
			if (formato === "pdf") {
				contentType = "application/pdf";
				extension = "pdf";
			} else if (formato === "word") {
				contentType =
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
				extension = "docx";
			} else {
				throw new Error("Formato no soportado.");
			}

			// Convertir la respuesta a un blob
			const blob = new Blob([response.data], { type: contentType });

			if (formato === "pdf") {
				// Crear una URL para el blob y abrirla en una nueva pestaña para PDFs
				const url = URL.createObjectURL(blob);
				window.open(url);
				setTimeout(() => {
					URL.revokeObjectURL(url);
				}, 1000);
			} else if (formato === "word") {
				// Crear una URL para el blob y simular un clic para descargar el archivo para Word
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `reporte.${extension}`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error("Error al descargar el reporte:", error);
		}
		setOpenDescargarReporteDialog(false);
	};

	function formatUrl(address) {
		// Elimina espacios en blanco al inicio y al final de la cadena
		address = address.trim();

		if (!address.startsWith("http://") && !address.startsWith("https://")) {
			address = "https://" + address;
		}

		if (!address.endsWith("/")) {
			address = address + "/";
		}

		return address;
	}

	const handleAgregarEvidencia = async () => {
		if (isLoading) return; // Evita múltiples clics si ya está en curso una carga

		let validationErrors = {};

		// Validar nombre de la evidencia
		if (!nombreEvidencia.trim()) {
			validationErrors.nombreEvidencia =
				"El nombre de la evidencia es obligatorio.";
		}

		// Validar tipo de evidencia y su contenido
		if (tipoEvidencia === "archivo" && !evidenciaActual) {
			validationErrors.evidenciaActual = "Debe adjuntar un archivo.";
		} else if (tipoEvidencia === "pagina") {
			if (!paginaEvidencia.trim()) {
				validationErrors.paginaEvidencia = "Debe proporcionar una URL.";
			} else {
				// Validar formato de la URL
				const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
				if (!urlPattern.test(paginaEvidencia.trim())) {
					validationErrors.paginaEvidencia =
						"Debe proporcionar una URL válida (por ejemplo, https://www.ejemplo.com).";
				}
			}
		}

		if (tipoEvidencia === "archivo" && evidenciaActual.size > 50000000) {
			validationErrors.evidenciaActual =
				"El tamaño del archivo debe ser menor a 50Mb";
		}

		// Si hay errores, actualiza el estado y no procedas
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		setIsLoading(true); // Deshabilita el botón mientras carga

		// Si no hay errores, procede a crear la evidencia
		const formData = new FormData();
		formData.append("nombre", nombreEvidencia);
		formData.append("tipo", tipoEvidencia);
		if (tipoEvidencia === "archivo") {
			formData.append("archivo", evidenciaActual);
		} else {
			formData.append("url", paginaEvidencia);
		}

		try {
			await reporteService.crearEvidencia(idReporte, formData);
			refreshEvidencias();
			// Limpiar todos los campos después de guardar
			setNombreEvidencia("");
			setEvidenciaActual(null);
			setPaginaEvidencia("");
			setTipoEvidencia("archivo");
			setErrors({});
			setOpenDialogAdjuntarEvidencia(false);
		} catch (error) {
			console.error("Error al crear la evidencia:", error);
			if (error.response.data === "Extensión de archivo no válida") {
				validationErrors.evidenciaActual =
					"El tipo de archivo seleccionado no es válido.";
				setErrors((prevErrors) => ({
					...prevErrors, // Mantiene los errores anteriores si es necesario
					...validationErrors, // Reemplaza con los nuevos errores
				}));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleEliminarEvidencia = async () => {
		if (selectedEvidencia) {
			await reporteService.eliminarEvidencia(selectedEvidencia.id);
			refreshEvidencias();
			handleCloseConfirmDialog();
		}
	};

	const accederEvidencia = async (evidencia) => {
		try {
			if (evidencia.tipo.toLowerCase() === "archivo") {
				let url = await reporteService
					.obtenerUrlS3(evidencia.id)
					.then((response) => response.data); // Obtener la URL del servicio S3.
				if (url) {
					window.open(url, "_blank"); // Abrir la URL en una nueva pestaña.
				} else {
					console.error("La URL obtenida del servicio S3 es nula o no válida."); // Mostrar un mensaje de error.
				}
			} else {
				window.open(formatUrl(evidencia.url), "_blank"); // Abrir la URL en una nueva pestaña.
			}
		} catch (error) {
			console.error("Error al acceder a la evidencia:", error); // Mostrar un mensaje de error.
		}
	};

	const handleAutorizacion = async () => {
		try {
			let coordenadas = {
				idUsuario: usuarioLogeado.id,
			};
			const response = await reporteService.autorizarReporte(
				idReporte,
				coordenadas,
			);
			if (response.status === 200) {
				fetchData();
				setOpenDialogAutorizarReporte(false);
			} else {
				console.error("Error al autorizar el reporte:", response.statusText);
			}
		} catch (error) {
			console.error("Error al autorizar el reporte:", error);
		}
	};

	return (
		<React.Fragment>
			<Box
				bgcolor="#fff"
				sx={{
					position: "fixed",
					bottom: 0,
					left: 0,
					right: 0,
					boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)",
					height: "80px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<Grid container spacing={2} justifyContent="center">
						<Grid item>
							<Button
								variant="contained"
								sx={{
									textTransform: "none",
									fontSize: "15px",
									fontWeight: "bold",
									mr: 1,
									backgroundColor: mostrarSemaforos ? "#006C94" : "#bbbbbb",
									color: mostrarSemaforos ? "#FFFFFF" : "#000000",
									"&:hover": {
										backgroundColor: mostrarSemaforos ? "#00D2E1" : "#000000",
										color: mostrarSemaforos ? "#006C94" : "#bbbbbb",
									},
								}}
								onClick={() => setMostrarSemaforos(!mostrarSemaforos)}
							>
								{mostrarSemaforos
									? "Ocultar estado de avance"
									: "Mostrar estado de avance"}
							</Button>
						</Grid>
						{usuarioLogeado &&
							rolesGestionarEvidencias.includes(
								parseInt(usuarioLogeado.rol),
							) && (
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										sx={{
											textTransform: "none",
											fontSize: "15px",
											fontWeight: "bold",
											mr: 1,
											backgroundColor: "#006C94",
											color: "#FFFFFF",
											"&:hover": {
												backgroundColor: "#00D2E1",
												color: "#006C94",
											},
										}}
										onClick={() => setOpenDialog(true)}
									>
										Gestionar Evidencias
									</Button>
								</Grid>
							)}
						{usuarioLogeado &&
							rolesDescargarReporte.includes(parseInt(usuarioLogeado.rol)) && (
								<Grid item>
									<Button
										variant="contained"
										color="cuaternary"
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											color: "white",
										}}
										onClick={() => setOpenDescargarReporteDialog(true)}
									>
										Descargar Reporte
									</Button>
								</Grid>
							)}
						{usuarioLogeado &&
							rolesGuardarPlantilla.includes(parseInt(usuarioLogeado.rol)) && (
								<Grid item>
									<Button
										variant="contained"
										color="warning"
										sx={{
											textTransform: "none",
											fontSize: "15px",
											fontWeight: "bold",
											mr: 1,
											backgroundColor: "#00D2E1",
											color: "#006C94",
											"&:hover": {
												backgroundColor: "#006C94",
												color: "#FFFFFF",
											},
										}}
										onClick={() => handleOpenGuardarPreset()}
									>
										Guardar Plantilla
									</Button>
								</Grid>
							)}
						{usuarioLogeado &&
							rolesAutorizarReporte.includes(parseInt(usuarioLogeado.rol)) && (
								<Grid item>
									<Button
										variant="contained"
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											color: "#ffffff",
											backgroundColor: "secondary.main",
											"&:hover": {
												color: "#ffffff",
												backgroundColor: "primary.main",
											},
										}}
										onClick={() => setOpenDialogAutorizarReporte(true)}
									>
										Autorizar Reporte
									</Button>
								</Grid>
							)}
						{usuarioLogeado &&
							rolesAutorizarReporte.includes(parseInt(usuarioLogeado.rol)) && (
								<Grid item>
									<Button
										variant="contained"
										color="secondary"
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											backgroundColor: "#bbbbbb",
											color: "#000000",
											"&:hover": {
												backgroundColor: "#000000",
												color: "#bbbbbb",
											},
										}}
										onClick={() =>
											navigate(
												`/empresas/${idEmpresa}/reportes/${idReporte}/roles`,
											)
										}
									>
										Administrar Roles
									</Button>
								</Grid>
							)}
					</Grid>
				</Box>
			</Box>
			{/* Diálogo de gestión de evidencias */}
			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				maxWidth="md"
				fullWidth
			>
				{/* Contenido del diálogo */}
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography
								variant="h5"
								color="primary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								Gestionar Evidencias
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton onClick={() => setOpenDialog(false)} disableRipple>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{evidencias.length >= 1 &&
						evidencias.map((evidencia, index) => (
							<>
								<Box sx={{ pl: 2, pr: 2, mb: 2 }} key={index}>
									<Grid
										container
										alignItems="center"
										justifyContent="space-between"
										borderBottom={2}
										borderColor={"secondary.main"}
										sx={{ mx: 0, py: 1 }}
									>
										<Grid item xs={3}>
											<Typography
												variant="h6"
												color="#000000"
												sx={{ fontFamily: "Segoe UI", fontWeight: "bold" }}
											>
												{evidencia.nombre}
											</Typography>
										</Grid>
										<Grid item xs={3} container>
											<Typography
												variant="h6"
												color={"primary"}
												sx={{
													fontFamily: "Segoe UI",
													fontStyle: "italic",
													fontWeight: "normal",
												}}
											>
												{evidencia.tipo.toLowerCase() === "archivo"
													? evidencia.nombreOriginal
													: formatUrl(evidencia.url)}
											</Typography>
										</Grid>
										<Grid item xs={6} container justifyContent={"flex-end"}>
											<Tooltip title="Eliminar" placement="bottom" arrow>
												<IconButton
													variant="outlined"
													color="secondary"
													onClick={() => handleOpenConfirmDialog(evidencia)}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>

											<Tooltip
												title={
													evidencia.tipo.toLowerCase() === "archivo"
														? "Descargar"
														: "Abrir"
												}
												placement="bottom"
												arrow
											>
												<IconButton
													variant="outlined"
													color="primary"
													onClick={() => accederEvidencia(evidencia)}
												>
													{evidencia.tipo.toLowerCase() === "archivo" ? (
														<CloudDownloadIcon />
													) : (
														<OpenInNewIcon />
													)}
												</IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</Box>
							</>
						))}
					{evidencias.length === 0 && (
						<Box sx={{ pl: 2, pr: 2, mb: 2 }}>
							<Typography
								variant="h6"
								color="secondary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								No se encontraron evidencias en el reporte.
							</Typography>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="center">
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleOpenAdjuntarEvidencia()}
								sx={{
									textTransform: "none",
									fontWeight: "bold",
									fontStyle: "italic",
									fontSize: "1rem",
									mr: 1,
								}}
							>
								Agregar Evidencia
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			{/* Diálogo de confirmación */}
			<Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
				<DialogContent>
					<Typography variant="h6">
						¿Está seguro de que desea eliminar la evidencia "
						{selectedEvidencia?.nombre}"?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmDialog} color="secondary">
						Cancelar
					</Button>
					<Button onClick={handleEliminarEvidencia} color="error">
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
			{/* Diálogo de adjuntar evidencia */}
			<Dialog
				open={openDialogAdjuntarEvidencia}
				onClose={() => handleCloseAdjuntarEvidencia()}
				fullWidth
			>
				{/* Contenido del diálogo */}
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography
								variant="h5"
								color="primary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								Adjuntar Evidencia
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton
								onClick={() => setOpenDialogAdjuntarEvidencia(false)}
								disableRipple
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", ml: 2 }}
					>
						<Typography variant="h6">
							Escriba el nombre de la evidencia:
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
					>
						<TextField
							label="Nombre de la Evidencia"
							variant="outlined"
							value={nombreEvidencia}
							error={!!errors.nombreEvidencia}
							helperText={errors.nombreEvidencia}
							sx={{ width: "90%" }}
							onChange={(e) => {
								setNombreEvidencia(e.target.value);
								setErrors((prev) => ({ ...prev, nombreEvidencia: null }));
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", ml: 2, mt: 2 }}
					>
						<Typography variant="h6">
							Seleccione el tipo de evidencia:
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
					>
						<Select
							value={tipoEvidencia}
							onChange={(e) => setTipoEvidencia(e.target.value)}
							variant="outlined"
							sx={{ width: "50%", mr: 2 }}
						>
							<MenuItem value="archivo">Archivo</MenuItem>
							<MenuItem value="pagina">Página</MenuItem>
						</Select>
					</Grid>
					{tipoEvidencia === "archivo" && (
						<>
							<Grid
								item
								xs={12}
								sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
							>
								<Typography variant="h6" sx={{ mr: 2 }}>
									Seleccione el archivo:
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
							>
								<Tooltip
									title="Tenga en cuenta que el tamaño máximo soportado es de 50Mb"
									placement="bottom"
									arrow
								>
									<Button
										variant="contained"
										component="label"
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											fontStyle: "italic",
											fontSize: "1rem",
											mr: 2,
										}}
										startIcon={<CloudUploadIcon />}
									>
										Adjuntar Archivo
										<input
											type="file"
											hidden
											onChange={(event) => {
												setEvidenciaActual(event.target.files[0]);
												setErrors((prev) => ({
													...prev,
													evidenciaActual: null,
												}));
											}}
										/>
									</Button>
								</Tooltip>
								<Typography sx={{ marginLeft: 1, whiteSpace: "nowrap" }}>
									{evidenciaActual
										? evidenciaActual.name
										: "No se ha seleccionado un archivo"}{" "}
									{/* Mensaje dinámico */}
								</Typography>
								{errors.evidenciaActual && (
									<Typography
										variant="body2"
										color="error"
										sx={{ mt: 1, ml: 2 }}
									>
										{errors.evidenciaActual}
									</Typography>
								)}
							</Grid>
						</>
					)}

					{tipoEvidencia === "pagina" && (
						<>
							<Grid
								item
								xs={12}
								sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
							>
								<Typography variant="h6" sx={{ mr: 2 }}>
									Escriba el URL de la evidencia:
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
							>
								<TextField
									label="Escribir URL"
									variant="outlined"
									value={paginaEvidencia}
									error={!!errors.paginaEvidencia}
									helperText={errors.paginaEvidencia}
									sx={{ width: "90%" }}
									onChange={(e) => {
										setPaginaEvidencia(e.target.value);
										setErrors((prev) => ({ ...prev, paginaEvidencia: null }));
									}}
								/>
							</Grid>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => handleCloseAdjuntarEvidencia()}
							>
								Cancelar
							</Button>
							<Button
								color="primary"
								variant="text"
								onClick={() => handleAgregarEvidencia()}
								disabled={isLoading} // Deshabilita el botón mientras carga
							>
								{isLoading ? "Cargando..." : "Subir"}{" "}
								{/* Cambia el texto mientras carga */}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			{/* Diálogo de descargar reporte */}
			<Dialog
				open={openDescargarReporteDialog}
				onClose={() => setOpenDescargarReporteDialog(false)}
				maxWidth="md"
				fullWidth
			>
				{/* Contenido del diálogo */}
				<DialogContent>
					<Grid container direction="column" spacing={2}>
						<Grid item container>
							<Grid item xs={8}>
								<Typography
									variant="h5"
									color="primary"
									fontWeight="bold"
									sx={{ mt: 1 }}
								>
									Descargar Reporte
								</Typography>
							</Grid>
							<Grid item xs={4} container justifyContent="flex-end">
								<IconButton
									onClick={() => setOpenDescargarReporteDialog(false)}
								>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>

						<Grid item container justifyContent="center">
							<Typography variant="h6" sx={{ mb: 1 }}>
								Seleccione el formato de descarga
							</Typography>
						</Grid>

						<Grid item container justifyContent="center">
							<Button
								variant="contained"
								color="error"
								onClick={() => handleDescargarReporte("pdf")}
								startIcon={<CloudDownloadIcon />}
								sx={{
									mr: 4,
									textTransform: "none",
									fontWeight: "bold",
									fontStyle: "italic",
									fontSize: "1rem",
								}}
							>
								Descargar PDF
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleDescargarReporte("word")}
								startIcon={<CloudDownloadIcon />}
								sx={{
									textTransform: "none",
									fontWeight: "bold",
									fontStyle: "italic",
									fontSize: "1rem",
								}}
							>
								Descargar Word
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => setOpenDescargarReporteDialog(false)}
							>
								Cancelar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			{/* Diálogo de guardar preset */}
			<Dialog
				open={openGuardarPresetDialog}
				onClose={() => handleCloseGuardarPreset()}
				maxWidth="sm"
				fullWidth
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography
								variant="h5"
								color="primary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								Guardar Plantilla
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton
								onClick={() => handleCloseGuardarPreset()}
								disableRipple
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", ml: 2 }}
					>
						<Typography variant="h6">
							Ingrese el título de la plantilla:
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", mt: 2, ml: 2 }}
					>
						<TextField
							label="Título de la Plantilla"
							variant="outlined"
							value={tituloPreset}
							sx={{ width: "90%" }}
							onChange={(e) => setTituloPreset(e.target.value)}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => handleCloseGuardarPreset()}
							>
								Cancelar
							</Button>
							<Button
								color="primary"
								variant="text"
								onClick={() => handleGuardarPreset()}
							>
								Guardar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			{/* Diálogo de autorizar reporte */}
			<Dialog
				open={openDialogAutorizarReporte}
				onClose={() => setOpenDialogAutorizarReporte(false)}
				maxWidth="md"
				fullWidth
			>
				{/* Contenido del diálogo */}
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography
								variant="h5"
								color="primary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								Autorizar Reporte
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton
								onClick={() => setOpenDialogAutorizarReporte(false)}
								disableRipple
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: "flex", alignItems: "center", ml: 2 }}
					>
						<Typography variant="h6">
							{reporteAutorizado
								? "El reporte ya se encuentra autorizado."
								: "Usted está a punto de autorizar el reporte. Es decir autorizar TODOS los campos del reporte. ¿Autorizar el reporte?"}
						</Typography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => setOpenDialogAutorizarReporte(false)}
							>
								Cancelar
							</Button>
							{!reporteAutorizado && (
								<Button
									color={reporteAutorizado ? "error" : "cuaternary"}
									variant="text"
									onClick={() => handleAutorizacion()}
								>
									Autorizar
								</Button>
							)}
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default NavbarEvidencia;

import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Navbar from "./Navbar";
import empresaService from "../services/EmpresaService";
import { Link } from "react-router-dom";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function CrearEmpresa() {
    const [nombre, setNombre] = useState("");
    const [tipoSociedad, setTipoSociedad] = useState("");
    const [domicilioEmpresa, setDomicilioEmpresa] = useState("");
    const [paginaWeb, setPaginaWeb] = useState("");
    const [email, setEmail] = useState("");
    const [domicilioContacto, setDomicilioContacto] = useState("");
    const [telefono, setTelefono] = useState("");
    const [razonSocial, setRazonSocial] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [tipoIdentificacion, setTipoIdentificacion] = useState("RUT");
    const [rut, setRut] = useState("");
    const [pais, setPais] = useState("");
    const [listaPaises, setListaPaises] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch("https://restcountries.com/v3.1/all?fields=name,translations,cca2");
                const data = await response.json();

                // Crear lista con nombres en español
                const countriesInSpanish = data.map((country) => ({
                    code: country.cca2, // Código del país (ISO Alpha-2)
                    name: country.translations.spa.common, // Nombre en español
                }));

                // Ordenar lista alfabéticamente
                countriesInSpanish.sort((a, b) => a.name.localeCompare(b.name));

                // Actualizar el estado
                setListaPaises(countriesInSpanish);
            } catch (error) {
                console.error("Error al obtener la lista de países:", error);
            }
        };

        fetchCountries();
    }, []);

    const validateDNI = (dni) => {
        const dniPattern = /^[a-zA-Z0-9]{7,}$/;
        return dniPattern.test(dni) ? "" : "Número Tributario inválido. Debe contener al menos 7 caracteres.";
    };


    const tiposSociedad = ["Sociedad Anónima", "Sociedad de Responsabilidad Limitada", "Empresa Individual", "Otra"];

    const validateURL = (url) => {
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
        return urlPattern.test(url) ? "" : "URL inválida. Debe ser una dirección válida (ej. https://www.ejemplo.com).";
    };


    const validateIdentificacion = (value, tipo) => {
        if (tipo === "RUT") {
            const rutPattern = /^[0-9]+-[0-9Kk]$/;
            if (!rutPattern.test(value)) {
                return "RUT inválido. Debe estar en formato 12345678-9.";
            }

            const [rut, dv] = value.split("-");
            const calculatedDv = calcularDigitoVerificador(rut);

            if (calculatedDv.toString().toUpperCase() !== dv.toUpperCase()) {
                return "RUT inválido. El dígito verificador no coincide.";
            }

            return ""; // RUT válido
        } else if (tipo === "Número Tributario") {
            const dniPattern = /^[a-zA-Z0-9]{7,}$/;
            return dniPattern.test(value) ? "" : "Número Tributario inválido. Debe contener al menos 7 caracteres.";
        }
        return "";
    };

    const calcularDigitoVerificador = (rut) => {
        let suma = 0;
        let factor = 2;

        // Recorremos los dígitos del RUT de derecha a izquierda
        for (let i = rut.length - 1; i >= 0; i--) {
            suma += parseInt(rut[i], 10) * factor;
            factor = factor === 7 ? 2 : factor + 1; // Ciclo de factores de 2 a 7
        }

        const resto = suma % 11;
        const dv = 11 - resto;

        // Retornamos el dígito verificador calculado
        if (dv === 10) return "K";
        if (dv === 11) return "0";
        return dv;
    };



    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const validateTelefono = (telefono) => {
        const telefonoPattern = /^\+?[0-9]{9,12}$/;
        return telefonoPattern.test(telefono);
    };

    const handleCrearEmpresa = async (event) => {
        event.preventDefault();

        let validationErrors = {};

        // Validación de identificación
        const identificacionError = validateIdentificacion(rut, tipoIdentificacion);
        if (identificacionError) validationErrors.rut = identificacionError;


        // Validación de email
        if (!validateEmail(email)) {
            validationErrors.email = "Correo electrónico inválido.";
        }

        // Validación de teléfono
        if (!validateTelefono(telefono) && telefono !== "") {
            validationErrors.telefono = "Teléfono inválido. Debe contener entre 9 y 12 dígitos.";
        }

        // Validación de URL
        const urlError = validateURL(paginaWeb);
        if (paginaWeb && urlError) {
            validationErrors.paginaWeb = urlError; // Asegúrate de usar "paginaWeb" como clave
        }

        // Mostrar errores si existen
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        if (!tipoIdentificacion) {
            validationErrors.tipoRut = "Seleccione el tipo de identificación.";
        }

        try {
            const response = await empresaService.verificarRut(rut);

            // Si la API devuelve datos, significa que el RUT ya está en uso
            if (response.data) {
                setErrors({ rut: "El Identificador ingresado ya está en uso. Por favor ingrese otro." });
                return;
            }
        } catch (error) {
            // Si el error es un 404, significa que el RUT no está en uso y es válido
            if (error.response && error.response.status === 404) {
                console.log("RUT no encontrado, puede usarse.");
            } else {
                // Si es otro error, manejarlo como un problema en la verificación
                console.error("Error al verificar el RUT:", error);
                setErrors({ rut: "Ocurrió un problema al verificar el RUT. Intente nuevamente más tarde." });
                return;
            }
        }

        const empresaData = {
            nombre,
            tipoSociedad,
            rut,
            tipoRut: tipoIdentificacion, // Agregar tipoRut aquí
            pais,
            domicilioEmpresa,
            paginaWeb,
            email,
            domicilioContacto,
            telefono,
            razonSocial,
        };

        try {
            await empresaService.crearEmpresa(empresaData);
            setOpenDialog(true);
        } catch (error) {
            console.error('Error al crear la empresa:', error);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Navbar />
            <Container>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h4" color={"primary.main"} fontWeight={"bold"} sx={{ mb: 2 }}>Crear Empresa</Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Link to="/empresas" style={{ textDecoration: "none" }}>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBackIcon />}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        mr: 1,
                                        fontSize: "1.1rem",
                                        maxHeight: 0.7,
                                        color: "#ffffff"
                                    }}>
                                    Volver
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleCrearEmpresa}>
                        <Grid container spacing={1} sx={{ p: 1 }}>
                            {/* Nombre de Fantasía */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Nombre de fantasía:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Nombre de fantasía"
                                        name="nombre"
                                        variant="outlined"
                                        helperText="Nombre de la empresa"
                                        fullWidth
                                        required
                                        onChange={(event) => setNombre(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Tipo de Sociedad */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Tipo de sociedad:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel>Tipo de sociedad</InputLabel>
                                        <Select
                                            label="Tipo de Sociedad"
                                            name="tipoSociedad"
                                            value={tipoSociedad}
                                            onChange={(event) => setTipoSociedad(event.target.value)}
                                        >
                                            {tiposSociedad.map((tipo, index) => (
                                                <MenuItem key={index} value={tipo}>{tipo}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* País */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        País:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel>País</InputLabel>
                                        <Select
                                            label="País*"
                                            value={pais}
                                            onChange={(e) => setPais(e.target.value)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Altura máxima del menú desplegable
                                                        overflowY: 'auto', // Habilitar scroll vertical si el contenido es largo
                                                    },
                                                },
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                            }}
                                        >
                                            {listaPaises.map((country) => (
                                                <MenuItem key={country.code} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            {/* Tipo de Identificación */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Tipo de Identificación:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <RadioGroup
                                        row
                                        value={tipoIdentificacion}
                                        onChange={(e) => setTipoIdentificacion(e.target.value)}
                                    >
                                        <FormControlLabel value="RUT" control={<Radio />} label="RUT" />
                                        <FormControlLabel value="Número Tributario" control={<Radio />} label="Número Tributario" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            {/* RUT */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        {tipoIdentificacion}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label={tipoIdentificacion}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={!!errors.rut}
                                        helperText={
                                            errors.rut
                                                ? errors.rut
                                                : tipoIdentificacion === "RUT"
                                                    ? "Sin puntos y con guión."
                                                    : "Debe contener al menos 7 caracteres."
                                        }
                                        value={rut}
                                        onChange={(e) => setRut(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Domicilio de la Empresa */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Dirección comercial:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Dirección comercial"
                                        name="domicilioEmpresa"
                                        variant="outlined"
                                        helperText="Domicilio de la empresa"
                                        fullWidth
                                        required
                                        onChange={(event) => setDomicilioEmpresa(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Página Web */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Página web:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Pagina web"
                                        name="paginaWeb"
                                        variant="outlined"
                                        helperText={errors.paginaWeb ? errors.paginaWeb : "Página web de la empresa"}
                                        error={!!errors.paginaWeb}
                                        fullWidth
                                        onChange={(event) => setPaginaWeb(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Razón Social */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Razón social:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="razonSocial"
                                        label="Razon social"
                                        variant="outlined"
                                        helperText="Razón Social de la empresa"
                                        fullWidth
                                        required
                                        onChange={(event) => setRazonSocial(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Correo Electrónico */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Correo electrónico:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Correo electrónico"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        helperText={errors.email ? errors.email : "Correo electrónico de la empresa"}
                                        error={!!errors.email}
                                        fullWidth
                                        required
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Domicilio de Contacto */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Direccion de contacto:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Dirección de contacto"
                                        name="domicilioContacto"
                                        variant="outlined"
                                        helperText="Domicilio de contacto de la empresa"
                                        fullWidth
                                        onChange={(event) => setDomicilioContacto(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Teléfono */}
                            <Grid container alignItems="center" sx={{ py: 1 }}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" fontWeight="bold">
                                        Teléfono:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Teléfono"
                                        name="telefono"
                                        variant="outlined"
                                        helperText={errors.telefono ? errors.telefono : "Teléfono de contacto de la empresa"}
                                        error={!!errors.telefono}
                                        fullWidth
                                        onChange={(event) => setTelefono(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* Botón de Crear Empresa */}
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform: "none", fontWeight: "bold", color: "#ffffff", mr: 1, fontSize: "1.1rem" }}
                                >
                                    Crear Empresa
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Empresa creada"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            La empresa ha sido creada exitosamente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Link to="/empresas" style={{ textDecoration: "none" }}>
                            <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                OK
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}

export default CrearEmpresa;

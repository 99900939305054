import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Container,
    Typography,
    Tooltip,
    Paper,
    Grid,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import ReporteService from "../services/ReporteService";
import Loading from "./Loading";

function EditarRol() {

    const { idEmpresa, idReporte, idRol } = useParams();

    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [listaCategorias, setListaCategorias] = useState([]);
    const [permisosCategorias, setPermisosCategorias] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const secciones = ["Roles", "Empleados"];
    const seccionesRutas = [`/empresas/${idEmpresa}/reportes/${idReporte}/roles`, `/empresas/${idEmpresa}/reportes/${idReporte}/empleados`];
    const seccionActual = "Roles";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rol, setRol] = useState({});

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // Obtención del rol
            const response = await ReporteService.obtenerReporte(idReporte);
            const categorias = response.data.categorias;

            // Inicializar permisos para las categorías y secciones
            const inicialPermisos = {};
            categorias.forEach((categoria) => {
                inicialPermisos[categoria.titulo] = {
                    id: categoria.id,
                    titulo: categoria.titulo,
                    check: false, // Estado inicial de categoría
                    secciones: categoria.secciones.map((seccion) => ({
                        id: seccion.id,
                        titulo: seccion.titulo,
                        check: false, // Estado inicial de la sección
                    })),
                };
            });
            if (response.data.roles != null && response.data.roles != undefined) {
                for (let i = 0; i < response.data.roles.length; i++) {
                    if (response.data.roles[i].id === idRol) {
                        setRol(response.data.roles[i]);
                        setNombre(response.data.roles[i].nombre);
                        setDescripcion(response.data.roles[i].descripcion);
                        for (let j = 0; j < response.data.roles[i].idCategorias.length; j++) {
                            const categoria = response.data.roles[i].idCategorias[j];
                            inicialPermisos[categoria.titulo].check = true;
                            for (let k = 0; k < categoria.idSecciones.length; k++) {
                                const seccion = categoria.idSecciones[k];
                                inicialPermisos[categoria.titulo].secciones = inicialPermisos[categoria.titulo].secciones.map((seccionMap) =>
                                    seccionMap.id === seccion ? { ...seccionMap, check: true } : seccionMap
                                );
                            }
                        }
                        break;
                    }
                }
            }
            setListaCategorias(categorias);
            setPermisosCategorias(inicialPermisos);
            setLoading(false);
        } catch (error) {
            console.error("Error al obtener los roles:", error);
        }
    };

    // Manejar el cambio de estado de un checkbox para categorías o secciones
    const handleCheckboxChange = (categoriaTitulo, seccionTitulo = null) => {
        setPermisosCategorias((prevState) => {
            const nuevaCategoria = { ...prevState[categoriaTitulo] };

            if (seccionTitulo) {
                // Cambiar el estado del checkbox de la sección
                nuevaCategoria.secciones = nuevaCategoria.secciones.map((seccion) =>
                    seccion.titulo === seccionTitulo
                        ? { ...seccion, check: !seccion.check }
                        : seccion
                );

                // Verificar si todas las secciones están marcadas
                nuevaCategoria.check = nuevaCategoria.secciones.every((seccion) => seccion.check);
            } else {
                // Cambiar el estado del checkbox de la categoría
                nuevaCategoria.check = !nuevaCategoria.check;

                // Cambiar el estado de todas las secciones dentro de la categoría
                nuevaCategoria.secciones = nuevaCategoria.secciones.map((seccion) => ({
                    ...seccion,
                    check: nuevaCategoria.check,
                }));
            }

            return {
                ...prevState,
                [categoriaTitulo]: nuevaCategoria,
            };
        });
    };

    const [openDialogError, setOpenDialogError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [errorNombreRol, setErrorNombreRol] = useState(false);
    const [errorMessageNombre, setErrorMessageNombre] = useState("");

    const handleEditarRol = async (event) => {
        event.preventDefault();
        try {
            // Obtener la lista de roles existentes
            const rolesExistentes = await ReporteService.getRoles(idReporte);

            // Normalizar el nombre del rol ingresado
            const nombreNormalizado = nombre.trim().toLowerCase();

            // Verificar si otro rol ya tiene el mismo nombre (excluyendo el que estamos editando)
            const rolDuplicado = rolesExistentes.data.some(rol =>
                rol.id !== idRol && rol.nombre.trim().toLowerCase() === nombreNormalizado
            );

            if (rolDuplicado) {
                setErrorNombreRol(true);
                setErrorMessageNombre("Ya existe un rol con este nombre.");
                return; // No continuar con la edición
            } else {
                setErrorNombreRol(false);
                setErrorMessageNombre("");
            }

            // Validar que se haya seleccionado al menos una categoría o sección
            let categoriasSeleccionadas = Object.values(permisosCategorias)
                .filter((categoria) => categoria.check || categoria.secciones.some((seccion) => seccion.check));

            if (categoriasSeleccionadas.length === 0) {
                setErrorMessage("Debes seleccionar al menos una categoría o sección.");
                setOpenDialogError(true);
                return;
            }

            // Validar que se haya ingresado un nombre
            if (!nombre.trim()) {
                setErrorMessage("Debes ingresar un nombre para el rol.");
                setOpenDialogError(true);
                return;
            }

            // Validar que el nombre del rol no exceda los 50 caracteres
            if (nombre.length > 50) {
                setErrorMessage("El nombre del rol no puede exceder los 50 caracteres.");
                setOpenDialogError(true);
                return;
            }

            categoriasSeleccionadas = categoriasSeleccionadas.map((categoria) => ({
                id: categoria.id,
                titulo: categoria.titulo,
                idSecciones: categoria.secciones.filter((seccion) => seccion.check).map((seccion) => seccion.id),
            }));

            const nuevoRol = {
                id: idRol,
                nombre,
                descripcion,
                idCategorias: categoriasSeleccionadas,
                permisos: rol.permisos ? rol.permisos : null,
                idEmpresa: idEmpresa,
                idUsuarios: rol.idUsuarios
            };

            await ReporteService.agregarRol(idReporte, nuevoRol);
            setOpenDialog(true);
        } catch (error) {
            console.error("Error al editar el rol:", error);
            setErrorMessage("Hubo un error al editar el rol. Inténtalo nuevamente.");
            setOpenDialogError(true);
        }
    };




    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate(-1);
    };

    return (
        <>
            <Navbar
                useSectionMode={false}
                secciones={secciones}
                seccionesRutas={seccionesRutas}
                seccionActual={seccionActual}
            />
            {loading && (
                <Loading />
            )}
            {!loading && (
                <>
                    <Container>
                        <Paper sx={{ mt: 2, p: 2 }}>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h4" color="primary" fontWeight="bold">
                                        Editando rol: {nombre}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Volver a la lista de roles" arrow>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate(`/empresas/${idEmpresa}/reportes/${idReporte}/roles`)}
                                            startIcon={<ArrowBackIcon />}
                                            sx={{
                                                textTransform: "none",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Volver
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Box component="form" onSubmit={handleEditarRol} sx={{ mt: 4 }}>
                                <TextField
                                    label="Nombre del Rol"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={nombre}
                                    error={errorNombreRol} // Muestra el borde rojo si hay un error
                                    helperText={errorNombreRol ? errorMessageNombre : ""} // Mensaje debajo del campo
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setNombre(e.target.value);
                                        setErrorNombreRol(false); // Limpiar error cuando el usuario escribe
                                        setErrorMessageNombre("");
                                    }}
                                    sx={{ mb: 3 }}
                                />

                                <TextField
                                    label="Descripción"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={descripcion}
                                    autoComplete="off"
                                    onChange={(e) => setDescripcion(e.target.value)}
                                    sx={{ mb: 3 }}
                                />

                                {Object.values(permisosCategorias).map((categoria) => (
                                    <Accordion key={categoria.id}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={categoria.check}
                                                        onChange={() => handleCheckboxChange(categoria.titulo)}
                                                    />
                                                }
                                                label={<Typography variant="h6" fontWeight="bold">{categoria.titulo}</Typography>}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box display="flex" flexDirection="column">
                                                {categoria.secciones.map((seccion) => (
                                                    <FormControlLabel
                                                        key={seccion.id}
                                                        control={
                                                            <Checkbox
                                                                checked={seccion.check}
                                                                onChange={() => handleCheckboxChange(categoria.titulo, seccion.titulo)}
                                                            />
                                                        }
                                                        label={seccion.titulo}
                                                    />
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Guardar Cambios
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                            <DialogTitle>{"Rol editado exitósamente"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>El rol ha sido editado con éxito.</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openDialogError} onClose={() => setOpenDialogError(false)}>
                            <DialogTitle>
                                {"Advertencia"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {errorMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDialogError(false)} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Container>
                </>
            )}
        </>
    );
}

export default EditarRol;


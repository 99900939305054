import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Button, Grid, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Navbar from "./Navbar";
import empresaService from "../services/EmpresaService";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
import usuarioService from "../services/UsuarioService";

function Empresas() {
    const secciones = ["Empresas", "Usuarios"];
    const seccionesRutas = ["/empresas", "/usuarios"];
    const seccionActual = "Empresas";

    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [empresaAEliminar, setEmpresaAEliminar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [usuarioLogeado, setUsuarioLogeado] = useState(null);

    // Estado para almacenar el nombre ingresado para confirmar la eliminación
    const [nombreIngresado, setNombreIngresado] = useState("");

    const [errorNombre, setErrorNombre] = useState(""); // Estado para el mensaje de error

    // Cargar la lista de empresas al cargar el componente
    useEffect(() => {
        setLoading(true);
        (async () => {
            await fetchData();
            setLoading(false);
        })();
    }, []);

    // Función para obtener la lista de empresas
    const fetchData = async () => {
        try {
            const usuarioResponse = await usuarioService.getUsuarioLogueado();
            setUsuarioLogeado(usuarioResponse.data);
            localStorage.setItem('userName', usuarioResponse.data.nombre);
            // En caso de que el usuario tenga acceso a una sola empresa, se redirije a la empresa, 
            // no debería poder ver la lista de empresas teniendo solo 1 asignada.
            if (usuarioResponse.data.empresas
                && usuarioResponse.data.empresas.length === 1
                && parseInt(usuarioResponse.data.rol) !== 0) {
                window.location.replace("/empresas/" + usuarioResponse.data.empresas[0] + "/reportes");
            }

            const empresasResponse = await empresaService.getListaEmpresas();
            setListaEmpresas(empresasResponse.data);
        } catch (error) {
            console.error('Error al obtener la lista de empresas:', error);
        }
    };

    // Función para manejar cambios en el campo de búsqueda
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Función para manejar la apertura del diálogo de confirmación de eliminación
    const handleOpenDialog = (empresa) => {
        setEmpresaAEliminar(empresa);
        setOpenDialog(true);
    };

    // Función para manejar la confirmación de eliminación de la empresa
    const handleConfirmarEliminacion = () => {
        if (nombreIngresado !== empresaAEliminar.nombre) {
            setErrorNombre("El nombre ingresado no coincide con el de la empresa."); // Establece el mensaje de error
            return;
        }
        setErrorNombre(""); // Limpia el mensaje de error si coincide
        empresaService.eliminarEmpresa(empresaAEliminar.id);
        setListaEmpresas(listaEmpresas.filter(empresa => empresa.id !== empresaAEliminar.id));
        setEmpresaAEliminar(null);
        setNombreIngresado("");
        setOpenDialog(false);
    };


    // Función para cerrar el diálogo de confirmación de eliminación
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Función para manejar cambios en el nombre ingresado por el usuario
    const handleNombreIngresadoChange = (event) => {
        setNombreIngresado(event.target.value);
    };

    // Filtrar la lista de empresas según el término de búsqueda
    const filteredEmpresas = listaEmpresas.filter(empresa => {
        const empresasUsuario = usuarioLogeado?.empresas || [];
        const rolUsuario = usuarioLogeado?.rol || -1;
        return empresa.nombre.toLowerCase().includes(searchTerm.toLowerCase()) && (parseInt(rolUsuario) === 0 || empresasUsuario.includes(empresa.id));
    });

    const usuarioAdministrador = usuarioLogeado ? parseInt(usuarioLogeado.rol) === 0 : false;

    return (
        <>
            <Navbar seccionActual={seccionActual} useSectionMode={usuarioAdministrador} secciones={secciones} seccionesRutas={seccionesRutas} />
            {loading && (
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            bgcolor: 'background.default',
                        }}
                    >
                        <CircularProgress size={80} />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Cargando...
                        </Typography>
                    </Box>
                </>
            )}
            {!loading && (
                <>
                    <Container sx={{ pb: '100px' }}>
                        <Paper sx={{ mt: 2, p: 2 }}>
                            <Typography variant="h4" color={"primary.main"} fontWeight={"bold"} sx={{ mb: 2 }}>Empresas</Typography>
                            <TextField
                                label="Buscar Empresa"
                                variant="outlined"
                                fullWidth
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{ mb: 2 }}
                            />
                            {filteredEmpresas && filteredEmpresas.length != 0 && filteredEmpresas.map((empresa, index) => {
                                return (
                                    <Box sx={{ pl: 2, pr: 2 }} key={index}>
                                        <Grid container alignItems="center" justifyContent="space-between" key={index} borderBottom={2} borderColor={"secondary"} sx={{ mx: 0, mb: 1, py: 1 }}>
                                            <Grid item xs={6}>
                                                <Typography variant="h5" color={"#000000"}>
                                                    {empresa.nombre}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent="flex-end" spacing={1}>
                                                <Tooltip
                                                    arrow
                                                    placement="bottom"
                                                    title="Ver reportes asociados a la empresa"
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        sx={{ textTransform: "none", fontWeight: "bold", mr: 1 }}
                                                        component={Link}
                                                        to={`/empresas/${empresa.id}/reportes`}
                                                        color="primary"
                                                    >
                                                        Ver Reportes
                                                    </Button>
                                                </Tooltip>
                                                {/*  */}
                                                {usuarioLogeado && usuarioLogeado.rol && (parseInt(usuarioLogeado.rol) === 0 || parseInt(usuarioLogeado.rol) === 6) && (
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom"
                                                        title="Ver detalles"
                                                    >
                                                        <IconButton
                                                            variant="outlined"
                                                            sx={{ textTransform: "none", fontWeight: "bold" }}
                                                            component={Link}
                                                            to={`/empresas/${empresa.id}`}
                                                        >
                                                            <VisibilityIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {parseInt(usuarioLogeado ? usuarioLogeado.rol : '2') === 0 && (
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom"
                                                        title="Eliminar"
                                                    >
                                                        <IconButton
                                                            variant="outlined"
                                                            onClick={() => handleOpenDialog(empresa)}
                                                        >
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                            })}
                            {filteredEmpresas && filteredEmpresas.length == 0 && (
                                <Typography variant="body1" color="textSecondary" sx={{ fontStyle: "italic" }}>
                                    No se encontraron empresas. (Puede que no tenga acceso a ninguna empresa)
                                </Typography>
                            )}
                        </Paper>
                    </Container>
                    {usuarioAdministrador && (
                        <Box bgcolor="#fff" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)", height: '80px' }}>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                                <Link to="/empresas/crear" style={{ textDecoration: "none" }}>
                                    <Button variant="contained" sx={{ textTransform: "none", fontWeight: "bold", mr: 1, color: "#ffffff" }}>
                                        Agregar Empresa
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    )}

                    {/* Diálogo de confirmación de eliminación */}
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Eliminar Empresa</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">
                                Usted está a punto de eliminar la empresa "<Typography component="span" variant="body1" color="primary.main" sx={{ fontStyle: "italic" }}>{empresaAEliminar && empresaAEliminar.nombre}</Typography>". Para confirmar la <Typography component="span" variant="body1" color="red" sx={{ fontWeight: "bold" }}>eliminación</Typography>, escriba el nombre de la empresa:
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Nombre de la Empresa a Eliminar"
                                type="text"
                                value={nombreIngresado}
                                onChange={(event) => {
                                    setNombreIngresado(event.target.value);
                                    setErrorNombre(""); // Limpia el error mientras el usuario escribe
                                }}
                                fullWidth
                                error={!!errorNombre} // Marca el campo con error si hay un mensaje
                                helperText={errorNombre} // Muestra el mensaje de error en letras rojas
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary" variant="outlined">
                                Cancelar
                            </Button>
                            <Button onClick={handleConfirmarEliminacion} color="error" variant="outlined">
                                Eliminar <DeleteIcon sx={{ ml: 0.5, mr: -0.5 }} />
                            </Button>
                        </DialogActions>
                    </Dialog>

                </>
            )}
        </>
    );
}

export default Empresas;

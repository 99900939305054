import React, { useEffect, useState } from 'react';
import { 
  Typography, Button, Divider, Box, Menu, MenuItem, 
  IconButton, Tooltip, Badge 
} from "@mui/material";  // <--- IMPORTA Badge
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import usuarioService from '../services/UsuarioService';
import { Link, useNavigate } from 'react-router-dom';

const DropdownNotificaciones = ({ usuarioLogeado }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificaciones, setNotificaciones] = useState([]);
    const [paginaNotificaciones, setPaginaNotificaciones] = useState(0);
    const [tieneMas, setTieneMas] = useState(true);
    const [totalNotificaciones, setTotalNotificaciones] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        cargarMasNotificaciones();
        cargarTotalNotificaciones();
    }, []);

    // Se puede optimizar mas esta logica.
    const cargarTotalNotificaciones = async () => {
        if (!usuarioLogeado) return;

        const nuevasNotificaciones = await usuarioService.cargarTodasLasNotificaciones(usuarioLogeado.id, 0)
            .then(response => response.data);

        setTotalNotificaciones(nuevasNotificaciones.length);
    };

    const cargarMasNotificaciones = async () => {
        if (!tieneMas) return;
        if (!usuarioLogeado) return;

        const nuevasNotificaciones = await usuarioService
            .cargarNotificaciones(usuarioLogeado.id, paginaNotificaciones)
            .then(response => response.data);

        if (nuevasNotificaciones.length < 5) {
            setTieneMas(false);
        }

        setNotificaciones(prevNotificaciones => [
            ...prevNotificaciones,
            ...nuevasNotificaciones
        ]);
        setPaginaNotificaciones(prevPagina => prevPagina + 1);
    };

    const handleEliminarNotificacion = async (index) => {
        try {
            await usuarioService.eliminarNotificacion(usuarioLogeado.id, index);
            setNotificaciones(prevNotificaciones =>
                prevNotificaciones.filter((_, i) => i !== index)
            );
            setTotalNotificaciones(prevNotificaciones =>
              prevNotificaciones = prevNotificaciones - 1
            );
        } catch (error) {
            console.error("Error al eliminar notificación", error);
        }
    };

    const formatTextWithBold = (text) => {
        return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    };

    const handleVerDetalles = (notificacion) => {
        const { idCampo, idSeccion, idCategoria, idEmpresa, idReporte } = notificacion;
        const params = new URLSearchParams();
        let queryString = "";
        let path = "";

        if (idEmpresa && idReporte) {
            if (idCampo || idSeccion || idCategoria) {
                if (idCampo) params.append("idCampo", idCampo);
                if (idSeccion) params.append("idSeccion", idSeccion);
                if (idCategoria) params.append("idCategoria", idCategoria);

                queryString = params.toString();
                path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
                navigate(`${path}?${queryString}`);
            } else {
                path = `/empresas/${idEmpresa}/reportes/${idReporte}`;
                navigate(path);
            }
        } else if (idEmpresa) {
            path = `/empresas/${idEmpresa}/reportes`;
            navigate(path);
        }
    };

    const handleLimpiarNotificaciones = async () => {
        try {
            await usuarioService.eliminarNotificaciones(usuarioLogeado.id);
            setNotificaciones([]);
            setTotalNotificaciones(0);
            setTieneMas(false);
        } catch {
            console.log("Error al eliminar las notificaciones");
        }
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip title={"Notificaciones"} placement="bottom" arrow>
                <IconButton
                  onClick={handleOpen}
                  sx={{ ml: 2 }}
                >
                    {/* Usamos Badge para mostrar el contador */}
                    <Badge 
                      badgeContent={totalNotificaciones} 
                      color="error" 
                      /* Esta prop hace que no se muestre si está en 0 */
                      invisible={notificaciones.length === 0}
                    >
                        {notificaciones.length > 0 ? (
                            <NotificationsIcon sx={{ color: '#003028' }} />
                        ) : (
                            <NotificationsNoneIcon sx={{ color: '#003028' }} />
                        )}
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '500px',
                        maxHeight: '500px',
                        overflowY: 'auto',
                    },
                }}
            >
                <Box padding="16px 24px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">Notificaciones</Typography>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                fontSize: '14px',
                                padding: '4px 12px',
                                borderRadius: '4px',
                            }}
                            component={Link}
                            to="/notificaciones"
                            startIcon={<OpenInNewIcon />}
                        >
                            Ver todo
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            style={{
                                textTransform: "none",
                                fontWeight: "bold",
                                fontSize: '14px',
                                padding: '4px 12px',
                                borderRadius: '4px',
                                marginLeft: 2,
                            }}
                            onClick={handleLimpiarNotificaciones}
                            startIcon={<ClearIcon />}
                        >
                            Limpiar
                        </Button>
                    </Box>
                </Box>

                <Divider style={{ width: '100%' }} />

                {notificaciones.length === 0 && (
                    <MenuItem
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '8px 16px',
                            flex: 1,
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            pointerEvents: 'none',
                            "&:hover": {
                                backgroundColor: 'transparent',
                            }
                        }}
                        disableRipple
                    >
                        <Typography
                            variant="body1"
                            style={{ userSelect: 'none' }}
                        >
                            No hay notificaciones
                        </Typography>
                    </MenuItem>
                )}

                {notificaciones.map((alerta, index) => (
                    <MenuItem
                        key={index}
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center', 
                            padding: '8px 16px' 
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body1"
                                style={{
                                    flex: 1,
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                                onMouseEnter={(e) => e.currentTarget.parentElement.style.backgroundColor = '#f5f5f5'}
                                onMouseLeave={(e) => e.currentTarget.parentElement.style.backgroundColor = 'transparent'}
                                onClick={() => handleVerDetalles(alerta)}
                                dangerouslySetInnerHTML={{ __html: formatTextWithBold(alerta.mensaje) }}
                            />
                            <Typography>
                                <strong>Fecha:</strong> {new Date(alerta.fecha).toLocaleString()}
                            </Typography>
                        </Box>

                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEliminarNotificacion(index);
                            }}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MenuItem>
                ))}

                {tieneMas && (
                    <MenuItem
                        style={{ display: 'flex', justifyContent: 'center', padding: '8px 16px' }}
                        onClick={cargarMasNotificaciones}
                    >
                        <Typography
                            variant="body1"
                            color={'primary'}
                            style={{
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}
                        >
                            Cargar más
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default DropdownNotificaciones;

import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Tooltip,
    Paper,
    Grid,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "./Navbar";
import { useNavigate, useParams } from "react-router-dom";
import ReporteService from "../services/ReporteService";
import EmpresaService from "../services/EmpresaService";
import BreadcrumbsMUI from "./BreadcrumbsMUI";

function AgregarRol() {
    const { idEmpresa, idReporte } = useParams();
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [permisosCategorias, setPermisosCategorias] = useState({});
    const [listaCategorias, setListaCategorias] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [tituloReporte, setTituloReporte] = useState("");
    const [nombreEmpresa, setNombreEmpresa] = useState("");
    const navigate = useNavigate();

    // Cargar datos del reporte y categorías al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ReporteService.obtenerReporte(idReporte);
                setTituloReporte(response.data.titulo);
                const categorias = response.data.categorias;

                // Obtener nombre de la empresa
                const empresa = await EmpresaService.getEmpresa(idEmpresa);
                setNombreEmpresa(empresa.data.nombre || "");

                // Inicializar permisos para las categorías y secciones
                const inicialPermisos = {};
                categorias.forEach((categoria) => {
                    inicialPermisos[categoria.titulo] = {
                        id: categoria.id,
                        titulo: categoria.titulo,
                        check: false, // Estado inicial de categoría
                        secciones: categoria.secciones.map((seccion) => ({
                            id: seccion.id,
                            titulo: seccion.titulo,
                            check: false, // Estado inicial de la sección
                        })),
                    };
                });
                setListaCategorias(categorias);
                setPermisosCategorias(inicialPermisos);
            } catch (error) {
                console.error("Error al obtener el reporte:", error);
            }
        };

        fetchData();
    }, [idReporte]);

    // Manejar el cambio de estado de un checkbox para categorías o secciones
    const handleCheckboxChange = (categoriaTitulo, seccionTitulo = null) => {
        setPermisosCategorias((prevState) => {
            const nuevaCategoria = { ...prevState[categoriaTitulo] };

            if (seccionTitulo) {
                // Cambiar el estado del checkbox de la sección
                nuevaCategoria.secciones = nuevaCategoria.secciones.map((seccion) =>
                    seccion.titulo === seccionTitulo
                        ? { ...seccion, check: !seccion.check }
                        : seccion
                );

                // Verificar si todas las secciones están marcadas
                nuevaCategoria.check = nuevaCategoria.secciones.every((seccion) => seccion.check);
            } else {
                // Cambiar el estado del checkbox de la categoría
                nuevaCategoria.check = !nuevaCategoria.check;

                // Cambiar el estado de todas las secciones dentro de la categoría
                nuevaCategoria.secciones = nuevaCategoria.secciones.map((seccion) => ({
                    ...seccion,
                    check: nuevaCategoria.check,
                }));
            }

            return {
                ...prevState,
                [categoriaTitulo]: nuevaCategoria,
            };
        });
    };


    const [openDialogError, setOpenDialogError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [errorNombreRol, setErrorNombreRol] = useState(false);
    const [errorMessageNombre, setErrorMessageNombre] = useState("");

    const handleAgregarRol = async (event) => {
        event.preventDefault();
        try {
            // Obtener la lista de roles existentes
            const rolesExistentes = await ReporteService.getRoles(idReporte);

            // Normalizar el nombre del rol ingresado
            const nombreNormalizado = nombre.trim().toLowerCase();

            // Verificar si ya existe un rol con el mismo nombre
            const rolDuplicado = rolesExistentes.data.some(rol => rol.nombre.trim().toLowerCase() === nombreNormalizado);

            if (rolDuplicado) {
                setErrorNombreRol(true);
                setErrorMessageNombre("Ya existe un rol con este nombre.");
                return; // No continuar con la creación
            } else {
                setErrorNombreRol(false);
                setErrorMessageNombre("");
            }

            // Validar que se haya seleccionado al menos una categoría o sección
            let categoriasSeleccionadas = Object.values(permisosCategorias)
                .filter((categoria) => categoria.check || categoria.secciones.some((seccion) => seccion.check));

            if (categoriasSeleccionadas.length === 0) {
                setErrorMessage("Debes seleccionar al menos una categoría o sección.");
                setOpenDialogError(true);
                return;
            }

            // Validar que se haya ingresado un nombre
            if (!nombre.trim()) {
                setErrorMessage("Debes ingresar un nombre para el rol.");
                setOpenDialogError(true);
                return;
            }

            // Validar que el nombre del rol no exceda los 50 caracteres
            if (nombre.length > 50) {
                setErrorMessage("El nombre del rol no puede exceder los 50 caracteres.");
                setOpenDialogError(true);
                return;
            }

            categoriasSeleccionadas = categoriasSeleccionadas.map((categoria) => ({
                id: categoria.id,
                titulo: categoria.titulo,
                idSecciones: categoria.secciones.filter((seccion) => seccion.check).map((seccion) => seccion.id),
            }));

            const rolData = {
                id: null,
                nombre,
                descripcion,
                idCategorias: categoriasSeleccionadas,
                permisos: null,
                idEmpresa: idEmpresa,
                idUsuarios: []
            };

            console.log('Estado de permisosCategorias:', permisosCategorias);
            console.log('Lista de categorías:', listaCategorias);

            await ReporteService.agregarRol(idReporte, rolData);
            console.log('Estructura del rol al crearlo:', rolData);
            setOpenDialog(true);
        } catch (error) {
            console.error("Error al crear el rol:", error);
            setErrorMessage("Hubo un error al crear el rol. Inténtalo nuevamente.");
            setOpenDialogError(true);
        }
    };



    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate("/empresas/" + idEmpresa + "/reportes/" + idReporte + "/roles");
    };

    return (
        <>
            <Navbar />
            <BreadcrumbsMUI
                breadcrumbs={[
                    { nombre: "Empresas", ruta: "/empresas", enabled: true },
                    { nombre: nombreEmpresa, ruta: `/empresas/${idEmpresa}/reportes`, enabled: true },
                    { nombre: tituloReporte, ruta: `/empresas/${idEmpresa}/reportes/${idReporte}`, enabled: true }
                ]}
                ultimoBreadcrumb={{ nombre: "Roles" }}
            />
            <Container>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4" color="primary" fontWeight="bold">
                                Crear Rol {tituloReporte !== "" ? "- " + tituloReporte : ""}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Volver a la lista de roles" arrow>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/empresas/${idEmpresa}/reportes/${idReporte}/roles`)}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        fontStyle: "italic",
                                        fontSize: "1.1rem",
                                    }}
                                >
                                    Volver
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Box component="form" onSubmit={handleAgregarRol} sx={{ mt: 4 }}>
                        <TextField
                            label="Nombre del Rol"
                            variant="outlined"
                            fullWidth
                            required
                            value={nombre}
                            error={errorNombreRol} // Muestra el borde rojo si hay un error
                            helperText={errorNombreRol ? errorMessageNombre : ""} // Mensaje debajo del campo
                            autoComplete="off"
                            onChange={(e) => {
                                setNombre(e.target.value);
                                setErrorNombreRol(false); // Limpiar error cuando el usuario escribe
                                setErrorMessageNombre("");
                            }}
                            sx={{ mb: 3 }}
                        />

                        <TextField
                            label="Descripción"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={descripcion}
                            autoComplete="off"
                            onChange={(e) => setDescripcion(e.target.value)}
                            sx={{ mb: 3 }}
                        />

                        {Object.values(permisosCategorias).map((categoria) => (
                            <Accordion key={categoria.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={categoria.check}
                                                onChange={() => handleCheckboxChange(categoria.titulo)}
                                            />
                                        }
                                        label={<Typography variant="h6" fontWeight="bold">{categoria.titulo}</Typography>}
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" flexDirection="column">
                                        {categoria.secciones.map((seccion) => (
                                            <FormControlLabel
                                                key={seccion.id}
                                                control={
                                                    <Checkbox
                                                        checked={seccion.check}
                                                        onChange={() => handleCheckboxChange(categoria.titulo, seccion.titulo)}
                                                    />
                                                }
                                                label={seccion.titulo}
                                            />
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}

                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                    fontSize: "1.2rem",
                                }}
                            >
                                Crear Rol
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{"Rol creado exitosamente"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>El rol ha sido creado con éxito.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDialogError} onClose={() => setOpenDialogError(false)}>
                    <DialogTitle>{"Advertencia"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {errorMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialogError(false)} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>
        </>
    );
}

export default AgregarRol;

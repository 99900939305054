import axiosInstance from './AxiosConfig';

const API_URL = '/api/usuario';

const usuarioService = {
    getListaUsuarios: () => axiosInstance.get(API_URL),
    getUsuario: (id) => axiosInstance.get(`${API_URL}/${id}`),
    crearUsuario: (usuario) => axiosInstance.post(API_URL, usuario),
    actualizarUsuario: (usuario) => axiosInstance.put(API_URL, usuario, {
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    deleteUsuario: (id) => axiosInstance.delete(`${API_URL}/${id}`),
    listaRoles: ["Superadministrador", "Editor de Reporte", "Usuario Indicador", "Autorizador de Registro", "Autorizador de Reporte", "Auditor", "Administrador"],
    getUsuarioLogueado: () => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (token && userId) {
            return usuarioService.getUsuario(userId);
        }
        return null;
    },
    resetPassword: (token, idUsuario) =>
        axiosInstance.put(`${API_URL}/reset-password`, token, {
            params: { idUsuario },
            headers: {
                'Content-Type': 'application/json'
            }
        }),
    cargarNotificaciones: (id, pagina) => axiosInstance.get(`${API_URL}/${id}/notificaciones`, {
        params: {
            pagina: pagina,
            tamanoPagina: 5
        }
    }),
    cargarTodasLasNotificaciones: (id, pagina) => axiosInstance.get(`${API_URL}/${id}/notificaciones`, {
        params: {
            pagina: pagina,
            tamanoPagina: 100
        }
    }),
    eliminarNotificacion: (id, index) => axiosInstance.delete(`${API_URL}/${id}/notificaciones/${index}`),
    eliminarNotificaciones: (id) => axiosInstance.delete(`${API_URL}/${id}/notificaciones`),
    toggleBlockUser: (id) => axiosInstance.put(`${API_URL}/${id}/toggle-block`),
    validarContrasenia: (id, contrasenia) => axiosInstance.get(`${API_URL}/${id}/validar-contrasenia`, {
        params: { 
            contrasenia: contrasenia
        }
    }),
};

export default usuarioService;
